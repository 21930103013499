import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getSourceData } from "../services/urlService.jsx";
import { filterBy } from "@progress/kendo-data-query";
import PreviousStateFunctionalComponent from "./previousStateFunctionalComponent";
var _ = require("lodash");
function GridDropDownList(props) {
    var _a, _b;
    var dataItem = props.dataItem, field = props.field;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    var dropdownDataRef = useRef(0);
    var defaultItem = (_a = {},
        _a[field] = "Select...",
        _a.id = "",
        _a);
    var addItem = (_b = {},
        _b[field] = dataItem[field],
        _b.id = dataItem[props.className.replace(/^\s+|\s+$/g, "")],
        _b);
    /*
     * Hook constant to set state to the current functional component
     */
    var currentDataItem = dataItem[field] === null ? [] : [dataItem];
    var _c = useState([]), dropdownData = _c[0], setdropdownData = _c[1];
    var _d = useState(false), loader = _d[0], setLoader = _d[1];
    var _e = useState(null), aliasField = _e[0], setAliasField = _e[1];
    var _f = useState(null), value = _f[0], setValue = _f[1];
    /*
     * Function to load dropdown list on opening the list
     */
    function onOpenDropdown() {
        var sourceField;
        var progressiveFilter;
        if (dropdownData.length === 0 ||
            (dataItem[field] === null && currentDataItem.length === 0)) {
            setLoader(true);
            if (typeof props.filterParams !== "undefined") {
                for (var i = 0; i < props.filterParams.length; i++) {
                    var filterParamsVal = props.filterParams[i].split("-");
                    var paramValue = props.dataItem[filterParamsVal[1]];
                    var filter = filterParamsVal[0];
                    if (typeof progressiveFilter !== "undefined") {
                        progressiveFilter =
                            progressiveFilter + "_" + filter + "?" + paramValue;
                    }
                    else {
                        progressiveFilter = "_" + filter + "?" + paramValue;
                    }
                }
                sourceField = props.field + "Filter" + progressiveFilter;
            }
            else {
                sourceField = props.field;
            }
            // let aliasOrField = props.alias;
            getSourceData(sourceField).then(function (response) {
                if (typeof response !== "undefined") {
                    var data = response["data"];
                    if (data !== "empty" && response["data"].length > 0) {
                        var cnSplit = props.className.split("-");
                        dropdownDataRef.current = data;
                        if (cnSplit.length > 1) {
                            setAliasField(cnSplit[1].replace(/^\s+|\s+$/g, ""));
                        }
                        setdropdownData(dropdownDataRef.current);
                        setLoader(false);
                    }
                }
                else {
                    setdropdownData([]);
                    setLoader(false);
                }
            });
        }
    }
    /*
     * This will hold the previous state of the grid data in this functional component
     */
    var previousStateDropdownData = PreviousStateFunctionalComponent(dropdownDataRef.current);
    /*
     * Function to apply filterBy kendo function on the state data
     */
    function filterData(filter) {
        var data = previousStateDropdownData.slice();
        return filterBy(data, filter);
    }
    /*
     * Function to be called on search filter
     */
    function filterChange(event) {
        if (event.filter.value.length > 0) {
            setdropdownData(filterData(event.filter));
        }
        else {
            setdropdownData(dropdownDataRef.current);
        }
    }
    /*
     * Function to be called on change
     */
    function onChange(event) {
        //let getVal = props.field;
        var aliasKey;
        var cnSplit = props.className.split("-");
        if (cnSplit.length > 1) {
            aliasKey = cnSplit[2].replace(/^\s+|\s+$/g, "");
        }
        var foreignKey = aliasKey ? aliasKey : props.className.split(" ")[0];
        var data = props.dataItem;
        delete data[foreignKey];
        var keyId = event.target.value.id;
        _.set(data, foreignKey, keyId);
        var foreignKeyName = event.target.value[aliasField ? aliasField : props.field];
        props.onChange({
            dataItem: data,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
            value: foreignKeyName,
        });
        setValue(dropdownData.find(function (c) {
            return c[aliasField ? aliasField : props.field] ===
                event.target.value[aliasField ? aliasField : props.field];
        }));
        setdropdownData(dropdownData);
    }
    /*
     * Function to reset value when cancelled or switched
     */
    useEffect(function () {
        if (!dataItem.inEdit) {
            setValue(null);
        }
    }, [dataItem.inEdit]);
    /*
     *Function to align list items to the left
     */
    function itemRender(li, itemProps) {
        var itemChildren = (_jsx("span", __assign({ style: { textAlign: "left" } }, { children: li.props.children })));
        return React.cloneElement(li, li.props, itemChildren);
    }
    /*
     *component jsx return
     */
    // console.log(props, aliasField? aliasField:props.field);
    return (_jsx("td", { children: dataItem.inEdit ? (_jsx(DropDownList, { id: props.field, className: props.field, style: { width: "100%" }, data: dropdownData, textField: aliasField ? aliasField : props.field, loading: loader, defaultItem: defaultItem, itemRender: itemRender, value: value
                ? value
                : dropdownData.length > 0
                    ? dropdownData.find(function (c) {
                        return c[aliasField ? aliasField : props.field] ===
                            dataItem[aliasField ? aliasField : props.field];
                    })
                    : dataItem.event === "edit"
                        ? dataItem
                        : dataItem.event === "add"
                            ? addItem
                            : defaultItem, onOpen: function () {
                onOpenDropdown();
            }, filterable: true, onChange: onChange, onFilterChange: filterChange })) : (dataValue.toString()) }));
}
export default GridDropDownList;
