import { __assign } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ConfirmDeleteActionBox from "./confirmDeleteActionBox";
import { deleteSourceData } from "../services/urlService.jsx";
function ActionCell(props) {
    //Hook to set show/hide delete confirm box
    var _a = useState(false), isDelete = _a[0], setIsDelete = _a[1];
    /*
     *to cancel the confirm box
     */
    function cancelDelete() {
        document
            .querySelector(".k-state-selected")
            .classList.remove("k-state-selected");
        setIsDelete(!isDelete);
    }
    /*
     *This will call the delete API to remove the selected
     *data item
     */
    var confirmDelete = function () {
        props.setIsLoading(true);
        deleteSourceData(props.dataItem.id, props.api).then(function (response) {
            if (typeof response !== "undefined") {
                if (response.status === 200) {
                    props.setIsLoading(false);
                    props.updateCountAndData(props.dataItem.id);
                    setIsDelete(!isDelete);
                }
            }
            else {
                props.setIsLoading(false);
            }
        });
    };
    //functon to delete an item from the grid
    function remove(e) {
        if (e.target.classList.contains("fa")) {
            e.target.parentNode.parentNode.parentNode.parentNode.classList.add("k-state-selected");
        }
        else {
            e.target.parentNode.parentNode.parentNode.classList.add("k-state-selected");
        }
        setIsDelete(!isDelete);
    }
    /*
     *component jsx return
     */
    return !props.dataItem.inEdit ? (_jsxs(_Fragment, { children: [_jsxs("td", { children: [_jsx(Tooltip, __assign({ openDelay: 10, position: "top", anchorElement: "target" }, { children: props.dataItem.versionActiveInd == "Y" &&
                            props.dataItem.versionTypeKey == 3 ? (_jsxs(_Fragment, { children: [_jsx("button", __assign({ className: "k-button border border-0 bg-transparent pull-left", onClick: function () {
                                        props.edit(props.dataItem);
                                    } }, { children: _jsx("i", { title: "Edit", className: "fa fa-pencil", style: { fontSize: 18, color: "#7f7f7f" } }) })), " "] })) : null })), _jsx(Tooltip, __assign({ openDelay: 10, position: "top", anchorElement: "target" }, { children: props.dataItem.versionActiveInd == "Y" &&
                            props.dataItem.versionTypeKey == 3 ? (_jsxs(_Fragment, { children: [" ", _jsx("button", __assign({ className: "k-button border border-0 bg-transparent pull-left", onClick: function (e) {
                                        remove(e);
                                    } }, { children: _jsx("i", { title: "Remove", className: "fa fa-trash", style: { fontSize: 18, color: "#7f7f7f" } }) })), " "] })) : null }))] }), isDelete && (_jsx(ConfirmDeleteActionBox, { cancelDelete: cancelDelete, confirmDelete: confirmDelete, data: props.dataItem }))] })) : (_jsxs("td", { children: [_jsx(Tooltip, __assign({ openDelay: 10, position: "top", anchorElement: "target" }, { children: _jsx("button", __assign({ className: "k-button k-grid-save-command border-0 bg-transparent pull-left", onClick: function () { return props.save(props.dataItem); } }, { children: props.dataItem["id"] ? (_jsx("i", { title: "Update", className: "fa fa-save", style: { fontSize: 20, color: "#7f7f7f" } })) : (_jsx("i", { title: "Add", className: "fa fa-save", style: { fontSize: 20, color: "#7f7f7f" } })) })) })), _jsx(Tooltip, __assign({ openDelay: 10, position: "top", anchorElement: "target" }, { children: _jsx("button", __assign({ className: "k-button k-grid-cancel-command border-0 bg-transparent pull-left", onClick: function () { return props.cancel(props.dataItem); } }, { children: props.dataItem["id"] ? (_jsx("i", { title: "Cancel", className: "fa fa-times", style: { fontSize: 20, color: "#7f7f7f" } })) : (_jsx("i", { title: "Discard", className: "fa fa-times", style: { fontSize: 20, color: "#7f7f7f" } })) })) }))] }));
}
export default ActionCell;
