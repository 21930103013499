import { __assign, __awaiter, __extends, __generator, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
import Badge from "./tools/badge";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { CustomDateInput } from "./customDateInput.jsx";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import DropdownMultiSelect from "./dropdownMultiSelect";
import FilterBtn from "./tools/filterBtn";
import Filter from "./tools/filter";
// import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import QuaterPicker from "../../../../../commons/quaterPicker";
import ErrorBoundary from "../../../../../services/errorBoundary";
import { ToastContainer, toast } from "react-toastify";
import onClickOutside from "react-onclickoutside";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import IncrBtnGroup from "./tools/incrBtnGroup";
var moment = require("moment");
var panelVisible = false;
var FilterContainer = /** @class */ (function (_super) {
    __extends(FilterContainer, _super);
    function FilterContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.anchor = null;
        _this.versionDatePicker = function () {
            _this.setState({
                versionDatePicker: !_this.state.versionDatePicker,
            });
        };
        _this.onDeltaBetweenProvidersClick = function () {
            if (!_this.state.deltaBetweenProviders) {
                _this.setState({
                    deltaBetweenProviders: true,
                    deltaBetweenVersions: false,
                });
            }
        };
        _this.onDeltaBetweenVersionsClick = function () {
            if (!_this.state.deltaBetweenVersions) {
                _this.setState({
                    deltaBetweenProviders: false,
                    deltaBetweenVersions: true,
                });
            }
        };
        _this.onLimitForHighlightProviderChange = function (event) {
            _this.setState({
                limitForHighlightProvider: event.target.value,
            });
        };
        _this.onBaseProviderChange = function (event) {
            _this.setState({
                baseProvider: event.target.value,
            });
        };
        _this.showDeltaBetweenProviders = function () {
            _this.setState({
                showPopup: false,
            }, function () {
                _this.props.setLimitForHighlight(_this.state.limitForHighlightProvider);
                _this.props.deltaActive(true);
                _this.props.setBaseProvider(_this.state.baseProvider);
            });
        };
        _this.onVersionDateChange = function (event) {
            if (event.target.props.versionDateType === "1")
                _this.setState({
                    version1Date: moment(event.target.value).format("YYYY-MM-DD"),
                });
            else
                _this.setState({
                    version2Date: moment(event.target.value).format("YYYY-MM-DD"),
                });
        };
        _this.onLimitForHighlightVersionChange = function (event) {
            _this.setState({
                limitForHighlightVersion: event.target.value,
            });
        };
        _this.showDeltaBetweenVersions = function () {
            _this.setState({
                showPopup: false,
            }, function () {
                _this.props.setLimitForHighlight(_this.state.limitForHighlightVersion);
                _this.props.deltaActive(true);
                _this.applyOnCLick("version");
            });
        };
        _this.deltaToggle = function () {
            _this.setState({ delta: !_this.state.delta });
        };
        _this.filterBtnToggle = function () {
            _this.setState({ filterbtnisOn: !_this.state.filterbtnisOn });
            var filterDiv = document.getElementById("filterPanel");
            if (filterDiv.style.zIndex === "-1") {
                filterDiv.style.zIndex = "9999";
            }
            else {
                filterDiv.style.zIndex = "-1";
            }
            if (_this.state.showPopup) {
                _this.setState({
                    showPopup: false,
                });
            }
        };
        _this.onApplyChange = function () {
            _this.setState({ onApplyChange: !_this.state.onApplyChange });
        };
        _this.versionHandleChange = function (e) {
            var dateFormate = moment(e.value).format("DD MMM YYYY");
            _this.setState({
                versionDate: dateFormate,
            });
        };
        _this.itemRender = function (li, itemProps) {
            var index = itemProps.index;
            var itemChildren = (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "row justify-content-between no-gutters" }, { children: [_jsxs("div", __assign({ className: "select-text" }, { children: [_jsx("span", __assign({ className: "dim-text" }, { children: "Provider:" })), _jsx("span", __assign({ className: "normal-text ml-1 mr-1" }, { children: "Baseline, +6" }))] })), _jsx("div", __assign({ className: "select-dropdown ml-3" }, { children: _jsx("i", { className: "fa fa-lg fa-caret-down dim-text", "aria-hidden": "true" }) }))] })), _jsx("div", __assign({ className: "select-options" }, { children: _jsxs("div", __assign({ className: "dropdown-menu" }, { children: [li.props.children, " ", index] })) }))] }));
            return React.cloneElement(li, li.props, itemChildren);
        };
        _this.handleSelect = function (e) {
            _this.setState({ selected: e.selected });
        };
        _this.CustomDateInputVersion = function (text, dateType) {
            if (text === void 0) { text = null; }
            if (dateType === void 0) { dateType = null; }
            return (_jsx(CustomDateInput, { dimtext: text ? text.toString() : "Version", datetype: dateType
                    ? moment(new Date(dateType)).format("DD MMM YYYY")
                    : moment(new Date(_this.state.versionDate)).format("DD MMM YYYY"), textClass: text ? "dim-text p-2 ml-2" : "dim-text", dateClass: text ? "normal-text p-2 delta-dates" : "normal-text ml-2 mr-4" }));
        };
        _this.CustomDateInputLastUpdate = function () {
            return (_jsx(CustomDateInput, { dimtext: "Last Update Since", datetype: moment(_this.state.lastUpdateDate).format("DD MMM YYYY"), textClass: "dim-text", dateClass: "normal-text ml-2 mr-4" }));
        };
        _this.CustomDateInputStart = function (props) {
            return _jsx(CustomDateInput, __assign({}, props, { dimtext: "Start", datetype: "2000 | Q1" }));
        };
        _this.CustomDateInputEnd = function (props) {
            return _jsx(CustomDateInput, __assign({}, props, { dimtext: "End", datetype: "2019 | Q1" }));
        };
        _this.handleToggle = function () {
            panelVisible = panelVisible ? false : true;
        };
        _this.CustomPopup = function (props) {
            return _jsx(Popup, __assign({}, props, { popupClass: "filter-popup" }));
        };
        _this.resetHandler = function () {
            if (_this.state.resetDenote) {
                _this.setState({
                    countries: "Countries?",
                    cities: "Cities?",
                    company: "Companies?",
                    assetClass: "AssetClasses?",
                    assets: "Assets/AssetFilter?",
                    assetUnitType: "AssetUnitTypes?",
                    assetUnitSubtype: "AssetUnitSubtypes?",
                    assetUnit: "AssetUnits/AssetUnitFilter?",
                    resetDenote: !_this.state.resetDenote,
                });
            }
            else {
                _this.setState({
                    countries: "Countries",
                    cities: "Cities",
                    company: "Companies",
                    assetClass: "AssetClasses",
                    assets: "Assets/AssetFilter",
                    assetUnitType: "AssetUnitTypes",
                    assetUnitSubtype: "AssetUnitSubtypes",
                    assetUnit: "AssetUnits/AssetUnitFilter",
                    resetDenote: !_this.state.resetDenote,
                    resetToDefault: !_this.state.resetToDefault,
                });
            }
            _this.setState({
                refresh: true,
                Countries: 0,
                Cities: 0,
                Owners: 0,
                AssetType: 0,
                Assets: 0,
                UnitType: 0,
                UnitSubtype: 0,
                Unit: 0,
                resetCities: false,
                currentSelectedCountries: [],
                currentSelectedCompanies: [],
                currentSelectedCities: [],
                currentSelectedAssetClasses: [],
                currentSelectedAssets: [],
                currentSelectedAssetUnitTypes: [],
                currentSelectedAssetUnitSubtypes: [],
                currentSelectedAssetUnit: [],
                versionDate: new Date(),
                selectedBadgeIndex: -1,
            });
            _this.provider.resetHandler();
            _this.probability.resetHandler();
            _this.startDate.resetQqYear();
            _this.endDate.resetQqYear();
            _this.props.resetWMEnable();
            _this.props.deleteFilterStore();
            if (!_this.state.onApplyChange) {
                _this.props.OnApply();
            }
        };
        _this.eventManagement = function (object, path, currentSelected, count, pathChange) {
            if (pathChange === void 0) { pathChange = true; }
            if (Object.keys(object).length > 0) {
                // this.setState(prevState => ({
                //   currentSelectedFilters: prevState.currentSelectedFilters.set(
                //     path,
                //     currentSelected
                //   )
                // }));
                Object.entries(object).map(function (value, key) {
                    switch (value[0]) {
                        case "countries":
                            _this.setState({
                                cities: value[1],
                                resetCountries: true,
                            });
                            break;
                        case "cities":
                            _this.setState({
                                cities: value[1],
                                resetCities: true,
                            });
                            break;
                        case "company":
                            _this.setState({
                                company: value[1],
                                resetCompany: true,
                            });
                            break;
                        case "assetClass":
                            _this.setState({
                                assetClass: value[1],
                                resetAssetClass: true,
                            });
                            break;
                        case "assets":
                            _this.setState({
                                assets: value[1],
                                resetAssets: true,
                            });
                            break;
                        case "assetUnitType":
                            _this.setState({
                                assetUnitType: value[1],
                                resetAssetUnitType: true,
                            });
                            break;
                        case "assetUnitSubtype":
                            _this.setState({
                                assetUnitSubtype: value[1],
                                resetAssetUnitSubType: true,
                            });
                            break;
                        case "assetUnit":
                            if (pathChange) {
                                _this.setState({
                                    assetUnit: value[1],
                                    resetAssetUNit: true,
                                });
                            }
                            break;
                        default:
                            break;
                    }
                    return value;
                });
            }
            switch (path) {
                case "Countries":
                    _this.setState({
                        Countries: count,
                        Cities: 0,
                        Owners: 0,
                        AssetType: 0,
                        Assets: 0,
                        UnitType: 0,
                        UnitSubtype: 0,
                        Unit: 0,
                        currentSelectedCountries: currentSelected,
                        currentSelectedCities: [],
                        currentSelectedCompanies: [],
                        currentSelectedAssetClasses: [],
                        currentSelectedAssets: [],
                        currentSelectedAssetUnitTypes: [],
                        currentSelectedAssetUnitSubtypes: [],
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "Cities":
                    _this.setState({
                        Cities: count,
                        Owners: 0,
                        AssetType: 0,
                        Assets: 0,
                        UnitType: 0,
                        UnitSubtype: 0,
                        Unit: 0,
                        currentSelectedCities: currentSelected,
                        currentSelectedCompanies: [],
                        currentSelectedAssetClasses: [],
                        currentSelectedAssets: [],
                        currentSelectedAssetUnitTypes: [],
                        currentSelectedAssetUnitSubtypes: [],
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "Companies":
                    _this.setState({
                        Owners: count,
                        AssetType: 0,
                        Assets: 0,
                        UnitType: 0,
                        UnitSubtype: 0,
                        Unit: 0,
                        currentSelectedCompanies: currentSelected,
                        currentSelectedAssetClasses: [],
                        currentSelectedAssets: [],
                        currentSelectedAssetUnitTypes: [],
                        currentSelectedAssetUnitSubtypes: [],
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "AssetClasses":
                    _this.setState({
                        AssetType: count,
                        Assets: 0,
                        UnitType: 0,
                        UnitSubtype: 0,
                        Unit: 0,
                        currentSelectedAssetClasses: currentSelected,
                        currentSelectedAssets: [],
                        currentSelectedAssetUnitTypes: [],
                        currentSelectedAssetUnitSubtypes: [],
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "Assets/AssetFilter":
                    _this.setState({
                        Assets: count,
                        UnitType: 0,
                        UnitSubtype: 0,
                        Unit: 0,
                        currentSelectedAssets: currentSelected,
                        currentSelectedAssetUnitTypes: [],
                        currentSelectedAssetUnitSubtypes: [],
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "AssetUnitTypes":
                    _this.setState({
                        UnitType: count,
                        UnitSubtype: 0,
                        Unit: 0,
                        currentSelectedAssetUnitTypes: currentSelected,
                        currentSelectedAssetUnitSubtypes: [],
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "AssetUnitSubtypes":
                    _this.setState({
                        UnitSubtype: count,
                        Unit: 0,
                        currentSelectedAssetUnitSubtypes: currentSelected,
                        currentSelectedAssetUnit: [],
                    });
                    break;
                case "AssetUnits/AssetUnitFilter":
                    _this.setState({
                        Unit: count,
                        currentSelectedAssetUnit: currentSelected,
                    });
                    break;
                default:
                    break;
            }
        };
        _this.componentDidMount = function () {
            if (_this.props.mainFilterSelectedItems.size > 0 &&
                typeof _this.props.mainFilterSelectedItems.get("capacity") !== "undefined") {
                _this.setState({
                    versionDate: _this.props.mainFilterSelectedItems.get("capacity")
                        .versionDate,
                });
            }
        };
        _this.onStartDateSelect = function (type, value) {
            if (type === "quater") {
                _this.setState({
                    startDateQq: value,
                });
            }
            else {
                _this.setState({
                    startDateYear: value,
                });
            }
        };
        _this.onEndDateSelect = function (type, value) {
            if (type === "quater") {
                _this.setState({
                    endDateQq: value,
                });
            }
            else {
                _this.setState({
                    endDateYear: value,
                });
            }
        };
        _this.onProviderSelect = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var providerSelected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        providerSelected = [];
                        return [4 /*yield*/, value.map(function (value, index) {
                                providerSelected.push(value);
                                return value;
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.setState({
                                providerSelected: providerSelected,
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onProbabilityTypeSelect = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var probabilityTypeSelect;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        probabilityTypeSelect = [];
                        return [4 /*yield*/, value.map(function (value, index) {
                                probabilityTypeSelect.push(value);
                                return value;
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.setState({
                                probabilityTypeSelect: probabilityTypeSelect,
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.paramsForAssetFilter = function () {
            var provider = _this.state.providerSelected;
            var pType = _this.state.probabilityTypeSelect;
            var startDate = _this.dateQuater.start[_this.state.startDateQq] +
                " " +
                _this.state.startDateYear;
            var endDate = _this.dateQuater.end[_this.state.endDateQq] + " " + _this.state.endDateYear;
            var providerSelected = provider.length > 0
                ? "dp=" +
                    _.map(provider.filter(function (item) { return item.value !== "*"; }), "value").join("&dp=")
                : "";
            var probabilityTypeSelect = pType.length > 0
                ? "pt=" +
                    _.map(pType.filter(function (item) { return item.value !== "*"; }), "value").join("&pt=")
                : "";
            return ("startdate=" +
                moment(new Date(startDate)).format("YYYY-MM-DD") +
                "&enddate=" +
                moment(new Date(endDate)).format("YYYY-MM-DD") +
                "&" +
                providerSelected +
                "&versiondate=" +
                moment(new Date(_this.state.versionDate)).format("YYYY-MM-DD") +
                "&" +
                probabilityTypeSelect);
        };
        _this.groupByParamChange = function (type) {
            if (type === "country") {
                _this.setState({
                    groupByAsset: false,
                    groupByCountry: true,
                }, function () {
                    _this.applyOnCLick();
                });
            }
            else if (type === "asset") {
                _this.setState({
                    groupByCountry: false,
                    groupByAsset: true,
                }, function () {
                    _this.applyOnCLick();
                });
            }
            else {
                //code for region
            }
        };
        _this.applyOnCLick = function (type) {
            if (type === void 0) { type = null; }
            if (type !== "baseProvider") {
                _this.props.setBaseProvider(null);
            }
            if (type === null) {
                _this.props.deltaActive(false);
                _this.setState({
                    delta: false,
                });
            }
            _this.setState({
                selectedBadgeIndex: -1,
            });
            var countriesPath;
            var citiesPath;
            var companyPath;
            var assetClassPath;
            var assetsPath;
            var assetUnitTypePath;
            var assetUnitSubtypePath;
            var assetUnitPath;
            var startDate = _this.dateQuater.start[_this.state.startDateQq] +
                " " +
                _this.state.startDateYear;
            var endDate = _this.dateQuater.end[_this.state.endDateQq] + " " + _this.state.endDateYear;
            var provider = _this.state.providerSelected;
            var pType = _this.state.probabilityTypeSelect;
            var providerSelected = provider.length > 0
                ? "dp=" +
                    _.map(provider.filter(function (item) { return item.value !== "*"; }), "value").join("&dp=")
                : "";
            var probabilityTypeSelect = pType.length > 0
                ? "pt=" +
                    _.map(pType.filter(function (item) { return item.value !== "*"; }), "value").join("&pt=")
                : "";
            countriesPath =
                _this.state.currentSelectedCountries.length > 0
                    ? "co=" + _this.state.currentSelectedCountries.join("&co=")
                    : "";
            citiesPath =
                _this.state.currentSelectedCities.length > 0
                    ? "ci=" + _this.state.currentSelectedCities.join("&ci=")
                    : "";
            companyPath =
                _this.state.currentSelectedCompanies.length > 0
                    ? "o=" + _this.state.currentSelectedCompanies.join("&o=")
                    : "";
            assetClassPath =
                _this.state.currentSelectedAssetClasses.length > 0
                    ? "at=" + _this.state.currentSelectedAssetClasses.join("&at=")
                    : "";
            assetsPath =
                _this.state.currentSelectedAssets.length > 0
                    ? "a=" + _this.state.currentSelectedAssets.join("&a=")
                    : "";
            assetUnitTypePath =
                _this.state.currentSelectedAssetUnitTypes.length > 0
                    ? "ut=" + _this.state.currentSelectedAssetUnitTypes.join("&ut=")
                    : "";
            assetUnitSubtypePath =
                _this.state.currentSelectedAssetUnitSubtypes.length > 0
                    ? "ust=" + _this.state.currentSelectedAssetUnitSubtypes.join("&ust=")
                    : "";
            assetUnitPath =
                _this.state.currentSelectedAssetUnit.length > 0
                    ? "unit=" + _this.state.currentSelectedAssetUnit.join("&unit=")
                    : "";
            var countriesPathNU = typeof countriesPath !== "undefined" ? countriesPath + "&" : "";
            var citiesPathNU = typeof citiesPath !== "undefined" ? citiesPath + "&" : "";
            var companyPathNU = typeof companyPath !== "undefined" ? companyPath + "&" : "";
            var assetClassPathNU = typeof assetClassPath !== "undefined" ? assetClassPath + "&" : "";
            var assetsPathNU = typeof assetsPath !== "undefined" ? assetsPath + "&" : "";
            var assetUnitTypePathNU = typeof assetUnitTypePath !== "undefined" ? assetUnitTypePath + "&" : "";
            var assetUnitSubtypePathNU = typeof assetUnitSubtypePath !== "undefined"
                ? assetUnitSubtypePath + "&"
                : "";
            var assetUnitPathNU = typeof assetUnitPath !== "undefined" ? assetUnitPath + "&" : "";
            var groupByParam = _this.state.groupByCountry ? "GroupBy=country&" : "";
            var path = countriesPathNU +
                citiesPathNU +
                companyPathNU +
                assetClassPathNU +
                assetsPathNU +
                assetUnitTypePathNU +
                assetUnitSubtypePathNU +
                assetUnitPathNU +
                groupByParam;
            if (type === "version") {
                //delta between versions
                path =
                    path +
                        "isAbsolute=false&isDeltaByProvider=false&versionDate1=" +
                        _this.state.version1Date +
                        "&versionDate2=" +
                        _this.state.version2Date;
            }
            if (_this.state.isLastUpdateSince) {
                _this.props.paramsForAssetFilterURLGenerate(moment(new Date(startDate)).format("YYYY-MM-DD"), moment(new Date(endDate)).format("YYYY-MM-DD"), providerSelected, probabilityTypeSelect, null, //if isLastUpdate flag is set, set versionDate in parent as null
                moment(new Date(_this.state.lastUpdateDate)).format("YYYY-MM-DD"), path);
            }
            else {
                _this.props.paramsForAssetFilterURLGenerate(moment(new Date(startDate)).format("YYYY-MM-DD"), moment(new Date(endDate)).format("YYYY-MM-DD"), providerSelected, probabilityTypeSelect, moment(new Date(_this.state.versionDate)).format("YYYY-MM-DD"), null, //if isLastUpdate is not set, set lastUpdateDate in parent as null
                path);
            }
            _this.generateURL =
                "?startdate=" +
                    moment(new Date(startDate)).format("YYYY-MM-DD") +
                    "&enddate=" +
                    moment(new Date(endDate)).format("YYYY-MM-DD") +
                    "&" +
                    providerSelected +
                    "&" +
                    probabilityTypeSelect +
                    (_this.state.isLastUpdateSince
                        ? "&lastUpdateDateSince=" +
                            moment(new Date(_this.state.lastUpdateDate)).format("YYYY-MM-DD")
                        : "&versiondate=" +
                            moment(new Date(_this.state.versionDate)).format("YYYY-MM-DD")) +
                    "&" +
                    countriesPathNU +
                    citiesPathNU +
                    companyPathNU +
                    assetClassPathNU +
                    assetsPathNU +
                    assetUnitTypePathNU +
                    assetUnitSubtypePathNU +
                    assetUnitPathNU +
                    groupByParam;
            if (type === "version") {
                _this.generateURL =
                    _this.generateURL +
                        "isAbsolute=false&isDeltaByProvider=false&versionDate1=" +
                        _this.state.version1Date +
                        "&versionDate2=" +
                        _this.state.version2Date;
            }
            // if (type === 'provider') {
            //   this.generateURL = this.generateURL +
            //     "isAbsolute=false&isDeltaByProvider=true&" +
            //     "BaseProviderKey=" + this.state.baseProvider.value;
            // }
            var capacitySelectedItems = {
                Countries: _this.state.currentSelectedCountries,
                Cities: _this.state.currentSelectedCities,
                Companies: _this.state.currentSelectedCompanies,
                assetClasses: _this.state.currentSelectedAssetClasses,
                assets: _this.state.currentSelectedAssets,
                assetUnitType: _this.state.currentSelectedAssetUnitTypes,
                assetUnitSubType: _this.state.currentSelectedAssetUnitSubtypes,
                assetUnit: _this.state.currentSelectedAssetUnit,
                provider: _this.state.providerSelected,
                probability: _this.state.probabilityTypeSelect,
                versionDate: _this.state.versionDate,
                startDate: _this.state.startDateQq + "-" + _this.state.startDateYear,
                endDate: _this.state.endDateQq + "-" + _this.state.endDateYear,
            };
            if (_this.state.applyButton) {
                _this.props.globalFilterStore(capacitySelectedItems, "capacity");
                _this.props.OnApply(_this.generateURL.replace(/\?&+/g, "?").replace(/&+/g, "&"));
            }
            _this.handleClickOutside();
            // }
        };
        _this.handleClickOutside = function (e) {
            var filterDiv = document.getElementById("filterPanel");
            if (filterDiv.style.zIndex === "9999") {
                filterDiv.style.zIndex = "-1";
                _this.setState({ filterbtnisOn: !_this.state.filterbtnisOn });
            }
            _this.setState({
                selectedBadgeIndex: -1,
            });
            if (e === undefined)
                return;
            for (var i = 0; i < e.path.length; i++) {
                if (e.path[i].className &&
                    (e.path[i].className.includes("delta-popup") ||
                        e.path[i].className.includes("k-popup"))) {
                    return;
                }
            }
            _this.setState({
                showPopup: false,
                showLastUpdateCalendar: false,
                showVersionCalendar: false,
            });
        };
        _this.applyButtonEnable = function (buttonType) {
            var applyButtonEnable = new Map();
            if (_this.state.applyButtonEnable) {
                _this.setState({
                    applyButtonEnable: __spreadArray(__spreadArray([], _this.state.applyButtonEnable, true), applyButtonEnable.set(buttonType, 1), true),
                }, function () {
                    if (_this.state.applyButtonEnable.length > 1) {
                        _this.setState({
                            applyButton: true,
                        });
                    }
                });
            }
            else {
                _this.setState({
                    applyButtonEnable: applyButtonEnable.set(buttonType, 1),
                });
            }
        };
        // qpicker = (q) => {
        //   let value = "";
        //   if (q == 1 || q == 2 || q == 3) {
        //     value = "Q1";
        //   } else if (q == 4 || q == 5 || q == 6) {
        //     value = "Q2";
        //   } else if (q == 7 || q == 8 || q == 9) {
        //     value = "Q3";
        //   } else if (q == 10 || q == 11 || q == 12) {
        //     value = "Q4";
        //   }
        //   return value;
        // };
        // this code will help global type and duration for incremental capacity
        /*doUnitToggle = (id) => {
          this.type = id;
          this.getSource(id, this.duration);
        };
       
        doDMQToggle = (id) => {
          this.duration = id;
          this.getSource(this.type, id);
        };
       
        removeParam = (key, sourceURL) => {
          var rtn = sourceURL.split("?")[0],
            param,
            params_arr = [],
            queryString =
              sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
          if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
              param = params_arr[i].split("=")[0];
              if (param === key) {
                params_arr.splice(i, 1);
              }
            }
            rtn = rtn + "?" + params_arr.join("&");
          }
          return rtn;
        };
       
        durationData = (object, durationId) => {
          let filterSet;
          switch (object.typeFilter) {
            case "Unit":
              filterSet = this.generateURL
                .split("&")
                .filter((value, i) => {
                  let valueSplit = value.split("=");
                  return valueSplit[0] !== "ust";
                })
                .join("&");
              break;
            case "Unit Subtype":
              filterSet = this.generateURL;
              break;
            case "Unit Type":
              filterSet = this.generateURL
                .split("&")
                .filter((value, i) => {
                  let valueSplit = value.split("=");
                  return valueSplit[0] !== "unit";
                })
                .join("&")
                .split("&")
                .filter((value, i) => {
                  let valueSplit = value.split("=");
                  return valueSplit[0] !== "ust";
                })
                .join("&");
              break;
            default:
              break;
          }
          this.props.OnApply(
            filterSet.replace(/\?&+/g, "?").replace(/&+/g, "&") +
              "&groupformat=" +
              JSON.stringify(object)
          );
        };
       
        getSource = (assetTypeId, durationId) => {
          this.setState({ loader: !this.state.loader });
          let object = {
            dmqFilter: this.getStringFormat(durationId, "duration"),
            typeFilter: this.getStringFormat(assetTypeId, "assetType"),
          };
          this.durationData(object, durationId);
        };*/
        //Format
        _this.getStringFormat = function (idInput, type) {
            var stringFormat;
            if (type === "duration") {
                switch (idInput) {
                    case 1:
                        stringFormat = "Daily";
                        break;
                    case 2:
                        stringFormat = "Monthly";
                        break;
                    case 3:
                        stringFormat = "Quarterly";
                        break;
                    default:
                        break;
                }
            }
            else {
                switch (idInput) {
                    case 1:
                        stringFormat = "Unit Type";
                        break;
                    case 2:
                        stringFormat = "Unit Subtype";
                        break;
                    case 3:
                        stringFormat = "Unit";
                        break;
                    default:
                        break;
                }
            }
            return stringFormat;
        };
        _this.onBadgeClick = function (e) {
            var filterDiv = document.getElementById("filterPanel");
            filterDiv.style.zIndex = "9999";
            _this.setState({
                selectedBadgeIndex: e.target.id,
            });
            switch (e.target.id) {
                case "0":
                    document.getElementById("country-search-box").focus();
                    break;
                case "1":
                    document.getElementById("city-search-box").focus();
                    break;
                case "2":
                    document.getElementById("company-search-box").focus();
                    break;
                case "3":
                    document.getElementById("assetClass-search-box").focus();
                    break;
                case "4":
                    document.getElementById("asset-search-box").focus();
                    break;
                case "5":
                    document.getElementById("unitType-search-box").focus();
                    break;
                case "6":
                    document.getElementById("unitSubType-search-box").focus();
                    break;
                case "7":
                    var el = document.getElementById("unit-search-box");
                    if (el)
                        el.focus();
                    break;
                default:
                    return;
            }
        };
        _this.state = {
            show: false,
            badgeName: [
                "Countries",
                "Cities",
                "Owners",
                "Asset Type",
                "Assets",
                "Unit Type",
                "Unit Subtype",
                "Unit",
            ],
            Countries: 0,
            Cities: 0,
            Owners: 0,
            AssetType: 0,
            Assets: 0,
            UnitType: 0,
            UnitSubtype: 0,
            Unit: 0,
            count: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            selected: 0,
            delta: false,
            countries: "Countries",
            cities: "Cities",
            company: "Companies",
            assetClass: "AssetClasses",
            assets: "Assets/AssetFilter",
            assetUnitType: "AssetUnitTypes",
            assetUnitSubtype: "AssetUnitSubtypes",
            assetUnit: "AssetUnits/AssetUnitFilter",
            pathStore: [],
            filterData: [],
            value: [],
            countriesData: [],
            refresh: false,
            versionDate: new Date(),
            onApplyChange: true,
            resetCountries: false,
            resetCities: false,
            resetCompany: false,
            resetAssetClass: false,
            resetAssets: false,
            resetAssetUnitType: false,
            resetAssetUnitSubType: false,
            resetAssetUNit: false,
            resetDenote: true,
            currentSelectedCountries: [],
            currentSelectedCompanies: [],
            currentSelectedCities: [],
            currentSelectedAssetClasses: [],
            currentSelectedAssets: [],
            currentSelectedAssetUnitTypes: [],
            currentSelectedAssetUnitSubtypes: [],
            currentSelectedAssetUnit: [],
            resetToDefault: false,
            providerSelected: [],
            probabilityTypeSelect: [],
            startDateQq: "Q1",
            endDateQq: "",
            startDateYear: "1990",
            endDateYear: "",
            currentSelectedFilters: new Map(),
            defaultProbability: { value: 1, label: "Firm" },
            groupByCountry: false,
            groupByAsset: true,
            deltaBetweenProviders: true,
            deltaBetweenVersions: false,
            baseProvider: null,
            limitForHighlightProvider: 10,
            deltaDropdownItems: [],
            version1Date: moment(new Date()).format("YYYY-MM-DD"),
            version2Date: moment(new Date()).format("YYYY-MM-DD"),
            limitForHighlightVersion: 10,
            showPopup: false,
            lastUpdateDate: moment(new Date()).format("YYYY-MM-DD"),
            isLastUpdateSince: false,
            showVersionCalendar: false,
            showLastUpdateCalendar: false,
            versionDatePicker: false,
            selectedBadgeIndex: -1,
        };
        _this.dateQuater = {
            start: { Q1: "01 JAN", Q2: "01 APR", Q3: "01 JUL", Q4: "01 OCT" },
            end: { Q1: "31 MAR", Q2: "30 JUN", Q3: "30 SEP", Q4: "31 DEC" },
        };
        _this.generateURL = "";
        _this.duration = 2;
        _this.type = 2;
        _this.currentMonth = new Date().getMonth();
        _this.currentYear = new Date().getFullYear();
        return _this;
    }
    FilterContainer.prototype.render = function () {
        var _this = this;
        var obj = this.state;
        var _a = this.state, countries = _a.countries, cities = _a.cities, company = _a.company, assetClass = _a.assetClass, assets = _a.assets, assetUnitType = _a.assetUnitType, assetUnitSubtype = _a.assetUnitSubtype, assetUnit = _a.assetUnit;
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "container-fluid background-filter-fill pl-0 pr-0" }, { children: _jsxs("div", __assign({ className: "m-0  position-relative" }, { children: [_jsxs("div", __assign({ className: "row no-gutters justify-content-between pt-3 pb-3 pr-3 position-relative" }, { children: [_jsxs("div", __assign({ className: "col-sm-10 p-0 float-left" }, { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsx(FilterBtn, { isOn: this.state.filterbtnisOn, handleToggle: this.filterBtnToggle }) })), _jsx("div", __assign({ className: "region" }, { children: obj.badgeName.map(function (name, index) {
                                                return (_jsx(Badge, { list: name + "-" + _this.state.pathStore.length, name: name, count: obj[name.replace(/ /g, "")], onBadgeClick: _this.onBadgeClick, id: index, selectedBadge: _this.state.selectedBadgeIndex }, index));
                                            }) }))] })), _jsx("div", __assign({ className: "col-sm-2 pr-3" }, { children: _jsxs("div", __assign({ className: "row justify-content-end" }, { children: [_jsx("div", __assign({ className: "mr-3" }, { children: _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear apply", disabled: this.state.applyButton ? false : true, onClick: function () { return _this.applyOnCLick(); } }, { children: ["Apply ", _jsx("i", { className: "fa fa-paint-brush" })] })) })) })), _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear", onClick: this.resetHandler }, { children: ["Reset ", _jsx("i", { className: "fa fa-undo" })] })) }))] })) }))] })), _jsxs("div", __assign({ id: "filterPanel", className: "row capacity-select rounded border px-3 ml-0 mr-0", 
                            // style={{ display: "none" }}
                            style: { zIndex: "-1" } }, { children: [_jsxs("div", __assign({ className: "country f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Country" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "country", code: "geopoliticalEntityNm", path: countries.replace(/\?&+/g, "?").replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedCountries, isReset: this.state.resetCountries, resetFunction: this.resetFunction, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .Countries
                                                    : null, setAllFilters: this.props.setAllFilters }, countries) })] })), _jsxs("div", __assign({ className: "cities f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Cities" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "city", code: "geopoliticalEntityNm", path: cities.replace(/\?&+/g, "?").replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedCities, isReset: this.state.resetCities, resetFunction: this.resetFunction, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .Cities
                                                    : null, setAllFilters: this.props.setAllFilters }, cities) })] })), _jsxs("div", __assign({ className: "owners f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Owners" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "company", code: "companyCd", path: company.replace(/\?&+/g, "?").replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedCompanies, isReset: this.state.resetCompany, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .Companies
                                                    : null, setAllFilters: this.props.setAllFilters }, company) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Asset Type" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "assetClass", code: "assetClassDesc", path: assetClass.replace(/\?&+/g, "?").replace(/&+/g, "&"), previousSelection: this.state.currentSelectedAssetClasses, isReset: this.state.resetAssetClass, eventManagement: this.eventManagement, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .assetClasses
                                                    : null, setAllFilters: this.props.setAllFilters }, assetClass) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Assets" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "asset", code: "assetNm", path: assets.replace(/\?&+/g, "?").replace(/&+/g, "&"), previousSelection: this.state.currentSelectedAssets, isReset: this.state.resetAssets, eventManagement: this.eventManagement, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .assets
                                                    : null, setAllFilters: this.props.setAllFilters }, assets) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Unit Type" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "unitType", code: "assetUnitTypeCd", path: assetUnitType
                                                    .replace(/\?&+/g, "?")
                                                    .replace(/&+/g, "&"), previousSelection: this.state.currentSelectedAssetUnitTypes, isReset: this.state.resetAssetUnitType, eventManagement: this.eventManagement, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .assetUnitType
                                                    : null, setAllFilters: this.props.setAllFilters }, assetUnitType) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Unit Subtype" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "unitSubType", code: "assetUnitSubtypeCd", path: assetUnitSubtype
                                                    .replace(/\?&+/g, "?")
                                                    .replace(/&+/g, "&"), previousSelection: this.state.currentSelectedAssetUnitSubtypes, isReset: this.state.resetAssetUnitSubType, eventManagement: this.eventManagement, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                    typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                    ? this.props.mainFilterSelectedItems.get("capacity")
                                                        .assetUnitSubType
                                                    : null, setAllFilters: this.props.setAllFilters }, assetUnitSubtype) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Unit" })), Object.keys(this.state.currentSelectedAssetUnitSubtypes)
                                            .length > 0 ? (_jsx(ErrorBoundary, { children: _jsx(Filter, { id: "unit", code: "assetUnitNm", path: assetUnit.replace(/\?&+/g, "?").replace(/&+/g, "&") +
                                                    "&placeholder=true", isReset: this.state.resetAssetUNit, previousSelection: this.state.currentSelectedAssetUnit, eventManagement: this.eventManagement, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, onSwitchPreviousSelectedItems: null }, assetUnit) })) : (_jsx("p", __assign({ className: "title py-5" }, { children: "Please select Unit Subtype filter to populate Unit Filter values" })))] }))] })), _jsxs("div", __assign({ className: "row no-gutters border-top pr-3 pt-3 pb-3 select-buttons" }, { children: [_jsx("div", __assign({ className: "mr-3" }, { children: !this.props.currentScreen && (_jsxs(ButtonGroup, __assign({ className: "bg-solid" }, { children: [_jsx(Button, __assign({ className: "btn border pt-2 pb-2 pl-3 pr-3", togglable: true, selected: this.state.groupByCountry, onClick: function () {
                                                    _this.groupByParamChange("country");
                                                } }, { children: "Country" })), _jsx(Button, __assign({ className: "btn border pt-2 pb-2 pl-3 pr-3", togglable: true, selected: this.state.groupByAsset, onClick: function () {
                                                    _this.groupByParamChange("asset");
                                                } }, { children: "Asset" }))] }))) })), _jsx("div", __assign({ className: "mr-3 bg-solid", id: "provider" }, { children: _jsx(DropdownMultiSelect, { ref: function (ref) { return (_this.provider = ref); }, title: "Provider", default: "Selected(0)", path: "DataProviders/UserSpecificDataProvider", name: "dataProviderAbbr", reset: this.state.resetToDefault, selectHandler: this.onProviderSelect, applyButtonEnable: this.applyButtonEnable, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                            typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                            ? this.props.mainFilterSelectedItems.get("capacity")
                                                .provider
                                            : null, fillParentProvidersArray: function (providersArray) {
                                            _this.setState({
                                                deltaDropdownItems: __spreadArray([
                                                    { value: 0, label: "Baseline" }
                                                ], providersArray, true),
                                            });
                                        } }) })), _jsx("div", __assign({ className: "mr-3 bg-solid" }, { children: this.props.currentScreen ? (_jsx("div", __assign({ className: "border rounded bg-transparent pt-2 pb-2 pl-3 pr-3" }, { children: _jsxs("div", __assign({ className: "row justify-content-between no-gutters" }, { children: [_jsx("div", __assign({ className: "col-sm-auto" }, { children: _jsx("div", __assign({ className: "start-date-select" }, { children: _jsx(QuaterPicker, { ref: function (ref) { return (_this.startDate = ref); }, text: "Start", align: "left", selectHandler: this.onStartDateSelect, defaultQq: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .startDate.split("-")[0]
                                                                : this.currentMonth <= 2
                                                                    ? "Q4"
                                                                    : this.currentMonth >= 3 &&
                                                                        this.currentMonth <= 5
                                                                        ? "Q1"
                                                                        : this.currentMonth >= 6 &&
                                                                            this.currentMonth <= 8
                                                                            ? "Q2"
                                                                            : "Q3", defaultYear: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .startDate.split("-")[1] == 1800
                                                                    ? this.currentMonth <= 2
                                                                        ? this.currentYear - 1
                                                                        : this.currentYear
                                                                    : this.props.mainFilterSelectedItems
                                                                        .get("capacity")
                                                                        .startDate.split("-")[1]
                                                                : this.currentMonth <= 2
                                                                    ? this.currentYear - 1
                                                                    : this.currentYear, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems.get("capacity").startDate
                                                                : null }, this.props.currentScreen + "picker1") })) })), _jsx("div", __assign({ className: "ml-1" }, { children: _jsx("div", __assign({ className: "end-date-select ml-4" }, { children: _jsx(QuaterPicker, { ref: function (ref) { return (_this.endDate = ref); }, text: "End", align: "right", selectHandler: this.onEndDateSelect, defaultQq: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .endDate.split("-")[0]
                                                                : this.currentMonth <= 2
                                                                    ? "Q4"
                                                                    : this.currentMonth >= 3 &&
                                                                        this.currentMonth <= 5
                                                                        ? "Q1"
                                                                        : this.currentMonth >= 6 &&
                                                                            this.currentMonth <= 8
                                                                            ? "Q2"
                                                                            : "Q3", defaultYear: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .endDate.split("-")[1]
                                                                : this.currentMonth <= 2
                                                                    ? this.currentYear + 2
                                                                    : this.currentYear + 3, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems.get("capacity").endDate
                                                                : null }, this.props.currentScreen + "picker2") })) }))] })) }))) : (_jsx("div", __assign({ className: "border rounded bg-transparent pt-2 pb-2 pl-3 pr-3" }, { children: _jsxs("div", __assign({ className: "row justify-content-between no-gutters" }, { children: [_jsx("div", __assign({ className: "col-sm-auto" }, { children: _jsx("div", __assign({ className: "start-date-select" }, { children: _jsx(QuaterPicker, { ref: function (ref) { return (_this.startDate = ref); }, text: "Start", align: "left", selectHandler: this.onStartDateSelect, defaultQq: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .startDate.split("-")[0]
                                                                : "Q1", defaultYear: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .startDate.split("-")[1]
                                                                : 1800, minYear: 2007, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems.get("capacity").startDate
                                                                : null }, this.props.currentScreen + "picker3") })) })), _jsx("div", __assign({ className: "ml-1" }, { children: _jsx("div", __assign({ className: "end-date-select ml-4" }, { children: _jsx(QuaterPicker, { ref: function (ref) { return (_this.endDate = ref); }, text: "End", align: "right", selectHandler: this.onEndDateSelect, defaultQq: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .endDate.split("-")[0]
                                                                : this.currentMonth <= 2
                                                                    ? "Q1"
                                                                    : this.currentMonth >= 3 &&
                                                                        this.currentMonth <= 5
                                                                        ? "Q2"
                                                                        : this.currentMonth >= 6 &&
                                                                            this.currentMonth <= 8
                                                                            ? "Q3"
                                                                            : "Q4", defaultYear: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems
                                                                    .get("capacity")
                                                                    .endDate.split("-")[1]
                                                                : this.currentYear, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? this.props.mainFilterSelectedItems.get("capacity").endDate
                                                                : null }, this.props.currentScreen + "picker4") })) }))] })) }))) })), _jsx("div", __assign({ className: "mr-3 bg-solid", style: { width: "194px" }, id: "ptype" }, { children: this.props.currentScreen ? (_jsx(DropdownMultiSelect, { ref: function (ref) { return (_this.probability = ref); }, title: "P.Type", default: "Selected(0)", path: "PlanningProbabilityTypes", name: "planningProbabilityTypeCd", reset: this.state.resetToDefault, selectHandler: this.onProbabilityTypeSelect, applyButtonEnable: this.applyButtonEnable, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                            typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                            ? this.props.mainFilterSelectedItems.get("capacity")
                                                .probability
                                            : null }, this.props.currentScreen + "pType1")) : (_jsx(DropdownMultiSelect, { ref: function (ref) { return (_this.probability = ref); }, title: "P.Type", default: this.state.defaultProbability, path: "PlanningProbabilityTypes", name: "planningProbabilityTypeCd", reset: this.state.resetToDefault, selectHandler: this.onProbabilityTypeSelect, applyButtonEnable: this.applyButtonEnable, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                            typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                            ? this.props.mainFilterSelectedItems.get("capacity")
                                                .probability
                                            : null }, this.props.currentScreen + "pType2")) })), _jsxs(ButtonGroup, { children: [_jsx(Button, __assign({ className: "pl-3 pr-3" + (!this.props.currentScreen ? " mr-3" : ""), style: !this.props.currentScreen || this.state.isLastUpdateSince
                                                ? { backgroundColor: "#202020" }
                                                : { backgroundColor: "#003c88" }, onClick: function () {
                                                _this.setState({
                                                    showVersionCalendar: !_this.state.showVersionCalendar,
                                                    showLastUpdateCalendar: false,
                                                    isLastUpdateSince: false,
                                                });
                                            } }, { children: _jsx("div", __assign({ className: "row justify-content-between no-gutters" }, { children: _jsx("div", __assign({ className: "col-sm-auto" }, { children: _jsx("div", __assign({ className: "version-date-select" }, { children: _jsx(DatePicker, { popup: this.CustomPopup, dateInput: function () { return _this.CustomDateInputVersion(); }, defaultValue: this.props.mainFilterSelectedItems.size > 0 &&
                                                                typeof this.props.mainFilterSelectedItems.get("capacity") !== "undefined"
                                                                ? new Date(this.props.mainFilterSelectedItems.get("capacity").versionDate)
                                                                : new Date(), onChange: this.versionHandleChange, show: this.state.showVersionCalendar }) })) })) })) })), this.props.currentScreen && (_jsx(Button, __assign({ className: "pl-3 pr-3 mr-3", style: !this.state.isLastUpdateSince
                                                ? { backgroundColor: "#202020" }
                                                : { backgroundColor: "#003c88" }, onClick: function () {
                                                _this.setState({
                                                    showVersionCalendar: false,
                                                    showLastUpdateCalendar: !_this.state
                                                        .showLastUpdateCalendar,
                                                    isLastUpdateSince: true,
                                                });
                                            } }, { children: _jsx("div", __assign({ className: "row justify-content-between no-gutters" }, { children: _jsx("div", __assign({ className: "col-sm-auto" }, { children: _jsx("div", __assign({ className: "version-date-select" }, { children: _jsx(DatePicker, { popup: this.CustomPopup, dateInput: function () { return _this.CustomDateInputLastUpdate(); }, onChange: function (e) {
                                                                _this.setState({
                                                                    lastUpdateDate: moment(e.value).format("DD MMM YYYY"),
                                                                });
                                                            }, show: this.state.showLastUpdateCalendar }) })) })) })) })))] }), _jsxs(ButtonGroup, __assign({ className: "mr-3 bg-solid" }, { children: [_jsx("button", __assign({ className: "btn border py-2 px-3 k-button", onClick: function () {
                                                _this.setState({
                                                    delta: false,
                                                    showPopup: false,
                                                }, function () {
                                                    // this.props.setBaseProvider(null);
                                                    // this.props.deltaActive(false);
                                                    // this.setState({
                                                    //   delta: false,
                                                    // });
                                                    // this.props.getSourceOnRefresh();
                                                    _this.applyOnCLick();
                                                });
                                            }, style: !this.state.delta ? { background: "#003c88" } : null }, { children: "KB/D" })), _jsx("button", __assign({ ref: function (button) {
                                                _this.anchor = button;
                                            }, className: "btn border py-2 px-3 k-button", onClick: function () {
                                                _this.setState({
                                                    delta: true,
                                                    showPopup: !_this.state.showPopup,
                                                });
                                            }, style: this.state.delta ? { background: "#003c88" } : null }, { children: "Delta" })), _jsx(Popup, __assign({ anchor: this.anchor, anchorAlign: {
                                                horizontal: "right",
                                                vertical: "bottom",
                                            }, popupAlign: {
                                                horizontal: "center",
                                                vertical: "top",
                                            }, show: this.state.showPopup, popupClass: "delta-popup" }, { children: _jsxs("div", { children: [_jsxs("ul", __assign({ className: "d-flex" }, { children: [_jsx("li", __assign({ className: this.state.deltaBetweenProviders
                                                                    ? "delta-events-button active"
                                                                    : "delta-events-button", style: { cursor: "pointer" }, onClick: this.onDeltaBetweenProvidersClick }, { children: "Delta Between Provider" })), _jsx("li", __assign({ className: this.state.deltaBetweenVersions
                                                                    ? "delta-events-button active"
                                                                    : "delta-events-button", style: { cursor: "pointer" }, onClick: this.onDeltaBetweenVersionsClick }, { children: "Delta Between Versions" }))] })), this.state.deltaBetweenProviders ? (_jsxs("div", __assign({ id: "popup-content-provider" }, { children: [_jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("p", __assign({ className: "dim-text", style: { marginBottom: "6px" } }, { children: "Select the base to show differences of others" })), _jsx(DropDownList, { data: this.state.deltaDropdownItems, style: {
                                                                            width: "100%",
                                                                            backgroundColor: "#282828 !important",
                                                                        }, onChange: this.onBaseProviderChange, textField: "label", dataItemKey: "value", value: this.state.baseProvider
                                                                            ? this.state.baseProvider
                                                                            : {
                                                                                value: null,
                                                                                label: (_jsx("span", __assign({ className: "dim-text" }, { children: "Select a Base Provider" }))),
                                                                            } })] })), _jsxs("div", __assign({ className: "pt-3 pb-3" }, { children: [_jsx("p", __assign({ className: "dim-text", style: { marginBottom: "6px" } }, { children: "Highlight values over +/-" })), _jsx("label", { children: _jsx(NumericTextBox, { placeholder: "please enter value", value: this.state.limitForHighlightProvider, onChange: this.onLimitForHighlightProviderChange }) }), _jsx("span", __assign({ className: "px-2" }, { children: "KB/D" }))] })), _jsx("div", { children: _jsx(Button, __assign({ primary: true, style: { width: "100%" }, onClick: this.showDeltaBetweenProviders, disabled: this.state.baseProvider === null ||
                                                                        this.state.limitForHighlightProvider === null
                                                                        ? true
                                                                        : false }, { children: "Show Delta Between Provider" })) })] }))) : (_jsxs("div", __assign({ id: "popup-content-version" }, { children: [_jsxs("div", __assign({ className: "pt-4" }, { children: [_jsx("p", __assign({ className: "dim-text", style: { marginBottom: "6px" } }, { children: "Select the base to show differences of others" })), _jsx("div", { children: _jsx(DatePicker, { dateInput: function () {
                                                                                return _this.CustomDateInputVersion("Version 1", _this.state.version1Date);
                                                                            }, defaultValue: new Date(), versionDateType: "1", onChange: this.onVersionDateChange }) }), _jsx("div", __assign({ className: "pt-2" }, { children: _jsx(DatePicker, { dateInput: function () {
                                                                                return _this.CustomDateInputVersion("Version 2", _this.state.version2Date);
                                                                            }, defaultValue: new Date(), versionDateType: "2", onChange: this.onVersionDateChange }) }))] })), _jsxs("div", __assign({ className: "pt-3 pb-3" }, { children: [_jsx("p", __assign({ className: "dim-text", style: { marginBottom: "6px" } }, { children: "Highlight values over +/-" })), _jsx("label", { children: _jsx(NumericTextBox, { placeholder: "please enter value", value: this.state.limitForHighlightVersion, onChange: this.onLimitForHighlightVersionChange }) }), _jsx("span", __assign({ className: "px-2" }, { children: "KB/D" }))] })), _jsx("div", { children: _jsx(Button, __assign({ primary: true, onClick: this.showDeltaBetweenVersions, disabled: this.state.version1Date &&
                                                                        this.state.version2Date
                                                                        ? false
                                                                        : true, style: { width: "100%" } }, { children: "Show Delta Between Versions" })) })] })))] }) }))] }))] }))] })) })) }));
    };
    return FilterContainer;
}(Component));
export default onClickOutside(FilterContainer);
