import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useContext, forwardRef } from "react";
import { ApiContext } from "./apiContext";
import SupplyGradeDataGrid from "./SupplyGradeDataGrid";
import Spinner from "../../../../../commons/spinner";
import { getSourceFilter, saveSourceData, } from "../../../../../services/urlService";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
var moment = require("moment");
var SupplyGrade = forwardRef(function (props, ref) {
    var _a = useContext(ApiContext), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState(false), loader = _b[0], setLoader = _b[1];
    var _c = useState([]), newData = _c[0], setNewData = _c[1];
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var handleClose = function () {
        setOpen(false);
    };
    function productionSave() {
        var path = "productionGradeAddEdit";
        if (apiParams.productionAddEditMonths.length > 0 &&
            apiParams.comments == "") {
            setOpen(true);
            // alert("Please put the comment after production qty is updated.");
        }
        else if (apiParams.productionAddEditMonths.length > 0 ||
            apiParams.comments !== "") {
            setLoader(true);
            if (apiParams.productionAddEditMonths.length <= 0) {
                var selectedCountryKey = props.selectedCountryKey;
                var selectedGradeKey = props.selectedGradeKey;
                apiParams.country = props.selectedData[selectedCountryKey].countryKey;
                //apiParams.materialProduct = props.selectedProduct;
                apiParams.materialFamily = props.selectedFamily;
                apiParams.materialGroup = props.selectedGroup;
                apiParams.materialGrade =
                    props.selectedData[selectedCountryKey].productionGradeDetails[selectedGradeKey].gradeKey;
                apiParams.quantityChanged = false;
                for (var i = 0; i <
                    props.selectedData[selectedCountryKey].productionGradeDetails[selectedGradeKey].quantityMonths.length; i++) {
                    var qty = props.selectedData[selectedCountryKey].productionGradeDetails[selectedGradeKey].quantityMonths[i].quantities[1].quantity;
                    if (qty != 0) {
                        var month = moment(props.selectedData[selectedCountryKey].productionGradeDetails[selectedGradeKey].quantityMonths[i].month).format("yyyy-MM-DD");
                        var obj = {
                            idSelected: true,
                            month: month,
                            monthIndex: i,
                            quantity: qty,
                            originalQuantity: qty,
                        };
                        apiParams.productionAddEditMonths.push(obj);
                    }
                }
            }
            var saveReturn = saveSourceData(apiParams, path).then(function (response) {
                if (typeof response === "undefined") {
                    //  props.setEnableSaveButton(true);
                    // setOverlay(false);
                    setLoader(false);
                }
                else if (response.status === 200 || response.status === 201) {
                    // props.setShouldRefresh();
                    //setOverlay(false);
                    props.setShouldRefresh(true);
                    var refreshReturn = getSourceFilter(props.applyUrl, "ProductionGrade")
                        .then(function (returnData) {
                        if (typeof returnData !== "undefined" &&
                            returnData.data.tableData.length > 0) {
                            props.setSelectedData(returnData.data.tableData);
                            props.setGraphData(returnData.data.graphData);
                            setNewData(returnData.data.tableData);
                            props.setShouldRefresh(false);
                            setLoader(false);
                            setApiParams(function (prevParams) {
                                var newParams = __assign({}, prevParams);
                                var len = newParams.productionAddEditMonths.length;
                                for (var i = 0; i < len; i++) {
                                    newParams.productionAddEditMonths[i].originalQuantity =
                                        newParams.productionAddEditMonths[i].quantity;
                                }
                                return newParams;
                                // productionSave: getRandom(),
                            });
                        }
                    })
                        .catch(function (error) {
                        setLoader(false);
                    });
                    //this.props.onApply(props.applyUrl);
                }
                else {
                    // props.setShouldRefresh(true);
                    //props.setEnableSaveButton(true);
                    //setOverlay(false);
                    setLoader(false);
                }
            });
        }
        else {
            alert("Nothing is changed. No save needed.");
        }
    }
    function lockToBaseline(e) {
        var newParams = __assign({}, apiParams);
        newParams.lockedToBaseline = e.target.checked;
        setApiParams(newParams);
    }
    function scaleCondensate(e) {
        var newParams = __assign({}, apiParams);
        newParams.scaleAssociatedCondensate = e.target.checked;
        setApiParams(newParams);
    }
    function saveComment(e) {
        var newParams = __assign({}, apiParams);
        newParams.comments = e.target.value;
        setApiParams(newParams);
    }
    function createRowData() {
        var rawData = __spreadArray([], props.selectedData[props.selectedCountryKey].productionGradeDetails[props.selectedGradeKey].quantityMonths, true);
        var rowData = rawData.reduce(function (data, curr, i) {
            if (!(i % 12)) {
                data.push(rawData.slice(i, i + 12));
            }
            return data;
        }, []);
        // setNewData(rowData);
        return rowData;
    }
    return (_jsxs(_Fragment, { children: [loader && _jsx(Spinner, {}), _jsx("div", __assign({ className: "row no gutters", style: { height: "100%" } }, { children: _jsx("div", __assign({ className: "p-0", style: { width: "100%" } }, { children: _jsx("div", __assign({ className: "event-area bg-solid pt-2 pb-2 pl-2", style: {
                            //height: "100%",
                            overflowY: "auto",
                            overflowX: "auto",
                            //marginBottom: "20px",
                        } }, { children: createRowData().map(function (data, index) {
                            return (_jsx("div", { children: _jsx(SupplyGradeDataGrid, { updatedKey: index, selectedData: props.selectedData, selectedCountryKey: props.selectedCountryKey, selectedGradeKey: props.selectedGradeKey, selectedProduct: props.selectedProduct, selectedGroup: props.selectedGroup, updatedMonthData: __spreadArray([], data, true) }) }));
                        }) })) })) })), _jsxs("div", __assign({ className: "row no-gutters mb-3", style: { marginTop: "20px" } }, { children: [_jsx("span", { className: "devider" }), _jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", __assign({ className: "title position-absolute bg-solid" }, { children: "*Comments(Reason for Change)" })), _jsx("textarea", { className: "border text-white bg-solid rounded p-4 w-100", placeholder: "Enter comments here", onChange: function (e) { return saveComment(e); } })] }))] })), _jsxs("div", __assign({ className: "text-right px-2 py-4" }, { children: [_jsx("span", { className: "devider" }), _jsx("input", { type: "checkbox", name: "lockStatus", checked: typeof apiParams.productionAddEditMonths === "undefined"
                            ? true
                            : apiParams.lockedToBaseline, onChange: function (e) { return lockToBaseline(e); } }), _jsxs("label", __assign({ htmlFor: "placeholder" }, { children: [" ", "\u00A0Locked to Baseline \u00A0\u00A0", " "] })), _jsx("button", __assign({ className: "btn btn-default mx-3 border", hidden: true }, { children: "Preview" })), _jsx("button", __assign({ className: "btn btn-primary", onClick: productionSave }, { children: "Save" })), _jsx("button", __assign({ className: "btn btn-default mx-3 border", onClick: function () { return props.onClickCancel(); } }, { children: "Close" })), _jsxs(Dialog, __assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogContent, { children: _jsx(DialogContentText, __assign({ id: "alert-dialog-description" }, { children: _jsxs("span", __assign({ style: { color: "#282828", fontSize: "1.1rem" } }, { children: [" ", "Please Enter the Comments When Production Quantity is Modified!"] })) })) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: handleClose, color: "#595959", autoFocus: true }, { children: "OK" })) })] }))] }))] }));
});
export default SupplyGrade;
