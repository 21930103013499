var _a;
var moment = require("moment");
//defaut data for api context
export var defaultConst = (_a = {
        Countries: [],
        Cities: [],
        Companies: [],
        AssetClasses: []
    },
    _a["Assets/AssetFilter"] = [],
    _a.AssetUnitTypes = [],
    _a.AssetUnitSubtypes = [],
    _a["AssetUnits/AssetUnitFilter"] = [],
    _a.AssetEventTypes = [],
    _a.EventCauseTypes = [],
    _a["DataProviders/UserSpecificDataProvider"] = [],
    _a.CapacityBasisTypes = [],
    _a.PlanningProbabilityTypes = [],
    _a.PlanningStatusTypes = [],
    _a.co = 0,
    _a.ci = 0,
    _a.o = 0,
    _a.at = 0,
    _a.a = 0,
    _a.ut = 0,
    _a.ust = 0,
    _a.unit = [],
    _a.multipleUnit = [],
    _a.path = [
        "Countries",
        "Cities",
        "Companies",
        "AssetClasses",
        "Assets/AssetFilter",
        "AssetUnitTypes",
        "AssetUnitSubtypes",
        "AssetUnits/AssetUnitFilter",
    ],
    _a.dataAreaPath = [
        "AssetEventTypes",
        "EventCauseTypes",
        "DataProviders/UserSpecificDataProvider",
        "CapacityBasisTypes",
        "PlanningProbabilityTypes",
        "PlanningStatusTypes",
    ],
    _a.capacityData = {
        assetUnitKey: [],
        capacityBasisTypeKey: null,
        planningProbabilityTypeKey: null,
        dataProviderKey: null,
        capacityOriginalUomKey: "",
        planningStatusTypeKey: null,
        capacityOriginalQty: 100,
        capacityStartDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        comment: "",
        url: "",
        baselinedFromKey: null,
        eventId: "",
    },
    _a.maintenanceData = {
        multiUnit: [],
        eventStartDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        eventEndDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        assetEventTypeKey: null,
        dataProviderKey: null,
        offlineCapacityOriginal: 100,
        eventCauseTypeKey: null,
        offlineCapacityOriginalUomKey: 2,
        offlineDataType: "PCT",
        assetUnitEventKey: "",
        eventLockInd: false,
        url: "",
        comment: "",
    },
    _a.maintenanceAssetUnitKey = {},
    _a.maintenanceAssetEventTypeKey = {},
    _a.maintenanceDataProviderKey = {},
    _a.maintenanceEventCauseTypeKey = {},
    _a.capacityAssetUnitKey = {},
    _a.capacityCapacityBasisTypeKey = {},
    _a.capacityPlanningProbabilityTypeKey = {},
    _a.capacityDataProviderKey = {},
    _a.capacityPlanningStatusTypeKey = {},
    _a.capacityShowEvent = false,
    _a.maintenanceShowEvents = false,
    _a.capacitySave = false,
    _a);
