import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, createContext } from "react";
export var ApiContext = createContext();
export var ApiProvider = function (props) {
    var _a = useState({
        comments: "",
        quantityChanged: false,
        country: 0,
        changeByOperation: "null",
        changeBy: 0,
        percentageOrAbsolute: "null",
        isProgessive: true,
        lockedToBaseline: true,
        //materialProduct: [],
        materialFamily: [],
        materialGroup: [],
        scaleAssociatedCondensate: true,
        materialGrade: 0,
        productionAddEditMonths: [],
    }), apiParams = _a[0], setApiParams = _a[1];
    return (_jsx(ApiContext.Provider, __assign({ value: [apiParams, setApiParams] }, { children: props.children })));
};
