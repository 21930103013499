import { __spreadArray } from "tslib";
import * as types from "../actions/masterScreenActionsType";
export default function masterScreenReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case types.LOAD_MASTER_SCREEN_DATA:
            var masterData = new Map();
            var currentMasterState = state.masterData;
            if (typeof currentMasterState !== "undefined") {
                masterData.set(action.masterDataType, action.masterData);
                var concatedArray = new Map(__spreadArray(__spreadArray([], currentMasterState, true), masterData, true));
                return { masterData: concatedArray };
            }
            else {
                masterData.set(action.masterDataType, action.masterData);
                return { masterData: masterData };
            }
        default:
            return state;
    }
}
