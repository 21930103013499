import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
import UploadService from "./uploadService";
import { DropzoneArea } from "material-ui-dropzone";
var UploadFile = /** @class */ (function (_super) {
    __extends(UploadFile, _super);
    function UploadFile(props) {
        var _this = _super.call(this, props) || this;
        _this.selectFile = _this.selectFile.bind(_this);
        _this.upload = _this.upload.bind(_this);
        _this.state = {
            selectedFiles: [],
            currentFile: undefined,
            progress: 0,
            message: "",
            windowOverLay: false,
            fileInfos: [],
        };
        return _this;
    }
    UploadFile.prototype.componentDidMount = function () {
        /*   UploadService.getFiles().then((response) => {
          this.setState({
            fileInfos: response.data,
          });
        });*/
    };
    UploadFile.prototype.selectFile = function (event) {
        this.setState({
            selectedFiles: event,
        });
    };
    UploadFile.prototype.upload = function () {
        var _this = this;
        var currentFile = this.state.selectedFiles[0];
        this.setState({
            progress: 0,
            currentFile: currentFile,
        });
        UploadService.upload(currentFile, this.props.scenarioKey)
            .then(function (response) {
            _this.setState({
                message: response.data,
            });
            _this.props.onClose();
            //return UploadService.getFiles();
        })
            /*.then((files) => {
              this.setState({
                fileInfos: files.data,
              });
            })*/
            .catch(function () {
            _this.setState({
                progress: 0,
                message: "Could not upload the file!",
                currentFile: undefined,
            });
        });
        this.setState({
            selectedFiles: [],
        });
    };
    UploadFile.prototype.render = function () {
        var selectedFiles = this.state.selectedFiles;
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ style: { position: "relative" } }, { children: _jsxs("div", __assign({ className: "capacity-event", style: {
                        position: "relative",
                        height: "90%",
                        width: "100%",
                        // overflowY: "auto",
                    } }, { children: [_jsx("div", __assign({ style: { color: "#7f7f7f" } }, { children: _jsx(DropzoneArea, { dropzoneText: "Drag and drop an excel here or click", onChange: this.selectFile }) })), _jsxs("div", __assign({ className: "row no-gutters mb-3", style: { marginTop: "20px" } }, { children: [_jsx("span", { className: "devider" }), _jsxs("div", __assign({ className: "col" }, { children: [_jsx("label", __assign({ className: "title position-absolute bg-solid" }, { children: "Comments(Reason for Change)" })), _jsx("textarea", { className: "border text-white bg-solid rounded p-4 w-100", placeholder: "Enter comments here" })] }))] })), _jsxs("div", __assign({ className: "text-right px-1 py-1" }, { children: [_jsx("button", __assign({ className: "btn btn-primary", disabled: selectedFiles.length <= 0, onClick: this.upload }, { children: "Upload" })), _jsx("button", __assign({ className: "btn btn-default mx-3 border", onClick: this.props.onClose }, { children: "Close" }))] }))] })) })) }));
    };
    return UploadFile;
}(Component));
export { UploadFile };
export default UploadFile;
