import { __assign, __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
import { DropdownButton } from "react-bootstrap";
import MainMenu from "../../commons/mainMenu/mainMenu";
import { getProgramsMenu } from "../../services/program";
import onClickOutside from "react-onclickoutside";
import { withRouter } from "react-router";
//import PropTypes from "prop-types";
import "./programs.css";
import logo from "../../assets/images/supply.svg";
var programsMenu = /** @class */ (function (_super) {
    __extends(programsMenu, _super);
    function programsMenu(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.programClickHandler = function (e) {
            _this.setState({ show: true });
        };
        _this.handleClickOutside = function (e) {
            if (_this.wrapperRef && !_this.wrapperRef.contains(e.target)) {
                _this.setState({ show: false });
            }
            else {
                _this.setState({ show: true });
            }
        };
        _this.componentDidUpdate = function (prevProps) {
            if (_this.props.location !== prevProps.location) {
                setTimeout(function () {
                    _this.setState({ show: false });
                }, 100);
            }
        };
        _this.state = {
            show: false,
            getData: getProgramsMenu(),
        };
        _this.setWrapperRef = _this.setWrapperRef.bind(_this);
        _this.handleClickOutside = _this.handleClickOutside.bind(_this);
        return _this;
    }
    programsMenu.prototype.setWrapperRef = function (node) {
        this.wrapperRef = node;
    };
    programsMenu.prototype.render = function () {
        return (_jsx("div", __assign({ ref: this.setWrapperRef }, { children: _jsx(DropdownButton, __assign({ title: _jsx("img", { alt: "shell-logo", src: logo }), className: "program-menu menu-item dropdown", "data-menu": "program-menu", variant: "none", drop: "right", id: "dropdown", onClick: this.programClickHandler, show: this.state.show }, { children: _jsx(MainMenu, { title: this.state.getData.title, caption: this.state.getData.caption, subMenuStructure: this.state.getData.menu, showProp: this.state.show, showAllOption: true, searchDisplay: "Geneva" }) })) })));
    };
    return programsMenu;
}(Component));
// programsMenu.propTypes = {
//   children: PropTypes.element.isRequired
// };
export default withRouter(onClickOutside(programsMenu));
