import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import SupplyProductionGradeAccordion from "./supplyProductionGradeAccordion";
import { getSourceFilter } from "../../../../../services/urlService";
import Spinner from "../../../../../commons/spinner.jsx";
import ChartList from "../../../../charts/chartList";
import { If } from "rc-if-else";
import logo from "../../../../../assets/images/Supply_Logo.svg";
var productionGradeDataGrid = /** @class */ (function (_super) {
    __extends(productionGradeDataGrid, _super);
    function productionGradeDataGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.componentDidUpdate = function () {
            // console.log('updated');
        };
        _this.state = {
            currentRandKey: "",
            data: [],
            loading: false,
            shouldRefresh: _this.props.shouldRefresh,
            //expOn: true,
        };
        return _this;
    }
    productionGradeDataGrid.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: this.props.data.length > 0 ? (
            // <Accordion>
            this.props.data.map(function (parent, i) {
                var randNum = _this.getRandom();
                return (_jsx(SupplyProductionGradeAccordion, { identifier: randNum, title: parent.country, countryId: parent.countryKey, accordionKey: i, data: parent, countryKey: i, onDataSelect: _this.props.onDataSelect, selectedCountryKey: _this.props.selectedCountryKey, selectedGradeKey: _this.props.selectedGradeKey, setShouldRefresh: _this.props.setShouldRefresh, shouldRefresh: _this.props.shouldRefresh, applyUrl: _this.props.applyUrl, setSelectedData: _this.props.setSelectedData, selectedMaterialGroup: _this.props.selectedMaterialGroup, 
                    //selectedMaterialProduct={this.props.selectedMaterialProduct}
                    selectedMaterialFamily: _this.props.selectedMaterialFamily, setGraphData: _this.props.setGraphData }, randNum));
            })) : (
            //</Accordion>
            _jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center" }, { children: [_jsx("img", { alt: "geneva-watermark-logo", src: logo }), _jsx("h3", __assign({ className: "mt-2", style: {
                                color: "#595959",
                                backgroundColor: "rgba(32,32,32,0.8)",
                            } }, { children: "No data available, Please reselect filters." }))] })) }))) }, this.state.currentRandKey ? this.getRandom : this.state.currentRandKey));
    };
    return productionGradeDataGrid;
}(Component));
export default productionGradeDataGrid;
