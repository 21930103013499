import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
//import CrossReferenceGridTemplate from "../../../../commons/crossReferenceGridTemplate";
import CrRefGridTemplate from "../../../../../../commons/crRefGridTemplate";
function storageTank(props) {
    var validationParam = {};
    var columnParams = [
        {
            field: "storageTankKey",
            title: "Storage Tank Key",
            editable: false,
        },
        {
            field: "storageTankName",
            title: "Storage Tank Name",
            editable: false,
            //key: "storageTankKey",
        },
        {
            field: "storageLocationNm",
            title: "Storage Location Name",
            editable: false,
            //key: "storageLocationKey",
        },
        {
            field: "geoPoliticalEntityNm",
            title: "Geopolitical Entity Name",
            editable: false,
            //key: "storageLocationKey",
        },
        {
            field: "versionTypeName",
            title: "Version Type",
            editable: false,
            // key: "versionTypeKey",
        },
        {
            field: "versionActiveInd",
            title: " Version Active Ind",
            editable: false,
        },
        {
            field: "dataProviderName",
            title: "Data Provider",
            editable: false,
            //key: "dataProviderKey",
        },
        {
            field: "tankOperationalStatusTypeDesc",
            title: "Tank Operational Status",
            // key: "tankOperationalStatusTypeKey",
            editable: false,
        },
        {
            field: "tankCommercialityTypeDesc",
            title: "Tank Commerciality Type",
            //key: "tankCommercialityTypeKey",
            editable: false,
        },
        {
            field: "materialTypeNm",
            title: "Material Type",
            key: "materialTypeKey",
        },
        {
            field: "universalHistoricMaximumQty",
            title: "Historical Max Capacity Qty",
            editable: false,
        },
        {
            field: "intelligenceSourceTxt",
            title: "Comments",
            editable: false,
        },
        {
            field: "versionEffectiveDt",
            title: "Version Effective Date",
            format: "{0:dd MMM yyyy}",
            editable: false,
        },
        {
            field: "versionTerminationDt",
            title: "Version Termination Date",
            format: "{0:dd MMM yyyy}",
            editable: false,
        },
        {
            field: "metaCreatorNm",
            title: "Last Create User",
            editable: false,
        },
        {
            field: "metaCreatedDttm",
            title: "Last Create Date",
            format: "{0:dd MMM yyyy hh:mm a}",
            editable: false,
        },
        {
            field: "metaChangedByNm",
            title: "Last Update User",
            editable: false,
        },
        {
            field: "metaChangedDttm",
            title: "Last Update Date",
            format: "{0:dd MMM yyyy hh:mm a}",
            editable: false,
        },
        {
            field: "universalUomName",
            title: "UOM",
            editable: false,
            //key: "universalUomKey",
        },
        /* {
            field: "recordEntryDttm",
            title: "Record Entry Dttm",
            filter: "date",
            format: "{0:dd MMM yyyy hh:mm a}",
            type: "meta",
            editable: false
          },
          {
            field: "metaQualityCd",
            title: "Meta Quality Cd",
            type: "meta",
            editable: false
          },
          {
            field: "metaActionCd",
            title: "Meta Action Cd",
            type: "meta",
            editable: false
          },
          {
            field: "metaCreatedDttm",
            title: "Meta Created Dttm",
            filter: "date",
            format: "{0:dd MMM yyyy hh:mm a}",
            type: "meta",
            editable: false
          },
          {
            field: "metaCreatorNm",
            title: "Meta Creator Nm",
            type: "meta",
            editable: false
          },
          {
            field: "metaChangedDttm",
            title: "Meta Changed Dttm",
            filter: "date",
            format: "{0:dd MMM yyyy hh:mm a}",
            type: "meta",
            editable: false
          },
          {
            field: "metaChangedByNm",
            title: "Meta Changed By Nm",
            type: "meta",
            editable: false
          }*/
    ];
    return (_jsx(CrRefGridTemplate, { column: columnParams, validation: validationParam, api: "storageTank" }));
}
export default storageTank;
