import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Route, Redirect, Switch, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import storageTank from "./storageTank";
import "../../../../geneva/generalConfiguration.css";
var storageTankReferenceNavLink = /** @class */ (function (_super) {
    __extends(storageTankReferenceNavLink, _super);
    function storageTankReferenceNavLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    storageTankReferenceNavLink.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "general-configuration mt-3" }, { children: [_jsxs("div", __assign({ className: "header-title mb-3" }, { children: ["Storage Tank ", _jsx("span", { children: "(Reference Data)" })] })), _jsxs("div", __assign({ className: "data-area-window h-100" }, { children: [_jsx(Navbar, __assign({ bg: "none", variant: "none", className: "data-area-window-menu" }, { children: _jsx(Nav, __assign({ className: "mr-auto" }, { children: _jsx(NavLink, __assign({ to: "/dashboard/globalInventoryDatabase/ReferenceData/tank/storageTank", className: "nav-link", activeClassName: "active" }, { children: "Storage Tank" })) })) })), _jsx("div", __assign({ className: "data-area h-100" }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/storageTankReference", to: "/dashboard/storageTankReference/storageTank", exact: true }), _jsx(Route, { path: "/dashboard/globalInventoryDatabase/ReferenceData/tank/storageTank", component: storageTank, exact: true })] }) }))] }))] })) }));
    };
    return storageTankReferenceNavLink;
}(Component));
export default storageTankReferenceNavLink;
