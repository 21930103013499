import { __assign, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle, } from "react";
import MaintenanceDataGrid from "./MaintenanceDataGrid";
import { ApiContext } from "./apiContext";
import { getSourceFilter, saveSourceData, editSourceData, } from "../../../../../services/urlService";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Filter from "./tools/Filter";
var moment = require("moment");
var Maintenance = forwardRef(function (props, ref) {
    //context
    var _a = useContext(ApiContext), apiParams = _a[0], setApiParams = _a[1];
    //function state set
    var _b = useState(false), showGrid = _b[0], setShowGrid = _b[1];
    var _c = useState([]), gridData = _c[0], setGridData = _c[1];
    var _d = useState(false), editEvent = _d[0], setEditEvent = _d[1];
    var _e = useState(false), newEvent = _e[0], setNewEvent = _e[1];
    var _f = useState(props.currentYearData >= 0 ? props.currentYearData : -1), currentYearOrHistory = _f[0], setCurrentYearOrHistory = _f[1];
    var _g = useState(false), newEventRandomKey = _g[0], setNewEventRandomKey = _g[1];
    var _h = useState(false), overlay = _h[0], setOverlay = _h[1];
    var _j = useState(0), history = _j[0], setHistory = _j[1];
    var _k = useState(false), onSaveRandomKey = _k[0], setOnSaveRandomKey = _k[1];
    var _l = useState([]), childEvents = _l[0], setChildEvents = _l[1];
    /*
     *component did mount using useeffect, this used to detect changes in unit or on random key change
     */
    useEffect(function () {
        var paramsToSet = apiParams.multipleUnit.map(function (obj) {
            return obj.id;
        });
        if (apiParams.multipleUnit.length > 0 &&
            paramsToSet.length > 0 &&
            props.unitOnly) {
            var yearRagneArg = currentYearOrHistory > -1 ? "&yearRange=" + currentYearOrHistory : "";
            setOverlay(true);
            props.setOverlay(true);
            getSourceFilter("assetUnitEvents?unitid=" +
                apiParams.multipleUnit
                    .map(function (obj) {
                    return obj.id;
                })
                    .filter(function (value, key) { return !(value === 0); })
                    .join("&unitid=") +
                yearRagneArg).then(function (response) {
                if (typeof response !== "undefined") {
                    setOverlay(false);
                    props.setOverlay(false);
                    setGridData(response.data);
                    setEditEvent(true);
                    setNewEvent(false);
                    setShowGrid(apiParams.multipleUnit.length > 0 ? true : false);
                }
                else {
                    setOverlay(false);
                    props.setOverlay(false);
                    setGridData([]);
                    setEditEvent(true);
                    setNewEvent(false);
                    setShowGrid(true);
                    setApiParams(function (prevParams) {
                        return __assign(__assign({}, prevParams), { maintenanceData: {
                                assetUnitKey: "",
                                eventStartDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
                                eventEndDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
                                assetEventTypeKey: null,
                                dataProviderKey: null,
                                offlineCapacityOriginal: 100,
                                eventCauseTypeKey: null,
                                offlineCapacityOriginalUomKey: 2,
                                offlineDataType: "PCT",
                                eventLockInd: false,
                                url: "",
                                comment: "",
                            }, maintenanceAssetUnitKey: {}, maintenanceAssetEventTypeKey: {}, maintenanceDataProviderKey: {}, maintenanceEventCauseTypeKey: {} });
                    });
                }
            });
        }
    }, [apiParams.multipleUnit, currentYearOrHistory, onSaveRandomKey]);
    /*
     *Detect change in the data dropdowns
     */
    useEffect(function () {
        setNewEventRandomKey(getRandom());
    }, [
        apiParams.maintenanceAssetEventTypeKey,
        apiParams.maintenanceDataProviderKey,
        apiParams.maintenanceEventCauseTypeKey,
    ]);
    /*
     *change in multiple units
     */
    useEffect(function () {
        if (apiParams.multipleUnit.length === 0) {
            setApiParams(function (prevParams) {
                return __assign(__assign({}, prevParams), { maintenanceShowEvents: false });
            });
        }
    }, [apiParams.multipleUnit]);
    /*
     *random key generator function
     */
    function getRandom() {
        return (Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15));
    }
    /*
     *function to change maintenance data
     */
    function maintanenceInputOnChange(e) {
        var updatedMaintenance = __assign(__assign({}, apiParams.maintenanceData), { offlineCapacityOriginal: e.target.value });
        props.setEnableSaveButton(false);
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance });
        });
    }
    /*
     *function to change event lock
     */
    function eventLockedIndChange(e) {
        var updatedMaintenance = __assign(__assign({}, apiParams.maintenanceData), { eventLockInd: e.target.checked });
        props.setEnableSaveButton(false);
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance });
        });
    }
    /*
     *function to change start date
     */
    function newStartDateSetMaintanence(e) {
        if (e.value) {
            var updatedMaintenance_1 = __assign(__assign({}, apiParams.maintenanceData), { eventStartDt: moment(e.value).format("YYYY-MM-DDTHH:mm:ss") });
            props.setEnableSaveButton(false);
            setApiParams(function (prevParams) {
                return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance_1 });
            });
        }
    }
    /*
     *function to change end date
     */
    function endStartDateSetMaintanence(e) {
        if (e.value) {
            var updatedMaintenance_2 = __assign(__assign({}, apiParams.maintenanceData), { eventEndDt: moment(e.value).format("YYYY-MM-DDTHH:mm:ss") });
            props.setEnableSaveButton(false);
            setApiParams(function (prevParams) {
                return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance_2 });
            });
        }
    }
    /*
     *function to change dropdowns of data area
     */
    function changeDropDownOptions(e, dropDown) {
        var _a;
        var updatedMaintenance = __assign(__assign({}, apiParams.maintenanceData), (_a = {}, _a[dropDown] = e.value.id > 0 ? e.value.id : null, _a));
        props.setEnableSaveButton(false);
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance });
        });
    }
    /*
     *function to change maintenance comment
     */
    function maintanenceCommentsOnChange(e) {
        var updatedMaintenance = __assign(__assign({}, apiParams.maintenanceData), { comment: e.target.value });
        props.setEnableSaveButton(false);
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance });
        });
    }
    /*
     *function to change maintenance url
     */
    function maintanenceUrlOnChange(e) {
        var updatedMaintenance = __assign(__assign({}, apiParams.maintenanceData), { url: e.target.value });
        props.setEnableSaveButton(false);
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { maintenanceData: updatedMaintenance });
        });
    }
    /*
     *function on clicking new event in data area
     */
    function newEventClick() {
        setNewEventRandomKey(getRandom());
        setEditEvent(false);
        setNewEvent(true);
        setApiParams(function (prevParams) {
            return __assign(__assign({}, prevParams), { maintenanceData: {
                    assetUnitKey: "",
                    eventStartDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
                    eventEndDt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
                    assetEventTypeKey: null,
                    dataProviderKey: null,
                    offlineCapacityOriginal: 100,
                    eventCauseTypeKey: null,
                    offlineCapacityOriginalUomKey: 2,
                    offlineDataType: "PCT",
                    eventLockInd: false,
                    url: "",
                    comment: "",
                }, maintenanceAssetUnitKey: {}, maintenanceAssetEventTypeKey: {}, maintenanceDataProviderKey: {}, maintenanceEventCauseTypeKey: {} });
        });
        props.setEnableSaveButton(true);
    }
    /*
     *function on clicking edit event in data area
     */
    function editEventClick() {
        setNewEventRandomKey(getRandom());
        setEditEvent(true);
        setNewEvent(false);
        props.setEnableSaveButton(true);
    }
    /*
     *function on get the methods inside this in parent component
     */
    useImperativeHandle(ref, function () { return ({
        maintenanceSave: function () {
            var path = "assetUnitEvents";
            setOverlay(true);
            if (newEvent) {
                var maintenanceData = apiParams.maintenanceData;
                maintenanceData["assetUnitKey"] = apiParams.multipleUnit
                    .filter(function (value) {
                    return value.id;
                })
                    .map(function (value) {
                    if (value.id > 0) {
                        return value.id;
                    }
                });
                var saveReturn = saveSourceData(maintenanceData, path).then(function (response) {
                    if (typeof response === "undefined") {
                        //props.setEnableSaveButton(true);
                        setOverlay(false);
                    }
                    else if (response.status === 200 || response.status === 201) {
                        props.setEnableSaveButton(true);
                        setOnSaveRandomKey(getRandom());
                        setOverlay(false);
                        if (!props.newEdit) {
                            props.onApply();
                        }
                    }
                    else {
                        props.setEnableSaveButton(true);
                        setOverlay(false);
                    }
                });
            }
            else {
                var maintenanceDataCl_1 = apiParams.maintenanceData;
                if (apiParams.maintenanceData.assetUnitEventKey[0] === -1) {
                    delete apiParams.maintenanceData.assetUnitEventKey;
                    apiParams.maintenanceData["assetUnitEventKey"] = __spreadArray([], childEvents, true);
                }
                var editReturn = editSourceData(apiParams.maintenanceData, null, path).then(function (response) {
                    if (typeof response === "undefined") {
                        if (maintenanceDataCl_1.assetUnitEventKey.length > 1) {
                            maintenanceDataCl_1["assetUnitEventKey"] = [-1];
                        }
                        setApiParams(function (prevParams) {
                            return __assign(__assign({}, prevParams), { maintenanceData: maintenanceDataCl_1 });
                        });
                        setOnSaveRandomKey(getRandom());
                        setOverlay(false);
                    }
                    else if (response.status === 200 || response.status === 201) {
                        props.setEnableSaveButton(true);
                        setOnSaveRandomKey(getRandom());
                        setOverlay(false);
                        if (!props.newEdit) {
                            props.onApply();
                        }
                    }
                    else {
                        props.setEnableSaveButton(true);
                        setOverlay(false);
                    }
                });
            }
        },
    }); });
    /*
     *function to change cusrrent history
     */
    function currentYearOrHistoryChange(e) {
        setCurrentYearOrHistory(typeof e.item !== "undefined"
            ? e.item == "Current year"
                ? 0
                : e.item
            : e.target.getAttribute("data-history"));
    }
    /*
     *return of jsx
     */
    return (_jsx(_Fragment, { children: apiParams.multipleUnit &&
            apiParams.maintenanceShowEvents &&
            apiParams.multipleUnit.length > 0 && (_jsxs(_Fragment, { children: [overlay && (_jsx("div", { className: "k-overlay", style: { position: "absolute" } })), _jsx("div", __assign({ className: "event-area bg-solid border-bottom p-4" }, { children: _jsx(MaintenanceDataGrid, { setChildEvents: setChildEvents, showGrid: showGrid, data: gridData, eventKey: props.eventKey, overlay: overlay, editEvent: editEvent, newEvent: newEvent, setEditEvent: editEventClick, setNewEvent: newEventClick, keyChange: newEventRandomKey, currentYearOrHistory: currentYearOrHistory, currentYearOrHistoryChange: currentYearOrHistoryChange }) })), _jsx("div", __assign({ className: "parameters border-bottom p-4" }, { children: _jsxs("div", __assign({ className: "row no-gutters" }, { children: [_jsx("div", __assign({ className: "incremental-capacity pr-0" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "Capacity Offline" })), _jsx("div", __assign({ className: "capacity-counter row justify-content-between no-gutters pl-2 pr-2" }, { children: _jsxs("div", __assign({ className: "input-group" }, { children: [_jsx("input", { type: "number", value: typeof apiParams.maintenanceData
                                                            .offlineCapacityOriginal === "number"
                                                            ? apiParams.maintenanceData
                                                                .offlineCapacityOriginal
                                                            : undefined, onChange: maintanenceInputOnChange, placeholder: "100", className: "bg-transparent border-0 shadow-none incremental-capacity-input text-white" }, "oco-" + newEventRandomKey), _jsx("div", __assign({ className: "input-group-append" }, { children: _jsx("span", __assign({ className: "input-group-text bg-transparent border-0 shadow-none text-white", id: "basic-addon2" }, { children: "PCT" })) }))] })) }))] })) })), _jsxs("div", __assign({ className: "pull-left pl-3" }, { children: [_jsx("input", { id: "metaData", type: "checkbox", checked: apiParams.maintenanceData.eventLockInd, onChange: eventLockedIndChange, className: "k-checkbox" }, "el-" + newEventRandomKey), _jsx("label", __assign({ htmlFor: "metaData", className: "k-checkbox-label m-2" }, { children: "Locked Baseline" }))] }))] })) })), _jsx("div", __assign({ className: "datetime border-bottom p-4" }, { children: _jsxs("div", __assign({ className: "row no-gutters" }, { children: [_jsx("div", __assign({ className: "datepicker col-sm-2 p-0 mr-3" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative pl-0 pr-2" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "Event Start Date" })), _jsx(DatePicker, { format: "dd MMMM yyyy", value: apiParams.maintenanceData.eventStartDt
                                                ? new Date(apiParams.maintenanceData.eventStartDt)
                                                : new Date(), onChange: newStartDateSetMaintanence })] })) })), _jsx("div", __assign({ className: "datepicker col-sm-2 p-0 mr-3" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative pl-0 pr-2" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "Event End Date" })), _jsx(DatePicker, { format: "dd MMMM yyyy", value: apiParams.maintenanceData.eventEndDt
                                                ? new Date(apiParams.maintenanceData.eventEndDt)
                                                : new Date(), onChange: endStartDateSetMaintanence })] })) })), _jsx("div", __assign({ className: "unit-subtype col-sm-3 pr-0 mr-3" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "Event Type" })), _jsx(Filter, { data: apiParams.AssetEventTypes, code: "assetEventTypeDesc", changeDropDownOptions: function (e) {
                                                changeDropDownOptions(e, "assetEventTypeKey");
                                            }, dropDown: "AssetEventTypes", defaultData: apiParams.maintenanceAssetEventTypeKey }, "aet-" + newEventRandomKey)] })) })), _jsx("div", __assign({ className: "unit-subtype col-sm-2 pr-0 mr-3" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "Event Cause" })), _jsx(Filter, { data: apiParams.EventCauseTypes, code: "eventCauseTypeDesc", changeDropDownOptions: function (e) {
                                                changeDropDownOptions(e, "eventCauseTypeKey");
                                            }, dropDown: "EventCauseTypes", defaultData: apiParams.maintenanceEventCauseTypeKey }, "ect-" + newEventRandomKey)] })) })), _jsx("div", __assign({ className: "unit-subtype col-sm-2 pr-0 mr-3" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "Source of Intelligence" })), _jsx(Filter, { data: apiParams["DataProviders/UserSpecificDataProvider"], code: "dataProviderAbbr", changeDropDownOptions: function (e) {
                                                changeDropDownOptions(e, "dataProviderKey");
                                            }, dropDown: "DataProviders/UserSpecificDataProvider", defaultData: apiParams.maintenanceDataProviderKey }, "dpa-" + newEventRandomKey)] })) }))] })) })), _jsx("div", __assign({ className: "others p-4" }, { children: _jsxs("div", __assign({ className: "row no-gutters" }, { children: [_jsxs("div", __assign({ className: "col-sm-6" }, { children: [_jsx("label", __assign({ className: "title position-absolute bg-solid" }, { children: "Comments" })), _jsx("textarea", { className: "border text-white bg-solid rounded p-4 w-100", onChange: maintanenceCommentsOnChange, value: apiParams.maintenanceData.comment || undefined, placeholder: "Enter comments here" }, "com-" + newEventRandomKey)] })), _jsx("div", __assign({ className: "col-sm-6" }, { children: _jsx("div", __assign({ className: "url-input" }, { children: _jsxs("div", __assign({ className: "border bg-solid rounded position-relative" }, { children: [_jsx("div", __assign({ className: "title position-absolute bg-solid" }, { children: "URL" })), _jsx("input", { className: "bg-transparent border-0 p-2 w-100", onChange: maintanenceUrlOnChange, style: { color: "#fff" }, value: apiParams.maintenanceData.url || "", placeholder: "Enter URL here" }, "url-" + newEventRandomKey)] })) })) }))] })) }))] })) }));
});
export default Maintenance;
