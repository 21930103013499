import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
var Toggleiconlayer = /** @class */ (function (_super) {
    __extends(Toggleiconlayer, _super);
    function Toggleiconlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClickexp = function () {
            _this.setState({ expOn: !_this.state.expOn });
        };
        _this.state = {
            expOn: true,
        };
        return _this;
    }
    Toggleiconlayer.prototype.render = function () {
        var _this = this;
        var expOn = this.state.expOn;
        return (_jsx("div", __assign({ className: "col-12 position-absolute", style: { zIndex: 9, right: 0, top: "20px" }, onClick: function () { return _this.handleClickexp(); } }, { children: _jsxs("div", __assign({ className: "z-index mt-1" }, { children: [_jsx(Button, __assign({ className: "ml-2 px-3 float-left z-index border-0 bg-solid-toggle" }, { children: expOn ? (_jsx("i", { className: "fa fa-angle-down fa-bg" })) : (_jsx("i", { className: "fa fa-angle-up fa-bg" })) })), _jsx("div", __assign({ className: "bg-solid px-2 float-left z-index", style: { position: "absolute", left: "50px" } }, { children: _jsx("p", __assign({ style: { fontSize: "13px" } }, { children: _jsx("strong", { children: this.props.title }) })) }))] })) })));
    };
    return Toggleiconlayer;
}(Component));
export default Toggleiconlayer;
