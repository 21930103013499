import axios from "axios";
// import logger from "./logService";
import { toast } from "react-toastify";
import * as errosFunctions from "./errorMessage";
//Intercepting the methods
//http://sww-genevaacmdev.shell.com:8081
axios.interceptors.response.use(function (response) {
    switch (response.status) {
        case 200:
        case 201:
        case 202:
        case 203:
        case 204:
            switch (response.config.method) {
                case "put":
                    if (!toast.isActive("HTTP_RESPUTSU")) {
                        toast.success(errosFunctions.errorMessage[0].put.success, {
                            autoClose: 10000,
                            toastId: "HTTP_RESPUTSU",
                        });
                    }
                    break;
                case "post":
                    if (!toast.isActive("HTTP_RESPOSTSU")) {
                        toast.success(errosFunctions.errorMessage[0].post.success, {
                            autoClose: 10000,
                            toastId: "HTTP_RESPOSTSU",
                        });
                    }
                    break;
                case "get":
                    if (!toast.isActive("HTTP_RESGETSU")) {
                        // toast.success(errosFunctions.errorMessage[0].get.success, {
                        //   autoClose: 10000,
                        //   toastId: "HTTP_RESGETSU"
                        // });
                    }
                    break;
                case "delete":
                    if (!toast.isActive("HTTP_RESDELSU")) {
                        toast.success(errosFunctions.errorMessage[0].delete.success, {
                            autoClose: 10000,
                            toastId: "HTTP_RESDELSU",
                        });
                    }
                    break;
                default:
                    break;
            }
            break;
        case 301:
        case 308:
        case 303:
            if (!toast.isActive("HTTP_RESMVSU")) {
                toast.warn(errosFunctions.errorMessage[0].resourceUriChange, {
                    autoClose: 10000,
                    toastId: "HTTP_RESMVSU",
                });
            }
            break;
        default:
            break;
    }
    return response;
}, 
//Intercepting the error
function (error) {
    if (typeof error.response === "undefined" && !toast.isActive("CORS")) {
        toast.error(errosFunctions.errorMessage[0].corsIssue, {
            autoClose: 3000,
            toastId: "CORS",
        });
    }
    switch (error.response.status) {
        case 500:
        case 501:
        case 502:
        case 503:
        case 505:
            switch (error.response.config.method) {
                case "put":
                    if (!toast.isActive("HTTP_RESPUTER")) {
                        toast.error(errosFunctions.errorMessage[0].put.serverError, {
                            autoClose: 10000,
                            toastId: "HTTP_RESPUTER",
                        });
                    }
                    break;
                case "post":
                    if (!toast.isActive("HTTP_RESPOSTER")) {
                        toast.error(errosFunctions.errorMessage[0].post.serverError, {
                            autoClose: 10000,
                            toastId: "HTTP_RESPOSTER",
                        });
                    }
                    break;
                case "get":
                    if (!toast.isActive("HTTP_RESGETER")) {
                        toast.error(errosFunctions.errorMessage[0].get.serverError, {
                            autoClose: 10000,
                            toastId: "HTTP_RESGETER",
                        });
                    }
                    break;
                case "delete":
                    if (!toast.isActive("HTTP_RESDELER")) {
                        toast.error(errosFunctions.errorMessage[0].delete.serverError, {
                            autoClose: 10000,
                            toastId: "HTTP_RESDELER",
                        });
                    }
                    break;
                default:
                    break;
            }
            break;
        case 400:
            var errorList = errosFunctions.getDetailedError(error.response);
            if (errosFunctions.getDetailedError(error.response).size > 0) {
                for (var _i = 0, _a = errorList.entries(); _i < _a.length; _i++) {
                    var _b = _a[_i], key = _b[0], value = _b[1];
                    var errorMessage = value;
                    if (!toast.isActive(key)) {
                        toast.error(errorMessage, {
                            autoClose: 10000,
                            toastId: key,
                        });
                    }
                }
            }
            else {
                if (!toast.isActive("HTTP_BAD")) {
                    toast.error(errosFunctions.errorMessage[0].badRequest, {
                        autoClose: 10000,
                        toastId: "HTTP_BAD",
                    });
                }
            }
            break;
        case 401:
        case 403:
            if (!toast.isActive("HTTP_RESUAUTHER")) {
                toast.error(errosFunctions.errorMessage[0].unAuthorized, {
                    autoClose: 10000,
                    toastId: "HTTP_RESUAUTHER",
                });
            }
            break;
        case 404:
            if (!toast.isActive("HTTP_RESNOFOER")) {
                toast.error(errosFunctions.errorMessage[0].notFound, {
                    autoClose: 10000,
                    toastId: "HTTP_RESNOFOER",
                });
            }
            break;
        case 408:
            if (!toast.isActive("HTTP_RESTOTER")) {
                toast.warn(errosFunctions.errorMessage[0].timeOut, {
                    autoClose: 10000,
                    toastId: "HTTP_RESTOTER",
                });
            }
            break;
        default:
            break;
    }
    return Promise.reject(error);
});
function setJwt(jwt) {
    axios.defaults.headers.common["x-auth-token"] = jwt;
}
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt: setJwt,
};
