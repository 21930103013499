//import http from "./httpCommon";
import { postSourceData } from "../../../../../services/urlService";
var UploadService = /** @class */ (function () {
    function UploadService() {
    }
    UploadService.prototype.upload = function (file, scenarioKey) {
        var axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
        };
        var formData = new FormData();
        formData.append("inputFile", file);
        return postSourceData(formData, "scenarioUpload", "?ScenarioKey=" + parseInt(scenarioKey), axiosConfig);
        /* return http.post(
          "/ScenarioUpload?ScenarioKey=" + parseInt(scenarioKey),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );*/
    };
    return UploadService;
}());
export default new UploadService();
