import { __assign, __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProgramsMenu from "../../components/programs/programsMenu";
//import GlobalReferenceMenu from "../../components/globalReferenceData/globalReferenceMenu";
import "./sideBarMenu.css";
import logo from "../../assets/images/storage.svg";
//main at the far left hand side
var sideBarMenu = /** @class */ (function (_super) {
    __extends(sideBarMenu, _super);
    function sideBarMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    sideBarMenu.prototype.render = function () {
        return (_jsx("aside", __assign({ className: "sidebar-menu position-fixed h-100" }, { children: _jsx("div", { children: _jsx("li", { children: _jsx(ProgramsMenu, {}) }) }) })));
    };
    return sideBarMenu;
}(Component));
export default sideBarMenu;
