import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
//import "babel-polyfill";
import "unorm";
import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./services/errorBoundary";
import { createBrowserHistory } from "history";
import { AuthGuardProvider } from './app/providers/auth/AuthGuardProvider';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
//import {} from 'styled-components/cssprop';
//import { CSSProp } from 'styled-components';
var customHistory = createBrowserHistory();
var store = configureStore();
window.store = store;
ReactDOM.render(_jsx(ReduxProvider, __assign({ store: store }, { children: _jsx(BrowserRouter, __assign({ history: customHistory }, { children: _jsx(AuthGuardProvider, { children: _jsx(ErrorBoundary, { children: _jsx(App, {}) }) }) })) })), document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
