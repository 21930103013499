import { __extends } from "tslib";
import React, { Component } from "react";
import { toast } from "react-toastify";
var errorBoundary = /** @class */ (function (_super) {
    __extends(errorBoundary, _super);
    function errorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false };
        return _this;
    }
    errorBoundary.getDerivedStateFromError = function (error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    errorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    };
    errorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return toast.error("There is an internal error. Please contact administrator.", {
                autoClose: 2000,
                toastId: "ADMIN_ERR"
            });
            //   return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    };
    return errorBoundary;
}(Component));
export default errorBoundary;
