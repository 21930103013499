import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { ApiContext } from "./apiContext";
var moment = require("moment");
function SupplyDataGrid(props) {
    var _a = useContext(ApiContext), apiParams = _a[0], setApiParams = _a[1];
    var _b = useState([]), pasteVal = _b[0], setPasteVal = _b[1];
    var _c = useState(false), pasted = _c[0], setPasted = _c[1];
    var onPasteChange = function (e, index) {
        var text = e.clipboardData.getData("text/plain");
        text = text.replaceAll("\n", "_").replaceAll("\t", "_").replaceAll(",", "");
        var values = text.split("_");
        if (text.endsWith("_")) {
            values.pop();
        }
        var len = values.length;
        if (len > 1) {
            setPasted(true);
            setPasteVal(values);
        }
    };
    var onQtyChange = function (e, index, origQty) {
        var newParams = __assign({}, apiParams);
        if (pasted) {
            if (typeof newParams.productionAddEditMonths[0] == "undefined") {
                for (var i = 0; i < pasteVal.length; i++) {
                    if (typeof origQty[index] !== "undefined") {
                        var obj = {
                            isSelected: typeof e.target.checked == "undefined"
                                ? true
                                : e.target.checked,
                            month: moment(e.target.name)
                                .add(i, "months")
                                .format("yyyy-MM-DD"),
                            quantity: parseFloat(pasteVal[i]),
                            originalQuantity: parseFloat(origQty[index].quantities[1].quantity),
                            monIndex: index++,
                        };
                        newParams.productionAddEditMonths.push(obj);
                    }
                }
            }
            else {
                for (var j = 0; j < pasteVal.length; j++) {
                    var checkIndex = newParams.productionAddEditMonths.findIndex(function (month) { return month.monIndex === index; });
                    if (typeof origQty[index] !== "undefined") {
                        if (checkIndex < 0) {
                            var obj = {
                                isSelected: typeof e.target.checked == "undefined"
                                    ? true
                                    : e.target.checked,
                                month: moment(e.target.name)
                                    .add(j, "months")
                                    .format("yyyy-MM-DD"),
                                quantity: parseFloat(pasteVal[j]),
                                originalQuantity: parseFloat(origQty[index].quantities[1].quantity),
                                monIndex: index++,
                            };
                            newParams.productionAddEditMonths.push(obj);
                        }
                        else {
                            newParams.productionAddEditMonths[checkIndex].quantity =
                                parseFloat(pasteVal[j]);
                            newParams.productionAddEditMonths[checkIndex].originalQuantity =
                                parseFloat(origQty[index].quantities[1].quantity);
                            /* newParams.productionAddEditMonths[checkIndex].isSelected = !newParams
                              .productionAddEditMonths[checkIndex].isSelected;*/
                            newParams.productionAddEditMonths[checkIndex].month = moment(e.target.name)
                                .add(j, "months")
                                .format("yyyy-MM-DD");
                            index++;
                        }
                    }
                }
            }
        }
        else {
            var value = void 0;
            if (e.value == "") {
                value = "0";
            }
            else {
                value = e.value;
            }
            if (typeof newParams.productionAddEditMonths[0] == "undefined") {
                var obj = {
                    isSelected: typeof e.target.checked == "undefined" ? true : e.target.checked,
                    month: moment(e.target.name).format("yyyy-MM-DD"),
                    monIndex: index,
                    quantity: parseFloat(value.replaceAll(",", "")),
                    originalQuantity: parseFloat(origQty[index].quantities[1].quantity),
                };
                newParams.productionAddEditMonths.push(obj);
            }
            else {
                var checkIndex = newParams.productionAddEditMonths.findIndex(function (month) { return month.monIndex === index; });
                if (checkIndex < 0) {
                    var obj = {
                        isSelected: typeof e.target.checked == "undefined" ? true : e.target.checked,
                        month: moment(e.target.name).format("yyyy-MM-DD"),
                        monIndex: index,
                        quantity: parseFloat(value),
                        originalQuantity: parseFloat(origQty[index].quantities[1].quantity),
                    };
                    newParams.productionAddEditMonths.push(obj);
                }
                else {
                    newParams.productionAddEditMonths[checkIndex].quantity = parseFloat(value.replaceAll(",", ""));
                    newParams.productionAddEditMonths[checkIndex].originalQuantity =
                        parseFloat(e.target.props.placeholder);
                    /* newParams.productionAddEditMonths[checkIndex].isSelected = !newParams
                      .productionAddEditMonths[checkIndex].isSelected;*/
                    newParams.productionAddEditMonths[checkIndex].month = moment(e.target.name).format("yyyy-MM-DD");
                }
            }
        }
        newParams.country = props.selectedData[props.selectedKey].countryKey;
        //newParams.materialProduct = props.selectedProduct;
        newParams.materialFamily = props.selectedFamily;
        newParams.materialGroup = props.selectedGroup;
        newParams.quantityChanged = true;
        setApiParams(newParams);
        setPasted(false);
    };
    var onMonthChange = function (e, index) {
        var newParams = __assign({}, apiParams);
        if (typeof newParams.productionAddEditMonths[index] == "undefined") {
            var obj = {
                isSelected: e.target.checked,
                month: moment(e.target.defaultValue).format("yyyy-MM-DD"),
                monIndex: index,
            };
            newParams.productionAddEditMonths.push(obj);
        }
        else {
            newParams.productionAddEditMonths[index].isSelected =
                !newParams.productionAddEditMonths[index].isSelected;
            newParams.productionAddEditMonths[index].month = moment(e.target.defaultValue).format("yyyy-MM-DD");
        }
        newParams.country = props.selectedData[props.selectedKey].countryKey;
        //newParams.materialProduct = props.selectedProduct;
        newParams.materialFamily = props.selectedFamily;
        newParams.materialGroup = props.selectedGroup;
        setApiParams(newParams);
    };
    return (_jsx("div", __assign({ id: "unit-cross-reference", className: "capacity-event-grid" }, { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", __assign({ colSpan: "12" }, { children: _jsx("td", { children: _jsx("table", { children: _jsx("thead", { children: _jsx("tr", { children: props.updatedMonthData.map(function (monthData, i) {
                                        var index = 12 * props.updatedKey + i;
                                        var monIndex;
                                        if (typeof apiParams.productionAddEditMonths !== "undefined") {
                                            monIndex = apiParams.productionAddEditMonths.findIndex(function (month) { return month.monIndex === index; });
                                        }
                                        /*let isSelected =
                                            apiParams.prodcutionAddEditMonths[index].isSelected;
                                          let updateQty =
                                            apiParams.prodcutionAddEditMonths[index].quantity;*/
                                        return (_jsx("td", __assign({ className: "k-header text-centre" }, { children: _jsx("td", { children: _jsxs("div", __assign({ style: { width: "117px" } }, { children: [_jsxs("div", { children: [_jsx("input", { type: "checkbox", name: "monthSelect", id: index, value: monthData.month, hidden: true }), "\u00A0", monthData.month] }), _jsx(Input
                                                        //type="number"
                                                        , { 
                                                            //type="number"
                                                            name: monthData.month, style: {
                                                                width: "100%",
                                                                textAlign: "center",
                                                                color: 
                                                                // monthData.quantities[1].quantity == 0.0 ||
                                                                monthData.quantities[1].quantity == null
                                                                    ? "gray"
                                                                    : "white",
                                                            }, placeholder: monthData.quantities[1].quantity == null
                                                                ? "-"
                                                                : monthData.quantities[1].quantity
                                                                    ? parseFloat(monthData.quantities[1].quantity).toFixed(0)
                                                                    : monthData.quantities[1].quantity, value: typeof apiParams.productionAddEditMonths[monIndex] === "undefined"
                                                                ? monthData.quantities[1].quantity == null
                                                                    ? "-"
                                                                    : monthData.quantities[1].quantity
                                                                        ? parseFloat(monthData.quantities[1].quantity).toFixed(0)
                                                                        : monthData.quantities[1].quantity
                                                                : apiParams.productionAddEditMonths[monIndex].isSelected
                                                                    ? apiParams.productionAddEditMonths[monIndex].quantity
                                                                    : monthData.quantities[1].quantity == null
                                                                        ? "-"
                                                                        : monthData.quantities[1].quantity
                                                                            ? parseFloat(monthData.quantities[1].quantity).toFixed(0)
                                                                            : monthData.quantities[1].quantity, autoComplete: "off", readOnly: 
                                                            //monthData.quantities[1].quantity == 0.0 ||
                                                            monthData.quantities[1].quantity == null, onPaste: function (e) { return onPasteChange(e, index); }, onChange: function (e) {
                                                                return onQtyChange(e, index, props.selectedData[props.selectedKey]
                                                                    .quantityMonths);
                                                            } }, index)] })) }) })));
                                    }) }) }) }) }) })) }) }) })));
}
export default SupplyDataGrid;
