import { __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
/*import Login from "./commons/userAuth/login";
import Register from "./commons/userAuth/register";
import Reset from "./commons/userAuth/reset";*/
import { ToastContainer } from "react-toastify";
import BaseContainer from "./baseContainer/baseContainer";
import withAppInsights from './AppInsights';
import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        return (_jsxs(React.Fragment, { children: [_jsx(ToastContainer, {}), _jsxs(Switch, { children: [_jsx(Route, { path: "/dashboard", component: BaseContainer }), _jsx(Redirect, { from: "/", exact: true, to: "/dashboard" })] })] }));
    };
    return App;
}(Component));
export default withAppInsights(App);
