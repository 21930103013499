import { __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Calendar } from "@progress/kendo-react-dateinputs";
var CustomCalendar = /** @class */ (function (_super) {
    __extends(CustomCalendar, _super);
    function CustomCalendar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomCalendar.prototype.render = function () {
        return (_jsx(Calendar, { bottomView: "year", topView: "year", value: this.props.value, onChange: this.props.onChange }));
    };
    return CustomCalendar;
}(React.Component));
export { CustomCalendar };
