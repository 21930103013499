import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import "./leftNav.css";
function LeftNavPanel(props) {
    var _a = useState(true), bool = _a[0], setToggle = _a[1];
    var _b = useState(props.data[0].assetId), isActive = _b[0], setActive = _b[1];
    var listItems = props.data.map(function (list, assetId) { return (_jsx("li", __assign({ className: "text-left px-2 my-2 ".concat(isActive === list.assetId ? "active" : ""), onClick: function () {
            setActive(list.assetId);
            props.divRefs[assetId].scrollIntoView();
        } }, { children: _jsxs("span", __assign({ className: "row" }, { children: [_jsx("span", __assign({ className: "col-sm-1" }, { children: _jsx("i", { className: "fa fa-angle-double-right pr-2", "aria-hidden": "true" }) })), _jsx("span", __assign({ className: "col-sm-10 p-0" }, { children: list.assetName }))] })) }), list.assetId)); });
    return (_jsxs("div", __assign({ style: { width: bool ? "18%" : "2%" } }, { children: [_jsx("div", __assign({ style: { height: window.innerHeight - 300 }, id: "listItems", className: "navcontainer pull-left ".concat(bool ? "show" : "hidden") }, { children: _jsx("ul", { children: listItems }) })), _jsx("div", __assign({ className: "pull-left ".concat(bool ? "position-absolute" : ""), style: { right: "".concat(bool ? "-22px" : "0px") } }, { children: bool ? (_jsx("div", __assign({ className: "togleBtn mt-3", onClick: function () {
                        setToggle(false);
                        document.getElementById("capacity-increment-data-area").style.width = "98%";
                        document.getElementById("capacity-increment-data-area").style.marginLeft = "0px";
                    } }, { children: _jsx("i", { className: "fa fa-chevron-left", "aria-hidden": "true" }) }))) : (_jsx("div", __assign({ className: "togleBtn mt-3", onClick: function () {
                        setToggle(true);
                        document.getElementById("capacity-increment-data-area").style.width = "82%";
                        document.getElementById("capacity-increment-data-area").style.marginLeft = "22px";
                    } }, { children: _jsx("i", { className: "fa fa-chevron-right", "aria-hidden": "true" }) }))) }))] })));
}
export default LeftNavPanel;
