import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import TopBarMenu from "../commons/topBarMenu/topBarMenu";
import SideBarMenu from "../commons/sideBarMenu/sideBarMenu";
import Dashboard from "../commons/dashboard/dashboard";
//import GeneralConfigurationNavLink from "../components/programs/geneva/configurationData/generalConfiguration/generalConfigurationNavLink";
//import AssetReferenceNavLink from "../components/programs/geneva/referenceData/assetReference/assetReferenceNavLink";
// import Maintenance from "../components/programs/geneva/globalAssetDatabase/maintenance/maintenance";
import GlobalSupplyDatabase from "../components/programs/geneva/globalSupplyDatabase/globalSupplyDatabase";
import StorageTankReferenceNavLink from "../components/programs/geneva/globalInventoryDatabase/ReferenceData/tank/storageTankReferenceNavLink";
var baseContainer = /** @class */ (function (_super) {
    __extends(baseContainer, _super);
    function baseContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    baseContainer.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs("main", __assign({ className: "main" }, { children: [_jsx(SideBarMenu, {}), _jsxs("div", __assign({ className: "content" }, { children: [_jsx(TopBarMenu, {}), _jsx("div", __assign({ className: "data-container", style: { position: "fixed", marginTop: "45px", width: "97.2%" } }, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard", to: "/dashboard/globalSupplyDatabase/supply/supplyMain", exact: true }), _jsx(Redirect, { from: "/dashboard/globalSupplyDatabase/supply", to: "/dashboard/globalSupplyDatabase/supply/supplyMain", exact: true }), _jsx(Route, { path: "/dashboard/globalSupplyDatabase", component: GlobalSupplyDatabase }), _jsx(Route, { path: "/dashboard/globalInventoryDatabase/ReferenceData/tank/storageTank", component: StorageTankReferenceNavLink })] }) }))] }))] })) }));
    };
    return baseContainer;
}(Component));
export default baseContainer;
