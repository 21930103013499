import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
import Badge from "./tools/badge";
import { Button } from "@progress/kendo-react-buttons";
import { CustomDateInput } from "./customDateInput.jsx";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import DropdownMultiSelect from "./dropdownMultiSelect";
import FilterBtn from "./tools/filterBtn";
import Filter from "./tools/filter";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import QuaterPicker from "../../../../../commons/quaterPicker";
import ErrorBoundary from "../../../../../services/errorBoundary";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import { ToastContainer, toast } from "react-toastify";
import onClickOutside from "react-onclickoutside";
import "react-toastify/dist/ReactToastify.css";
import ToggleBtnHistory from "./tools/toggleBtnHistory";
import ToggleBtnDataType from "./toggleBtnDataType";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Popup } from "@progress/kendo-react-popup";
import _ from "lodash";
import { CustomCalendar } from "./tools/customCalendar";
var moment = require("moment");
var panelVisible = false;
var FilterContainer = /** @class */ (function (_super) {
    __extends(FilterContainer, _super);
    //anchor = null;
    function FilterContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.filterBtnToggle = function () {
            _this.setState({ filterbtnisOn: !_this.state.filterbtnisOn });
            var filterDiv = document.getElementById("filterPanel");
            if (filterDiv !== null) {
                if (filterDiv.style.zIndex === "-1") {
                    filterDiv.style.zIndex = "9999";
                }
                else {
                    filterDiv.style.zIndex = "-1";
                }
            }
        };
        _this.onApplyChange = function () {
            _this.setState({ onApplyChange: !_this.state.onApplyChange });
        };
        //componentDidMount = () => {};
        _this.itemRender = function (li, itemProps) {
            var index = itemProps.index;
            var itemChildren = (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "row justify-content-between no-gutters" }, { children: [_jsxs("div", __assign({ className: "select-text" }, { children: [_jsx("span", __assign({ className: "dim-text" }, { children: "Provider:" })), _jsx("span", __assign({ className: "normal-text ml-1 mr-1" }, { children: "Baseline, +6" }))] })), _jsx("div", __assign({ className: "select-dropdown ml-3" }, { children: _jsx("i", { className: "fa fa-lg fa-caret-down dim-text", "aria-hidden": "true" }) }))] })), _jsx("div", __assign({ className: "select-options" }, { children: _jsxs("div", __assign({ className: "dropdown-menu" }, { children: [li.props.children, " ", index] })) }))] }));
            return React.cloneElement(li, li.props, itemChildren);
        };
        _this.handleSelect = function (e) {
            _this.setState({ selected: e.selected });
        };
        _this.CustomDateInputStartDt = function (props) {
            return (_jsx(CustomDateInput, __assign({}, props, { dimtext: "Start", datetype: moment(new Date(_this.state.startDate)).format("MMM YYYY"), textClass: "dim-text", dateClass: "normal-text ml-2 mr-4" })));
        };
        _this.CustomDateInputEndDt = function (props) {
            return (_jsx(CustomDateInput, __assign({}, props, { dimtext: "End", datetype: moment(new Date(_this.state.endDate)).format("MMM YYYY"), textClass: "dim-text", dateClass: "normal-text ml-2 mr-4" })));
        };
        _this.handleToggle = function () {
            panelVisible = panelVisible ? false : true;
        };
        //todo
        _this.resetHandler = function () {
            if (_this.state.resetDenote) {
                _this.setState({
                    regions: "Region?",
                    tradeOrganizations: "TradeOrganization?",
                    countries: "Countries?",
                    materialFamilies: "MaterialFamily?",
                    materialGroups: "MaterialGroup?",
                    materialGrade: "MaterialGrade?",
                    resetDenote: !_this.state.resetDenote,
                });
            }
            else {
                _this.setState({
                    regions: "Region",
                    tradeOrganizations: "TradeOrganization",
                    countries: "Countries",
                    materialFamilies: "MaterialFamily",
                    materialGroups: "MaterialGroup",
                    materialGrade: "MaterialGrade",
                    resetDenote: !_this.state.resetDenote,
                    resetToDefault: !_this.state.resetToDefault,
                });
            }
            _this.setState({
                refresh: true,
                Regions: 0,
                TradeOrganizations: 0,
                Countries: 0,
                MaterialGroups: 0,
                MaterialFamilies: 1,
                MaterialGrades: 0,
                currentSelectedRegions: [],
                currentSelectedTradeOrganizations: [],
                currentSelectedCountries: [],
                currentSelectedMaterialGroups: [],
                currentSelectedMaterialFamilies: [9],
                currentSelectedMaterialGrade: [],
                startDate: moment()
                    .subtract(1, "year")
                    .startOf("year")
                    .format("MMM YYYY"),
                endDate: moment().endOf("year").format("MMM YYYY"),
                selectedBadgeIndex: -1,
            });
            _this.provider.resetHandler();
            _this.props.resetWMEnable();
            _this.props.deleteFilterStore();
            _this.props.setSelectedMaterialGrade([]);
            if (!_this.state.onApplyChange) {
                _this.props.OnApply();
            }
        };
        //todo
        _this.eventManagement = function (object, path, currentSelected, count, pathChange) {
            if (pathChange === void 0) { pathChange = true; }
            if (Object.keys(object).length > 0) {
                Object.entries(object).map(function (value, key) {
                    switch (value[0]) {
                        case "Region":
                            _this.setState({
                                regions: value[1],
                                resetRegion: true,
                            });
                            break;
                        case "tradeOrganizations":
                            _this.setState({
                                tradeOrganizations: value[1],
                                resetTradeOrganization: true,
                            });
                            break;
                        case "countries":
                            _this.setState({
                                countries: 
                                /*value[1] === "Countries?&"
                                  ? "Countries?isSupply=true"
                                  : */ value[1],
                                resetCountry: true,
                            });
                            break;
                        case "MaterialFamily":
                            _this.setState({
                                materialFamilies: value[1],
                                resetMaterialFamily: true,
                            });
                            break;
                        case "materialGroups":
                            _this.setState({
                                materialGroups: value[1],
                                resetMaterialGroup: true,
                            });
                            break;
                        case "materialGrade":
                            _this.setState({
                                materialGrade: value[1],
                                resetMaterialGrade: true,
                            });
                            break;
                        default:
                            break;
                    }
                    return value;
                });
            }
            switch (path) {
                case "Region":
                    _this.setState({
                        Regions: count,
                        TradeOrganizations: 0,
                        Countries: 0,
                        MaterialGrades: 0,
                        currentSelectedRegions: currentSelected,
                        currentSelectedCountries: [],
                        currentSelectedTradeOrganizations: [],
                        currentSelectedMaterialGrade: [],
                    });
                    break;
                case "TradeOrganization":
                    _this.setState({
                        TradeOrganizations: count,
                        Countries: 0,
                        MaterialGrades: 0,
                        currentSelectedTradeOrganizations: currentSelected,
                        currentSelectedCountries: [],
                        currentSelectedMaterialGrade: [],
                    });
                    break;
                case "Countries":
                    _this.setState({
                        Countries: count,
                        MaterialGrades: 0,
                        currentSelectedCountries: currentSelected,
                        currentSelectedMaterialGrade: [],
                    });
                    break;
                case "MaterialFamily":
                    _this.setState({
                        MaterialFamilies: count,
                        MaterialGroups: 0,
                        MaterialGrades: 0,
                        currentSelectedMaterialFamilies: currentSelected,
                        currentSelectedMaterialGrade: [],
                    });
                    break;
                case "MaterialGroup":
                    _this.setState({
                        MaterialGroups: count,
                        MaterialGrades: 0,
                        currentSelectedMaterialGroups: currentSelected,
                        currentSelectedMaterialGrade: [],
                    });
                    break;
                case "MaterialGrade":
                    _this.setState({
                        MaterialGrades: count,
                        currentSelectedMaterialGrade: currentSelected,
                    });
                    break;
                default:
                    break;
            }
        };
        //componentDidMount = () => {};
        _this.onStartDateSelect = function (type, value) {
            _this.setState({
                startDate: value,
            });
        };
        _this.onEndDateSelect = function (type, value) {
            _this.setState({
                endDate: value,
            });
        };
        _this.onProviderSelect = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var providerSelected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        providerSelected = [];
                        return [4 /*yield*/, value.map(function (value, index) {
                                providerSelected.push(value);
                                return value;
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.setState({
                                providerSelected: providerSelected,
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.applyOnCLick = function () {
            _this.setState({
                selectedBadgeIndex: -1,
            });
            var countriesPath;
            var regionsPath;
            var tradeOrganizationsPath;
            var materialGroupsPath;
            var materialFamiliesPath;
            var materialGradePath;
            var provider = _this.state.providerSelected;
            var startDate = moment(_this.state.startDate, "MMM YYYY");
            var endDate = moment(_this.state.endDate, "MMM YYYY");
            var firstDayOfStart = startDate.startOf("month").format("YYYY-MM-DD");
            var lastDayOfEnd = endDate.endOf("month").format("YYYY-MM-DD");
            var providerSelected = provider.length > 0
                ? "&dp=" +
                    _.map(provider.filter(function (item) { return item.value !== "*"; }), "value").join("&dp=")
                : "";
            countriesPath =
                _this.state.currentSelectedCountries.length > 0
                    ? "countries=" + _this.state.currentSelectedCountries.join("&countries=")
                    : "";
            regionsPath =
                _this.state.currentSelectedRegions.length > 0
                    ? "regions=" + _this.state.currentSelectedRegions.join("&regions=")
                    : "";
            tradeOrganizationsPath =
                _this.state.currentSelectedTradeOrganizations.length > 0
                    ? "tradeorganisation=" +
                        _this.state.currentSelectedTradeOrganizations.join("&tradeorganisation=")
                    : "";
            materialGroupsPath =
                _this.state.currentSelectedMaterialGroups.length > 0
                    ? "materialgroup=" +
                        _this.state.currentSelectedMaterialGroups.join("&materialgroup=")
                    : "";
            materialFamiliesPath =
                _this.state.currentSelectedMaterialFamilies.length > 0
                    ? "materialfamily=" +
                        _this.state.currentSelectedMaterialFamilies.join("&materialfamily=")
                    : "";
            materialGradePath =
                _this.state.currentSelectedMaterialGrade.length > 0
                    ? "materialgrade=" +
                        _this.state.currentSelectedMaterialGrade.join("&materialgrade=")
                    : "";
            var countriesPathNU = typeof countriesPath !== "undefined" ? countriesPath + "&" : "";
            var regionsPathNU = typeof regionsPath !== "undefined" ? regionsPath + "&" : "";
            var tradeOrganizationsPathNU = typeof tradeOrganizationsPath !== "undefined"
                ? tradeOrganizationsPath + "&"
                : "";
            var materialGroupsPathNU = typeof materialGroupsPath !== "undefined" ? materialGroupsPath + "&" : "";
            var materialFamiliesPathNU = typeof materialFamiliesPath !== "undefined"
                ? materialFamiliesPath + "&"
                : "";
            var materialGradePathNU = typeof materialGradePath !== "undefined" ? materialGradePath + "&" : "";
            var pathForProduction = regionsPathNU +
                tradeOrganizationsPathNU +
                countriesPathNU +
                materialGroupsPathNU +
                materialFamiliesPathNU;
            var pathForProductionGrade = countriesPathNU + materialGradePathNU;
            var apiUrlProduction;
            if (_this.state.currentSelectedMaterialGrade.length > 0) {
                apiUrlProduction =
                    "?startdate=" +
                        firstDayOfStart +
                        "&enddate=" +
                        lastDayOfEnd +
                        "&" +
                        // unitPathNU +
                        pathForProductionGrade +
                        // "&dp=1&dp=2&dp=9&dp=12" +
                        providerSelected;
            }
            else {
                apiUrlProduction =
                    "?startdate=" +
                        firstDayOfStart +
                        "&enddate=" +
                        lastDayOfEnd +
                        "&" +
                        // unitPathNU +
                        pathForProduction +
                        // "&dp=1&dp=2&dp=9&dp=12" +
                        providerSelected;
            }
            if (_this.state.applyButton) {
                //  if (!this.props.maintenanceOn) {
                var maintenanceSelectedItems = {
                    Countries: _this.state.currentSelectedCountries,
                    Regions: _this.state.currentSelectedRegions,
                    TradeOrganizations: _this.state.currentSelectedTradeOrganizations,
                    MaterialGroups: _this.state.currentSelectedMaterialGroups,
                    MaterialFamilies: _this.state.currentSelectedMaterialFamilies,
                    MaterialGrade: _this.state.currentSelectedMaterialGrade,
                    startDate: _this.state.startDate,
                    endDate: _this.state.endDate,
                    //dataType: this.state.dataType,
                };
                _this.props.globalFilterStore(maintenanceSelectedItems, "maintenanceEventSummary");
                _this.props.OnApply(apiUrlProduction.replace(/\?&+/g, "?").replace(/&+/g, "&"));
                //  }
            }
            _this.handleClickOutside();
        };
        _this.handleClickOutside = function (e) {
            var filterDiv = document.getElementById("filterPanel");
            if (filterDiv.style.zIndex === "9999") {
                filterDiv.style.zIndex = "-1";
                _this.setState({ filterbtnisOn: !_this.state.filterbtnisOn });
            }
            _this.setState({
                selectedBadgeIndex: -1,
            });
            if (e === undefined)
                return;
            for (var i = 0; i < e.path.length; i++) {
                if (e.path[i].className &&
                    (e.path[i].className.includes("delta-popup") ||
                        e.path[i].className.includes("k-popup"))) {
                    return;
                }
            }
        };
        _this.applyButtonEnable = function (buttonType) {
            _this.setState({
                applyButton: true,
            });
        };
        _this.CustomPopup = function (props) {
            return _jsx(Popup, __assign({}, props, { popupClass: "filter-popup", format: "MMM YYYY" }));
        };
        _this.onBadgeClick = function (e) {
            var filterDiv = document.getElementById("filterPanel");
            filterDiv.style.zIndex = "9999";
            _this.setState({
                selectedBadgeIndex: e.target.id,
            });
            switch (e.target.id) {
                case "0":
                    document.getElementById("region-search-box").focus();
                    break;
                case "1":
                    document.getElementById("tradeOrganization-search-box").focus();
                    break;
                case "2":
                    document.getElementById("country-search-box").focus();
                    break;
                case "3":
                    document.getElementById("materialFamilies-search-box").focus();
                    break;
                case "4":
                    document.getElementById("materialGroups-search-box").focus();
                    break;
                case "5":
                    document.getElementById("materialGrades-search-box").focus();
                    break;
                default:
                    return;
            }
        };
        _this.state = {
            show: false,
            badgeName: [
                "Regions",
                "Trade Organizations",
                "Countries",
                "Material Families",
                "Material Groups",
                "Material Grades",
            ],
            applyButtonEnable: [],
            applyButton: false,
            Regions: 0,
            TradeOrganizations: 0,
            Countries: 0,
            MaterialGroups: 0,
            MaterialFamilies: 1,
            MaterialGrades: 0,
            count: [1, 2, 3, 4, 5, 6, 7],
            selected: 0,
            delta: false,
            regions: "Region",
            tradeOrganizations: "TradeOrganization?",
            countries: "Countries",
            materialFamilies: "MaterialFamily",
            materialGroups: "MaterialGroup",
            materialGrade: "MaterialGrade",
            pathStore: [],
            //dataType: "PCT",
            filterData: [],
            value: [],
            refresh: false,
            startDate: moment()
                //.subtract(1, "year")
                .startOf("year")
                .format("MMM YYYY"),
            endDate: moment().add(1, "year").endOf("year").format("MMM YYYY"),
            onApplyChange: true,
            resetRegion: false,
            resetTradeOrganization: false,
            resetCountry: false,
            resetMaterialGroup: false,
            resetMaterialFamily: false,
            resetMaterialGrade: false,
            resetDenote: true,
            currentSelectedRegions: [],
            currentSelectedTradeOrganizations: [],
            currentSelectedCountries: [],
            currentSelectedMaterialGroups: [],
            currentSelectedMaterialFamilies: [9],
            currentSelectedMaterialGrade: [],
            resetToDefault: false,
            providerSelected: [],
            selectedBadgeIndex: -1,
        };
        return _this;
        //this.toggleDialog = this.toggleDialog.bind(this);
    }
    FilterContainer.prototype.render = function () {
        var _this = this;
        var obj = this.state;
        var _a = this.state, regions = _a.regions, tradeOrganizations = _a.tradeOrganizations, countries = _a.countries, materialFamilies = _a.materialFamilies, materialGroups = _a.materialGroups, materialGrade = _a.materialGrade;
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "container-fluid background-filter-fill pl-0 pr-0" }, { children: _jsxs("div", __assign({ className: "m-0  position-relative" }, { children: [_jsxs("div", __assign({ className: "row no-gutters justify-content-between pt-3 pb-3 pr-3 position-relative" }, { children: [_jsxs("div", __assign({ className: "col-sm-7 p-0 float-left" }, { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsx(FilterBtn, { isOn: this.state.filterbtnisOn, handleToggle: this.filterBtnToggle }) })), _jsx("div", __assign({ className: "region" }, { children: obj.badgeName.map(function (name, index) { return (_jsx(Badge, { list: name + "-" + _this.state.pathStore.length, name: name, count: obj[name.replace(/ /g, "")], onBadgeClick: _this.onBadgeClick, id: index, selectedBadge: _this.state.selectedBadgeIndex }, index)); }) }))] })), _jsx("div", __assign({ style: { cursor: "pointer", backgroundColor: "#202020" }, id: "provider" }, { children: _jsx(DropdownMultiSelect, { ref: function (ref) { return (_this.provider = ref); }, title: "Provider", default: "Selected(0)", path: "DataProvider", name: "dataProviderAbbr", reset: this.state.resetToDefault, applyButtonEnable: this.applyButtonEnable, selectHandler: this.onProviderSelect, onSwitchPreviousSelectedItems: this.props.mainFilterSelectedItems.size > 0 &&
                                            typeof this.props.mainFilterSelectedItems.get("maintenance") !== "undefined"
                                            ? this.props.mainFilterSelectedItems.get("maintenance")
                                                .provider
                                            : null, fillDeltaDropdown: this.fillDeltaDropdown }, !this.props.maintenanceOn ? "1" : "2") })), _jsxs(ButtonGroup, { children: [_jsx(Button, __assign({ className: "pl-3 pr-3" + (!this.props.maintenanceOn ? " mr-2" : "") }, { children: _jsx("div", __assign({ className: "row justify-content-between no-gutters" }, { children: _jsx("div", __assign({ className: "col-sm-auto" }, { children: _jsx("div", __assign({ className: "version-date-select" }, { children: _jsx(DatePicker, { popup: this.CustomPopup, dateInput: this.CustomDateInputStartDt, 
                                                            // show={this.state.versionCalendar}
                                                            calendar: CustomCalendar, onChange: function (e) {
                                                                _this.setState({
                                                                    startDate: moment(e.target.value).format("MMM YYYY"),
                                                                });
                                                            } }) })) })) })) })), _jsx(Button, __assign({ className: "pl-3 pr-3 mr-4" }, { children: _jsx("div", __assign({ className: "row justify-content-between no-gutters" }, { children: _jsx("div", __assign({ className: "col-sm-auto" }, { children: _jsx("div", __assign({ className: "version-date-select" }, { children: _jsx(DatePicker, { popup: this.CustomPopup, calendar: CustomCalendar, dateInput: this.CustomDateInputEndDt, 
                                                            //  show={this.state.sinceLastUpdateCalendar}
                                                            onChange: function (e) {
                                                                _this.setState({
                                                                    endDate: moment(e.target.value).format("MMM YYYY"),
                                                                });
                                                            } }) })) })) })) }))] }), _jsx("div", __assign({ className: "col-sm-0 pr-1" }, { children: _jsxs("div", __assign({ className: "row justify-content-end" }, { children: [_jsx("div", __assign({ className: "mr-2" }, { children: _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear apply", disabled: this.state.applyButton ? false : true, onClick: this.applyOnCLick }, { children: ["Apply ", _jsx("i", { className: "fa fa-paint-brush" })] })) })) })), _jsx("div", __assign({ className: "selected-long-list-option text-right" }, { children: _jsxs("button", __assign({ className: "reset-clear", onClick: this.resetHandler }, { children: ["Reset ", _jsx("i", { className: "fa fa-undo" })] })) }))] })) }))] })), _jsxs("div", __assign({ id: "filterPanel", className: "row capacity-select rounded border px-3 ml-0 mr-0", 
                            // style={{ display: "none" }}
                            style: { zIndex: "-1" } }, { children: [_jsxs("div", __assign({ className: "cities f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Regions" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "regions", code: "hierarchyElementNm", path: regions.replace(/\?&+/g, "?").replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedRegions, isReset: this.state.resetRegion, refresh: this.state.refresh, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, typeOption: "checkbox", setAllFilters: this.props.setAllFilters, setRefresh: function (data) {
                                                    _this.setState({ refresh: data });
                                                }, setSelectedMaterialGrade: this.props.setSelectedMaterialGrade }, regions.replace(/&+/g, "&").replace(/\?&+/g, "")) })] })), _jsxs("div", __assign({ className: "owners f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Trade Organizations" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "tradeOrganizations", code: "tradeOrganizationAbbr", path: tradeOrganizations
                                                    .replace(/\?&+/g, "?")
                                                    .replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedTradeOrganizations, isReset: this.state.resetTradeOrganization, refresh: this.state.refresh, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, typeOption: "checkbox", setAllFilters: this.props.setAllFilters, setRefresh: function (data) {
                                                    _this.setState({ refresh: data });
                                                }, setSelectedMaterialGrade: this.props.setSelectedMaterialGrade }, tradeOrganizations
                                                .replace(/&+/g, "&")
                                                .replace(/\?&+/g, "")) })] })), _jsxs("div", __assign({ className: "country f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Countries" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "country", code: "geopoliticalEntityNm", path: countries.replace(/\?&+/g, "?").replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedCountries, isReset: this.state.resetCountry, refresh: this.state.refresh, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, typeOption: "checkbox", setAllFilters: this.props.setAllFilters, setRefresh: function (data) {
                                                    _this.setState({ refresh: data });
                                                }, setSelectedMaterialGrade: this.props.setSelectedMaterialGrade }, countries.replace(/&+/g, "&").replace(/\?&+/g, "")) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Material Families" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "materialFamily", code: "materialTypeNm", path: materialFamilies
                                                    .replace(/\?&+/g, "?")
                                                    .replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedMaterialFamilies, isReset: this.state.resetMaterialFamily, refresh: this.state.refresh, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, typeOption: "checkbox", setAllFilters: this.props.setAllFilters, setRefresh: function (data) {
                                                    _this.setState({ refresh: data });
                                                }, setSelectedMaterialFamily: this.props.setSelectedMaterialFamily, setSelectedFamilyName: this.props.setSelectedFamilyName, defaultValues: [9] }, materialFamilies
                                                .replace(/&+/g, "&")
                                                .replace(/\?&+/g, "")) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Material Groups" })), _jsx(ErrorBoundary, { children: _jsx(Filter, { id: "materialGroups", code: "materialTypeNm", path: materialGroups
                                                    .replace(/\?&+/g, "?")
                                                    .replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedMaterialGroups, isReset: this.state.resetMaterialGroup, refresh: this.state.refresh, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, typeOption: "checkbox", setAllFilters: this.props.setAllFilters, setSelectedMaterialGroup: this.props.setSelectedMaterialGroup, setSelectedGroupName: this.props.setSelectedGroupName, setRefresh: function (data) {
                                                    _this.setState({ refresh: data });
                                                } }, materialGroups
                                                .replace(/&+/g, "&")
                                                .replace(/\?&+/g, "")) })] })), _jsxs("div", __assign({ className: "f-panel px-2" }, { children: [_jsx("h6", __assign({ className: "title pt-3 px-0" }, { children: "Material Grades" })), Object.keys(this.state.currentSelectedCountries).length > 0 ? (_jsx(ErrorBoundary, { children: _jsx(Filter, { id: "materialTypeNm", code: "materialTypeNm", path: materialGrade
                                                    .replace(/\?&+/g, "?")
                                                    .replace(/&+/g, "&"), eventManagement: this.eventManagement, previousSelection: this.state.currentSelectedMaterialGrade, isReset: this.state.resetMaterialGrade, refresh: this.state.refresh, comparativeReset: this.comparativeReset, deleteFilterStore: this.props.deleteFilterStore, typeOption: "checkbox", setAllFilters: this.props.setAllFilters, setSelectedMaterialGrade: this.props.setSelectedMaterialGrade }, materialGrade
                                                .replace(/&+/g, "&")
                                                .replace(/\?&+/g, "")) })) : (_jsx("p", __assign({ className: "title py-5" }, { children: "Please select a country first for grade level edits" })))] }))] }))] })) })) }));
    };
    return FilterContainer;
}(Component));
export { FilterContainer };
export default onClickOutside(FilterContainer);
