import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import Toggleicon from "./tools/toggleicon";
import Toggleiconlayer from "./tools/toggleiconlayer";
// import _ from "lodash";
import { If } from "rc-if-else";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ProductionGradeView from "./productionGradeView";
import RightNavPanel from "../../../../../commons/tools/rightNavPanel";
var supplyProductionGradeAccordion = /** @class */ (function (_super) {
    __extends(supplyProductionGradeAccordion, _super);
    function supplyProductionGradeAccordion(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentRandKey: "",
        };
        return _this;
    }
    supplyProductionGradeAccordion.prototype.getScalingInfo = function () {
        var scalingInfo = [];
        var scalingInfoDetails = "";
        if (typeof this.props.data.scalingDataProviderInfos != "undefined") {
            scalingInfo = this.props.data.scalingDataProviderInfos;
        }
        var length = scalingInfo.length;
        if (length == 0) {
            scalingInfoDetails =
                "Preferred Data Provider is Rystad. No Automatic Scaling Defined.";
        }
        else {
            for (var i = 0; i < length; i++) {
                var scalingDetail = "";
                var condensateInd = scalingInfo[i].condensateIncludedInd;
                var crudeInd = scalingInfo[i].crudeIncludedInd;
                var nglInd = scalingInfo[i].nglIncludedInd;
                var scalingType = scalingInfo[i].scalingType;
                var dataProvider = scalingInfo[i].dataProviderNm;
                var period = scalingInfo[i].endMonthNum;
                var scalingPriod = "";
                var scalingBaseOn = "";
                if (scalingType === "Actualization") {
                    scalingPriod = scalingType + " (<= M" + period + ")";
                }
                else if (scalingType === "Nowcasting") {
                    scalingPriod = scalingType + " (M" + period + ")";
                }
                else {
                    scalingPriod = scalingType + " (> M" + period + ")";
                }
                if (crudeInd == "Y") {
                    scalingBaseOn = scalingBaseOn + "Crude,";
                }
                if (condensateInd === "Y") {
                    scalingBaseOn = scalingBaseOn + "Condensate,";
                }
                if (nglInd == "Y") {
                    scalingBaseOn = scalingBaseOn + "NGL,";
                }
                scalingDetail =
                    "Scaling Period: " +
                        scalingPriod +
                        "</br>" +
                        "Data Provider: " +
                        dataProvider +
                        "</br>" +
                        "Scaling Base On: " +
                        scalingBaseOn.substring(0, scalingBaseOn.length - 1) +
                        "</br>";
                scalingInfoDetails = scalingInfoDetails + scalingDetail;
            }
        }
        return scalingInfoDetails;
    };
    supplyProductionGradeAccordion.prototype.render = function () {
        var _this = this;
        return (_jsx(Accordion, __assign({ defaultActiveKey: this.props.accordionKey == 0 ? "0" : this.props.accordionKey }, { children: _jsxs(Card, { children: [_jsx(Accordion.Toggle, __assign({ as: Card.Header, eventKey: this.props.accordionKey == 0 ? "0" : this.props.accordionKey }, { children: _jsx(Toggleicon, { title: this.props.title, scalingInfo: this.getScalingInfo() }) })), _jsx(Accordion.Collapse, __assign({ eventKey: this.props.accordionKey == 0 ? "0" : this.props.accordionKey }, { children: _jsx(Card.Body, { children: _jsx("div", { children: typeof this.props.data.productionGradeDetails !==
                                    "undefined" &&
                                    this.props.data.productionGradeDetails.length > 0
                                    ? this.props.data.productionGradeDetails.map(function (parent, i) {
                                        return (_jsx(Accordion, __assign({ defaultActiveKey: i == 0 ? "0" : i }, { children: _jsxs(Card, { children: [_jsx(Accordion.Toggle, __assign({ as: Card.Header, eventKey: i == 0 ? "0" : i }, { children: _jsx(Toggleiconlayer, { title: parent.grade }) })), _jsx(Accordion.Collapse, __assign({ eventKey: i == 0 ? "0" : i }, { children: _jsxs(Card.Body, { children: [_jsx("div", __assign({ className: "pull-right position-relative" }, { children: _jsx(RightNavPanel, { data: parent, index: parent.gradeKey }) })), _jsx(ProductionGradeView, { data: parent, grade: parent.gradeKey, countryId: _this.props.countryId, gradeIndex: i, countryIndex: _this.props.countryKey, onDataSelect: _this.props.onDataSelect, shouldRefresh: _this.props.shouldRefresh, selectedCountryKey: _this.props.selectedCountryKey, selectedGradeKey: _this.props.selectedGradeKey, applyUrl: _this.props.applyUrl, selectedMaterialGroup: _this.props.selectedMaterialGroup, selectedMaterialFamily: _this.props.selectedMaterialFamily, setShouldRefresh: _this.props.setShouldRefresh, setSelectedData: _this.props.setSelectedData, setGraphData: _this.props.setGraphData })] }) }))] }) })));
                                    })
                                    : null }, this.props.identifier) }) }))] }) })));
    };
    return supplyProductionGradeAccordion;
}(Component));
export default supplyProductionGradeAccordion;
