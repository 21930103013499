import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ProductionDataGrid from "./productionDataGrid";
import ProductionGradeDataGrid from "./productionGradeDataGrid";
import MqyView from "./mqyView";
import "./supplyMain.css";
import _ from "lodash";
import PopUpAdd from "./popUpAdd";
import Header from "./tools/header";
import Topnav from "./tools/topnav";
import FilterContainer from "./filterContainer";
import { If } from "rc-if-else";
import { getSourceFilter } from "../../../../../services/urlService.jsx";
import Spinner from "../../../../../commons/spinner";
import { connect } from "react-redux";
import * as mainFilterActions from "../../../../../redux/actions/mainFilterActions";
import { bindActionCreators } from "redux";
import Events from "../addEditEvent/AddEditEvent";
import GradeEvents from "../addEditEvent/AddEditGradeEvent";
import ChartList from "../../../../charts/chartList";
import logo from "../../../../../assets/images/watermarkLogo.png";
var GenevaLogo = (_jsx("img", { className: "mt-3", alt: "geneva-water-mark", src: logo }));
var supplymain = /** @class */ (function (_super) {
    __extends(supplymain, _super);
    function supplymain() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            value: new Date(),
            supplyOn: false,
            esviewisOn: false,
            eventsWindow: false,
            isApply: true,
            filterShow: true,
            loader: false,
            year: 0,
            supplyData: [],
            newAddEditBt: false,
            title: "Supply Production",
            maintenanceEventData: [],
            url: null,
            selectedKey: 0,
            selectedGradeKey: 0,
            selectedCountryKey: 0,
            limitForHighlight: null,
            baseProvider: null,
            allFilters: {},
            selectedData: {},
            selectedMaterial: {},
            pageLimit: 50,
            shouldRefresh: false,
            selectedMaterialGroup: [],
            selectedMaterialGrade: [],
            selectedMaterialFamily: [],
            selectedMaterialGroupData: [],
            showChartWindow: true,
            graphData: [],
            selectedGroup: [],
            selectedProduct: [],
            selectedFamily: [],
            selectedFamilyName: [],
            selectedGroupName: [],
            //selectedMaterialProductName: [],
            selectedMaterialFamilyName: [],
            selectedMaterialGroupName: [],
        };
        _this.resetEnableWatermark = function () {
            _this.setState({
                isApply: true,
            });
        };
        _this.applyRemove = function () {
            _this.setState({
                isApply: false,
                loader: false,
            });
        };
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.setAllFilters = function (data) {
            _this.setState({
                allFilters: __assign(__assign({}, _this.state.allFilters), data),
            });
        };
        _this.getChartTitle = function () {
            if (JSON.stringify(_this.props.mainFilterSelectedItems) === "{}")
                return "";
            var filters = _this.props.mainFilterSelectedItems.get("maintenance");
            var countryFilter = filters.Countries;
            var regionFilter = filters.Regions;
            var tradeOrgFilter = filters.TradeOrganizations;
            var countryFilterName = [];
            var regionFilterName = [];
            var tradeOrgFilterName = [];
            var title = "";
            var allFilters = _this.state.allFilters;
            var allCountryFilters = allFilters.Countries;
            var allRegionFilters = allFilters.Regions;
            var allTradeOrgFilters = allFilters.TradeOrganizations;
            if (countryFilter.length > 0) {
                var _loop_1 = function (i) {
                    countryFilterName.push(allCountryFilters.find(function (element) { return element.id == countryFilter[i]; })
                        .geopoliticalEntityNm);
                };
                for (var i = 0; i < countryFilter.length; i++) {
                    _loop_1(i);
                }
            }
            if (tradeOrgFilter.length > 0) {
                var _loop_2 = function (i) {
                    tradeOrgFilterName.push(allTradeOrgFilters.find(function (element) { return element.id == tradeOrgFilter[i]; })
                        .tradeOrganizationAbbr);
                };
                for (var i = 0; i < tradeOrgFilter.length; i++) {
                    _loop_2(i);
                }
            }
            if (regionFilter.length > 0) {
                var _loop_3 = function (i) {
                    regionFilterName.push(allRegionFilters.find(function (element) { return element.id == regionFilter[i]; })
                        .hierarchyElementNm);
                };
                for (var i = 0; i < regionFilter.length; i++) {
                    _loop_3(i);
                }
            }
            if (regionFilterName.length > 0) {
                title = title + "Region: " + regionFilterName.toString() + ";";
            }
            if (tradeOrgFilterName.length > 0) {
                title =
                    title + " Trade Organization: " + tradeOrgFilterName.toString() + ";";
            }
            if (countryFilterName.length > 0) {
                title = title + " Country: " + countryFilterName.toString();
            }
            return title;
        };
        _this.handleEdit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (typeof e !== "undefined") {
                    if (typeof e.tagName !== "undefined" &&
                        (e.tagName == "I" || e.tagName == "BUTTON")) {
                        this.setState({
                            shouldRefresh: false,
                            eventsWindow: !this.state.eventsWindow,
                            //selectedData: this.state.supplyData,
                            selectedKey: parseInt(e.attributes[0].value), //e.getAtrributeNode("data-country-selected-id").value,
                        });
                    }
                }
                else {
                    this.setState({
                        //shouldRefresh: true,
                        eventsWindow: !this.state.eventsWindow,
                        supplyOn: true,
                        isApply: true,
                        //shouldRefresh: false,
                    });
                }
                return [2 /*return*/];
            });
        }); };
        _this.handleGradeEdit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (typeof e !== "undefined") {
                    if (typeof e.tagName !== "undefined" &&
                        (e.tagName == "I" || e.tagName == "BUTTON")) {
                        this.setState({
                            shouldRefresh: false,
                            eventsWindow: !this.state.eventsWindow,
                            //selectedData: this.state.supplyData,
                            selectedCountryKey: parseInt(e.attributes[0].value),
                            selectedGradeKey: parseInt(e.attributes[1].value),
                        });
                    }
                }
                else {
                    this.setState({
                        //shouldRefresh: true,
                        eventsWindow: !this.state.eventsWindow,
                        supplyOn: true,
                        isApply: true,
                        //shouldRefresh: false,
                    });
                }
                return [2 /*return*/];
            });
        }); };
        /**common includes
         * this function is called when clicked on 'Apply'
         */
        _this.OnApply = function (url, limitForHighlight) {
            if (limitForHighlight === void 0) { limitForHighlight = null; }
            _this.setState({
                loader: true,
                url: url,
                // deltaView: isDelta,
                // limitForHighlight: limitForHighlight,
                supplyData: [],
                baseProvider: null,
                supplyOn: true,
                shouldRefresh: false,
                selectedMaterialGroupData: _this.state.selectedMaterialGroup,
                showChartWindow: false,
            });
            if (url) {
                var api = void 0;
                if (_this.state.selectedMaterialGrade.length > 0) {
                    api = "ProductionGrade";
                }
                else {
                    api = "Production";
                }
                getSourceFilter(url, api)
                    .then(function (returnData) {
                    if (typeof returnData !== "undefined" &&
                        returnData.data.tableData.length > 0) {
                        if (_this.state.supplyOn) {
                            _this.setState({
                                supplyData: returnData.data.tableData,
                                graphData: returnData.data.graphData,
                                loader: false,
                                // isApply: false,
                                randKey: _this.getRandom(),
                                // selectedMaterialProductName: this.state.selectedProductName,
                                selectedMaterialFamilyName: _this.state.selectedFamilyName,
                                selectedMaterialGroupName: _this.state.selectedGroupName,
                            });
                        }
                    }
                    else {
                        _this.setState({
                            loader: false,
                        });
                    }
                })
                    .catch(function (error) {
                    _this.setState({
                        loader: false,
                        isApply: true,
                    });
                });
            }
        };
        _this.customRender = function (data) {
            var value = data.dataItem[data.field];
            return (_jsx("td", { children: _jsxs("div", __assign({ className: "td-data" }, { children: [value === null ? "" : data.dataItem[data.field].toString(), " KB/D"] })) }));
        };
        _this.chartEvent = function () {
            _this.setState({
                showChartWindow: !_this.state.showChartWindow,
            });
        };
        /**common includes */
        _this.renderGenevaLogo = function () {
            return (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent" }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsxs("div", __assign({ style: { display: _this.state.isApply ? "block" : "none" }, className: "text-center" }, { children: [_jsx("img", { alt: "geneva-watermark-logo", src: logo }), _jsx("p", __assign({ className: "mt-2", style: { color: "#595959" } }, { children: "Click on Apply to view Production" }))] })) })) })));
        };
        return _this;
    }
    supplymain.prototype.render = function () {
        var _this = this;
        var eventsWindow = this.state.eventsWindow;
        return (_jsxs(_Fragment, { children: [this.state.loader && _jsx(Spinner, {}), _jsx(Header, { title: this.state.title }), _jsxs("div", __assign({ className: "global-asset-db capacity dark bg-solid mx-3 mt-1 h-100", id: "capacityscreen" }, { children: [_jsxs("div", __assign({ className: "filter", style: { display: this.state.filterShow ? "block" : "none" } }, { children: [_jsx(FilterContainer, { ref: function (ref) { return (_this.applyTrigger = ref); }, OnApply: this.OnApply, switchPath: this.props.match.path, maintenanceOn: this.state.maintenanceOn, resetWMEnable: this.resetEnableWatermark, currentYearData: this.currentYearData, globalFilterStore: this.props.actions.addSelectedElementToGlobalArray, deleteFilterStore: this.props.actions.removeSelectedElementToGlobalArray, mainFilterSelectedItems: this.props.mainFilterSelectedItems, setAllFilters: this.setAllFilters, setSelectedMaterialGroup: function (data) {
                                        _this.setState({ selectedMaterialGroup: data });
                                    }, setSelectedMaterialGrade: function (data) {
                                        _this.setState({ selectedMaterialGrade: data });
                                    }, 
                                    // setSelectedMaterialProduct={(data) => {
                                    //.  this.setState({ selectedProduct: data });
                                    //}}
                                    setSelectedMaterialFamily: function (data) {
                                        _this.setState({ selectedMaterialFamily: data });
                                    }, setSelectedGroupName: function (data) {
                                        _this.setState({ selectedGroupName: data });
                                    }, setSelectedFamilyName: function (data) {
                                        _this.setState({ selectedFamilyName: data });
                                    } }), _jsx("div", { className: "border-top mt-0 ml-0 mr-0 mb-3 p-0 shadow" })] })), _jsx(If, __assign({ condition: this.state.graphData.length <= 0 &&
                                this.state.supplyData.length <= 0 }, { children: _jsx("div", { children: this.renderGenevaLogo() }) })), _jsxs(If, __assign({ condition: this.state.graphData.length > 0 &&
                                this.state.selectedMaterialGrade.length == 0 &&
                                this.state.supplyData.length > 0 &&
                                typeof this.state.supplyData[0].quantityMonths !== "undefined" &&
                                this.state.supplyData[0].quantityMonths.length > 0 }, { children: [!this.state.showChartWindow && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { textAlign: "center" } }, { children: this.getChartTitle() })), _jsx(ChartList, { chartEvent: this.chartEvent, graphData: this.state.graphData, setGraphData: function (data) {
                                                _this.setState({ graphData: data });
                                            } }), _jsx("div", { style: { height: "30px" } })] })), _jsx("div", __assign({ className: "border-0", style: {
                                        overflowY: "auto",
                                        //overflowX: "auto",
                                        height: "45%",
                                    } }, { children: _jsx("div", { children: _jsx("div", __assign({ className: "container-fluid m-0 p-0", style: {
                                                display: this.state.isApply ? "block" : "none",
                                            } }, { children: _jsx(ProductionDataGrid, { data: this.state.supplyData, setSupplyData: function (data) {
                                                    _this.setState({ supplyData: data });
                                                }, selectedKey: this.state.selectedKey, onDataSelect: this.handleEdit, shouldRefresh: this.state.shouldRefresh, applyUrl: this.state.url, selectedMaterialGroup: this.state.selectedMaterialGroupData, 
                                                //selectedMaterialProduct={this.state.selectedProduct}
                                                selectedMaterialFamily: this.state.selectedMaterialFamily, selectedGroupName: this.state.selectedMaterialGroupName, 
                                                // selectedProductName={this.state.selectedMaterialProductName}
                                                selectedFamilyName: this.state.selectedFamilyName, setSelectedData: function (data) {
                                                    _this.setState({ supplyData: data });
                                                }, setGraphData: function (data) {
                                                    _this.setState({ graphData: data });
                                                }, setShouldRefresh: function (bool) {
                                                    _this.setState({
                                                        shouldRefresh: bool,
                                                    });
                                                } }, this.state.randKey) })) }) }))] })), _jsxs(If, __assign({ condition: this.state.graphData.length > 0 &&
                                this.state.selectedMaterialGrade.length > 0 &&
                                this.state.supplyData.length > 0 &&
                                typeof this.state.supplyData[0].productionGradeDetails !==
                                    "undefined" &&
                                this.state.supplyData[0].productionGradeDetails.length > 0 }, { children: [!this.state.showChartWindow && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { textAlign: "center" } }, { children: this.getChartTitle() })), _jsx(ChartList, { chartEvent: this.chartEvent, graphData: this.state.graphData, setGraphData: function (data) {
                                                _this.setState({ graphData: data });
                                            } }), _jsx("div", { style: { height: "30px" } })] })), _jsx("div", __assign({ className: "border-0", style: {
                                        overflowY: "auto",
                                        overflowX: "auto",
                                        height: "45%",
                                    } }, { children: _jsx("div", __assign({ className: "container-fluid m-0 p-0", style: {
                                            display: this.state.isApply ? "block" : "none",
                                        } }, { children: _jsx(ProductionGradeDataGrid, { data: this.state.supplyData, setSupplyData: function (data) {
                                                _this.setState({ supplyData: data });
                                            }, selectedCountryKey: this.state.selectedCountryKey, selectedGradeKey: this.state.selectedGradeKey, onDataSelect: this.handleGradeEdit, shouldRefresh: this.state.shouldRefresh, applyUrl: this.state.url, selectedMaterialGroup: this.state.selectedMaterialGroupData, 
                                            // selectedMaterialProduct={this.state.selectedProduct}
                                            selectedMaterialFamily: this.state.selectedMaterialFamily, setSelectedData: function (data) {
                                                _this.setState({ supplyData: data });
                                            }, setGraphData: function (data) {
                                                _this.setState({ graphData: data });
                                            }, setShouldRefresh: function (bool) {
                                                _this.setState({
                                                    shouldRefresh: bool,
                                                });
                                            } }, this.state.randKey) })) }))] }))] })), _jsx(If, __assign({ condition: eventsWindow && this.state.selectedMaterialGrade.length == 0 }, { children: _jsx(Events, { onClose: this.handleEdit, selectedData: this.state.supplyData, setSelectedData: function (data) {
                            _this.setState({ supplyData: data });
                        }, setGraphData: function (data) {
                            _this.setState({ graphData: data });
                        }, selectedKey: this.state.selectedKey, selectedGroup: this.state.selectedMaterialGroupData, selectedFamily: this.state.selectedFamily, 
                        // selectedMaterial={this.props.mainFilterSelectedItems}
                        applyUrl: this.state.url, supplyProdData: eventsWindow, setShouldRefresh: function (bool) {
                            _this.setState({
                                shouldRefresh: bool,
                            });
                        }, OnApply: this.OnApply }) })), _jsx(If, __assign({ condition: eventsWindow && this.state.selectedMaterialGrade.length > 0 }, { children: _jsx(GradeEvents, { onClose: this.handleGradeEdit, selectedData: this.state.supplyData, setSelectedData: function (data) {
                            _this.setState({ supplyData: data });
                        }, setGraphData: function (data) {
                            _this.setState({ graphData: data });
                        }, selectedCountryKey: this.state.selectedCountryKey, selectedGradeKey: this.state.selectedGradeKey, 
                        //selectedMaterial={this.props.mainFilterSelectedItems}
                        selectedGroup: this.state.selectedMaterialGroupData, selectedFamily: this.state.selectedFamily, applyUrl: this.state.url, supplyProdData: eventsWindow, setShouldRefresh: function (bool) {
                            _this.setState({
                                shouldRefresh: bool,
                            });
                        }, OnApply: this.OnApply }) })), this.state.eventsWindow && _jsx("div", { className: "k-overlay" })] }));
    };
    return supplymain;
}(Component));
export { supplymain };
function mapStateToProps(state) {
    return {
        mainFilterSelectedItems: state.mainFilterActionReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainFilterActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(supplymain);
