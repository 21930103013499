import crypto from 'crypto';
import base64url from 'base64url';
export var generateCodeVerifier = function () { return crypto.randomBytes(32).toString('hex'); };
export var generateChallenge = function (codeVerifier) {
    return base64url.encode(crypto.createHash('sha256').update(codeVerifier).digest());
};
export var createAuthorizeUrl = function (codeVerifier) {
    var authorizeServerUrl = process.env.REACT_APP_SSO_URL;
    var clientId = process.env.REACT_APP_SSO_CLIENT_ID;
    var redirectUri = process.env.REACT_APP_SSO_REDIRECT_URI;
    var codeChallenge = generateChallenge(codeVerifier);
    if (authorizeServerUrl && clientId && redirectUri) {
        var url = new URL(authorizeServerUrl);
        var params = new URLSearchParams(url.search);
        params.set('client_id', clientId);
        params.set('response_type', 'code');
        params.set('code_challenge_method', 'S256');
        params.set('redirect_uri', redirectUri);
        params.set('code_challenge', codeChallenge);
        params.set('scope', 'email');
        return "".concat(url.href, "?").concat(params.toString());
    }
    return null;
};
