import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { PureComponent } from "react";
import Chip from '@material-ui/core/Chip';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, } from "recharts";
var _ = require("lodash");
var ChartList = function (props) {
    var color = function (name) {
        var color = "#93674F";
        if (name.includes("IEA"))
            color = "#228C22";
        else if (name.includes("EA"))
            color = "#007bff";
        else if (name.includes("EIA"))
            color = "#E66C37";
        else if (name.includes("PIRA"))
            color = "#744EC2";
        else if (name.includes("RYSTAD"))
            color = "#D9B300";
        else if (name.includes("PETROLOGISTICS"))
            color = "#E044A7";
        else if (name.includes("CRUDEDESK"))
            color = "#FFFFFF";
        else if (name.includes("SHELL-BASELINE"))
            color = "#DD1D21";
        else if (name.includes("BEST OF VENDOR"))
            color = "#90DCAA";
        else if (name.includes("ANP"))
            color = "#909CDC";
        else if (name.includes("ESAI"))
            color = "#5499C7";
        return color;
    };
    /* useEffect(() => {
       if (props.path) {
         setLoader(true);
         let chartPath = "CapacitiesOverTime" + props.path;
         getSourceFilter(chartPath).then((returnData) => {
           //api data
           setData(returnData.data);
           setLoader(false);
         });
       }
     }, []);*/
    var switchChart = function (e) {
        for (var i = 0; i < 9; i++) {
            document.getElementById("chart" + (i + 1)).classList.remove("show");
            document.getElementById("chart" + (i + 1)).classList.add("hide");
            if (e == i + 1) {
                document.getElementById("chart" + e).classList.remove("hide");
                document.getElementById("chart" + e).classList.add("show");
            }
        }
    };
    var buildGraphData = function () {
        var data = {
            data: [],
            labels: [],
        };
        if (typeof props.graphData[0] !== "undefined") {
            var qtyData = props.graphData;
            var len = props.graphData[0].dataProviderData.length;
            for (var i = 0; i < len; i++) {
                data.labels.push(qtyData[0].dataProviderData[i].month);
            }
            var qtyLen = qtyData.length;
            for (var j = 0; j < qtyLen; j++) {
                var dpName = qtyData[j].dataProvider;
                var supplyDataList = [];
                if (qtyData[j].dataProviderData.length > 0) {
                    for (var k = 0; k < len; k++) {
                        var qty = parseFloat(qtyData[j].dataProviderData[k].quantity).toFixed(0);
                        if (qty != 0) {
                            var groupObj = {
                                month: qtyData[j].dataProviderData[k].month,
                                quantity: qty,
                            };
                            supplyDataList.push(groupObj);
                        }
                    }
                }
                var dataObj = {
                    dataProviderName: dpName,
                    supplyDataGroup: supplyDataList,
                };
                data.data.push(dataObj);
            }
        }
        return data;
    };
    var getMinMaxArray = function (data) {
        var tempData = Array.from(data);
        var minArray = [];
        var maxArray = [];
        var minMax = [];
        for (var i = 0; i < tempData.length; i++) {
            //delete tempData[i].name;
            var tempArray = Object.values(tempData[i]);
            tempArray.shift();
            minArray.push(Math.min.apply(Math, tempArray));
            maxArray.push(Math.max.apply(Math, tempArray));
        }
        var minNum = Math.min.apply(Math, minArray);
        var maxNum = Math.max.apply(Math, maxArray);
        var minCalNum = Math.floor(parseInt(minNum - minNum * 0.1) / 10) * 10;
        var maxCalNum = Math.ceil(parseInt(maxNum + maxNum * 0.1) / 10) * 10;
        minMax.push(minCalNum);
        minMax.push(maxCalNum);
        return minMax;
    };
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload, label = _a.label;
        //let index = this.state.index;
        if (active && payload.length > 0) {
            return (_jsxs("div", __assign({ className: "custom-tooltip", style: { backgroundColor: "rgba(80,80,80,0.9)" } }, { children: [_jsx("p", __assign({ className: "label", style: { textAlign: "left", paddingLeft: "10px", paddingTop: "5px", fontWeight: "bold", fontSize: "15px", textDecorationLine: "underline" } }, { children: "".concat(payload[0].payload.name) })), _jsx("table", { children: _jsx("tbody", { children: payload.map(function (info, index) {
                                var payloadArray = Object.getOwnPropertyNames(info.payload);
                                var baselineIndex = payloadArray.findIndex(function (i) { return i.includes('SHELL-BASELINE'); });
                                var baselineNm = payloadArray[baselineIndex];
                                var delta = parseInt(info.value) - parseInt(info.payload[baselineNm]);
                                var color = delta > 0 ? "green" : delta == 0 ? "grey" : "red";
                                return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("label", __assign({ style: { color: "".concat(info.stroke), fontSize: "12px", paddingLeft: "10px", } }, { children: " \u2022" })) }), _jsxs("td", __assign({ style: { textAlign: "right" } }, { children: [info.name, ": \u00A0"] })), _jsxs("td", { children: [info.value, " \u00A0"] }), _jsx("td", { children: _jsx(Chip, { color: "default", style: { backgroundColor: "".concat(color) }, size: "small", variant: "outlined", label: delta }) })] }));
                            }) }) }), _jsx("p", __assign({ className: "label" }, { children: " " }))] })));
        }
        return null;
    };
    var CustomizedLabel = /** @class */ (function (_super) {
        __extends(CustomizedLabel, _super);
        function CustomizedLabel() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        CustomizedLabel.prototype.render = function () {
            var _a = this.props, x = _a.x, y = _a.y, stroke = _a.stroke, value = _a.value;
            return (_jsx("text", __assign({ x: x, y: y, dy: -4, fill: stroke, fontSize: 10, textAnchor: "middle" }, { children: value })));
        };
        return CustomizedLabel;
    }(PureComponent));
    var CustomizedAxisTick = /** @class */ (function (_super) {
        __extends(CustomizedAxisTick, _super);
        function CustomizedAxisTick() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        CustomizedAxisTick.prototype.render = function () {
            var _a = this.props, x = _a.x, y = _a.y, stroke = _a.stroke, payload = _a.payload;
            return (_jsx("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: _jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "end", fill: "#666", transform: "rotate(-35)" }, { children: payload.value })) })));
        };
        return CustomizedAxisTick;
    }(PureComponent));
    var data = buildGraphData();
    var chartData = [];
    _.forEach(data.labels, function (value) {
        var chartObj = {};
        chartObj["name"] = value;
        _.forEach(data.data, function (provider) {
            _.forEach(provider.supplyDataGroup, function (qty) {
                if (qty.month === value) {
                    chartObj[provider.dataProviderName] = qty.quantity;
                }
            });
        });
        chartData.push(chartObj);
    });
    var domainData = getMinMaxArray(chartData);
    return (
    /*<div style={{ width: "100%", height: 500, position: "absolute" }}>
     {/* {loader && <Spinner />}*/
    //<div className="row chart-continer">
    _jsx(ResponsiveContainer, __assign({ width: "100%", height: "30%" }, { children: _jsxs(LineChart, __assign({ data: chartData, margin: {
                top: 0,
                right: 30,
                left: 10,
                bottom: 0,
            } }, { children: [_jsx(CartesianGrid, { vertical: false, stroke: "#303030", strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, { domain: domainData }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), _jsx(Legend, { iconType: "circle", iconSize: 5, verticalAlign: "buttom", height: 15, wrapperStyle: { position: "relative", marginButtom: 20 } }), Object.keys(data).length > 0 &&
                    data.data.map(function (pname, index) {
                        return (_jsx(Line, { type: "monotone", dataKey: pname.dataProviderName, stroke: color(pname.dataProviderName), strokeWidth: pname.dataProviderName.includes("SHELL-BASELINE") ? 4 : 1 }, "chart" + index));
                    })] })) }))
    //</div>
    //  </div>
    );
};
export default ChartList;
