import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
var search = /** @class */ (function (_super) {
    __extends(search, _super);
    function search(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.showSearchForm = function (e) {
            _this.setState({
                form: !_this.state.form
            });
        };
        _this.state = {
            form: false
        };
        return _this;
    }
    search.prototype.render = function () {
        return (_jsxs("div", __assign({ className: "topbar-search" }, { children: [this.state.form && (_jsx("form", __assign({ action: "", className: "search-form float-left" }, { children: _jsxs("div", __assign({ className: "form-group has-feedback" }, { children: [_jsx("label", __assign({ htmlFor: "search", className: "sr-only" }, { children: "Search" })), _jsx("input", { type: "text", className: "form-control", name: "search", id: "search", placeholder: "search" })] })) }))), _jsx("button", __assign({ onClick: this.showSearchForm }, { children: _jsx("i", { className: "fa fa-search icon-size", "aria-hidden": "true" }) }))] })));
    };
    return search;
}(Component));
export default search;
