import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Overlay } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
var Toggleicon = /** @class */ (function (_super) {
    __extends(Toggleicon, _super);
    function Toggleicon(props) {
        var _this = _super.call(this, props) || this;
        _this.onMouseOver = function (e) {
            _this.setState({ show: true });
        };
        _this.onMouseOut = function () {
            _this.setState({ show: false });
        };
        _this.handleClickexp = function () {
            _this.setState({ expOn: !_this.state.expOn });
        };
        _this.state = {
            expOn: true,
            show: false,
            target: null,
        };
        return _this;
    }
    Toggleicon.prototype.render = function () {
        var _this = this;
        var expOn = this.state.expOn;
        var show = this.state.show;
        var HtmlTooltip = withStyles(function (theme) { return ({
            tooltip: {
                backgroundColor: "#404040",
                color: "white",
                maxWidth: 400,
                fontSize: 13,
                fontFamily: "Arial",
                border: "1px solid #404040",
            },
        }); })(Tooltip);
        return (_jsxs("div", __assign({ className: "col-12 position-absolute", style: { zIndex: 9, right: 0, top: "14px" }, onClick: function () { return _this.handleClickexp(); } }, { children: [_jsx("div", __assign({ className: "capacity-data-row", style: { position: "absolute", width: "100%" } }, { children: "\u00A0" })), _jsxs("div", __assign({ className: "z-index mt-1" }, { children: [_jsx(Button, __assign({ className: "ml-2 px-2 float-left z-index border-0 bg-solid-toggle" }, { children: expOn ? (_jsx("i", { className: "fa fa-caret-square-o-down fa-lg" })) : (_jsx("i", { className: "fa fa-caret-square-o-up fa-lg" })) })), _jsx("div", __assign({ className: "bg-solid px-2 float-left z-index", style: { position: "absolute", left: "60px" } }, { children: _jsx(HtmlTooltip, __assign({ title: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: this.props.scalingInfo,
                                    } }) }, { children: _jsx("p", { children: _jsx("strong", { children: this.props.title }) }) })) }))] }))] })));
    };
    return Toggleicon;
}(Component));
export default Toggleicon;
