import { __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Supply from "./supply/supplyMain";
import StorageTank from "../globalInventoryDatabase/ReferenceData/tank/storageTank";
import Upload from "./scenarioUpload/uploadMain";
import "./globalSupplyDatabase.css";
import "./capacityscreen/capacity.css";
//import DataUpload from "./dataUpload/dataUpload";
import Capacityscreen from "./capacityscreen/capacityscreen";
/*import AssetUnitConfiguration from "./assetUnitConfiguration/assetUnitConfiguration";
import AssetConfiguration from "./assetConfiguration/AssetConfiguration";*/
var globalAssetDb = /** @class */ (function (_super) {
    __extends(globalAssetDb, _super);
    function globalAssetDb() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        return _this;
    }
    globalAssetDb.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs(Switch, { children: [_jsx(Redirect, { from: "/dashboard/globalSupplyDatabase", to: "/dashboard/globalSupplyDatabase/supply", exact: true }), _jsx(Route, { exact: true, path: "/dashboard/globalSupplyDatabase/supply/supplyMain", component: Supply }, "supply"), _jsx(Route, { exact: true, path: "/dashboard/globalSupplyDatabase/supply/supplyMain", component: Supply }, "supply/supplyMain"), _jsx(Route, { exact: true, path: "/dashboard/globalInventoryDatabase/ReferenceData/tank", component: StorageTank }, "storageTank"), _jsx(Route, { exact: true, path: "/dashboard/globalSupplyDatabase/scenarioUpload/uploadMain", component: Upload }, "supply/dataUpload")] }) }));
    };
    return globalAssetDb;
}(Component));
export default globalAssetDb;
