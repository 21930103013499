import { getSourceData } from "../../services/urlService";
import * as types from "../actions/capacityScreenActionsType";
export var loadFilters = function (path, filterType) { return function (dispatch) {
    getSourceData(path)
        .then(function (filterData) {
        if (filterData.data.length > 0) {
            dispatch({
                type: types.LOAD_FILTER_SUCCESS,
                filterData: filterData.data,
                filterDataType: filterType
            });
        }
    })
        .catch(function (error) {
        // console.error("Error encountered: Details - " + error);
    });
}; };
export var onSelection = function (item, filterType) { return function (dispatch) {
    if (item && filterType) {
        dispatch({
            type: types.ADD_TO_FILTER,
            payload: item,
            filterType: filterType
        });
    }
}; };
export var onUnSelection = function (item, filterType) { return function (dispatch) {
    if (item && filterType) {
        dispatch({
            type: types.DELETE_FROM_FILTER,
            payload: item,
            filterType: filterType
        });
    }
}; };
export var search = function (item, length, Cd, filterName) { return function (dispatch) {
    if (item) {
        dispatch({
            type: types.SEARCH_IN_FILTER,
            payload: item,
            length: length,
            Cd: Cd,
            filterName: filterName
        });
    }
}; };
export var reset = function () { return function (dispatch) {
    dispatch({
        type: types.RESET_ALL_FILTER,
        payload: "reset"
    });
}; };
