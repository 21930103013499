import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { getSourceFilter } from "../../../../../services/urlService.jsx";
import Spinner from "../../../../../commons/spinner";
import _ from "lodash";
var totalCapacityAssetUnit = /** @class */ (function (_super) {
    __extends(totalCapacityAssetUnit, _super);
    function totalCapacityAssetUnit(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.capacitySelect = function (e) {
            _this.props.onCapacitySelect(e.target);
        };
        _this.removeParam = function (key, sourceURL) {
            var rtn = sourceURL.split("?")[0], param, params_arr = [], queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
            if (queryString !== "") {
                params_arr = queryString.split("&");
                for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                    param = params_arr[i].split("=")[0];
                    if (param === key) {
                        params_arr.splice(i, 1);
                    }
                }
                rtn = rtn + "?" + params_arr.join("&");
            }
            return rtn;
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.props.baseProvider) {
                            this.setState({
                                limitForHighlight: this.props.limitForHighlight,
                                baseProvider: this.props.baseProvider,
                                deltaByVersion: false,
                                deltaByProvider: true,
                            });
                        }
                        else if (this.props.assetFilterParams.includes("isDeltaByProvider=false")) {
                            this.setState({
                                limitForHighlight: this.props.limitForHighlight,
                                deltaByProvider: false,
                                deltaByVersion: true,
                            });
                        }
                        else {
                            this.setState({
                                limitForHighlight: 0,
                                deltaByProvider: false,
                                deltaByVersion: false,
                            });
                        }
                        return [4 /*yield*/, this.setState({
                                loader: true,
                            }, function (e) { return __awaiter(_this, void 0, void 0, function () {
                                var pathCon;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            pathCon = "TotalCapacities/TotalCapacityByAsset?&" +
                                                this.props.assetFilterParams +
                                                "&SubGroupBy=Unit";
                                            return [4 /*yield*/, getSourceFilter(this.props.assetFilterParams.includes("&GroupBy=country&")
                                                    ? pathCon
                                                    : this.removeParam("a", pathCon.replace(/\?&+/g, "?").replace(/&+/g, "&")) +
                                                        "&a=" +
                                                        this.props.assetKey)
                                                    .then(function (returnData) {
                                                    if (typeof returnData.data !== "undefined" &&
                                                        typeof returnData !== "undefined") {
                                                        _this.setState({
                                                            data: returnData.data[0].totalCapacityUnitTypeGroupList,
                                                            countryId: returnData.data[0].countryId,
                                                            countryNm: returnData.data[0].countryNm,
                                                            cityId: returnData.data[0].cityId,
                                                            cityNm: returnData.data[0].cityNm,
                                                            ownerId: returnData.data[0].ownerId,
                                                            ownerNm: returnData.data[0].ownerNm,
                                                            assetKey: returnData.data[0].groupId,
                                                            assetNm: returnData.data[0].groupName,
                                                            assetClassKey: returnData.data[0].assetClassKey,
                                                            assetClassDesc: returnData.data[0].assetClassDesc,
                                                            loader: false,
                                                        });
                                                    }
                                                })
                                                    .catch(function (error) {
                                                    _this.setState({
                                                        loader: false,
                                                        data: [],
                                                    });
                                                })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //Random function
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.state = {
            data: [],
            limitForHighlight: null,
            deltaByProvider: false,
            deltaByVersion: false,
        };
        return _this;
    }
    totalCapacityAssetUnit.prototype.render = function () {
        var _this = this;
        return (_jsxs("div", __assign({ id: "capacitycontainer" }, { children: [_jsx("div", __assign({ className: "col-12 k-widget k-grid capacity-grid", id: "capacityscreen" }, { children: _jsx("div", __assign({ style: { overflowX: "auto" } }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "k-header text-centre" }, { children: _jsx("div", __assign({ style: { width: "55px" } }, { children: " \u00A0 " })) })), Object.keys(this.state.data).length > 0 &&
                                                this.state.data.map(function (uType, uTypeIndex) {
                                                    return (_jsxs("th", __assign({ className: "k-header text-centre" }, { children: [_jsx("div", __assign({ className: "pr-1", style: {
                                                                    margin: "0 1px 4px 0",
                                                                } }, { children: _jsx("button", __assign({ className: "k-link no-pointer text-centre", style: {
                                                                        padding: "6px",
                                                                    } }, { children: uType.assetUnitTypeCd })) })), _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: uType.totalCapacityUnitSubTypeGroupList.map(function (uSubType, uSubTypeIndex) {
                                                                            return (_jsxs("th", __assign({ className: "k-header text-centre" }, { children: [_jsx("div", __assign({ className: "pr-1", style: {
                                                                                            margin: "0 1px 4px 0",
                                                                                        } }, { children: _jsx("button", __assign({ className: "k-link no-pointer text-centre", style: {
                                                                                                padding: "6px",
                                                                                            } }, { children: uSubType.unitSubTypeCd })) })), _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: uSubType.totalCapacityUnitGroupList.map(function (unit, unitIndex) {
                                                                                                    return (_jsx("th", __assign({ className: "k-header text-centre" }, { children: _jsx("div", __assign({ className: "td-data ellipsis mr-1" }, { children: _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-centre", title: unit.unitName }, { children: unit.unitName })) })) }), unitIndex + "unitIndex"));
                                                                                                }) }) }) })] }), uSubTypeIndex + "unitSubTypeNm"));
                                                                        }) }) }) })] }), uTypeIndex + "unitType"));
                                                })] }) }), _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: _jsx("table", { children: _jsx("tbody", { children: Object.keys(this.state.data).length > 0 &&
                                                            this.state.data[0].totalCapacityUnitSubTypeGroupList[0].totalCapacityUnitGroupList[0].totalCapacityDataList.map(function (uType, uTypeIndex) {
                                                                return (_jsx("tr", { children: _jsx("td", __assign({ style: {
                                                                            paddingBottom: "4px",
                                                                            paddingRight: "10px",
                                                                            textAlign: "right",
                                                                        } }, { children: _jsx("div", __assign({ style: { height: "36px" } }, { children: _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-right" }, { children: uTypeIndex === 0 ? ("Baseline") : (_jsx("span", { children: uType.dataProviderName })) })) })) })) }, uTypeIndex + "-uTypeIndex")
                                                                // </div>
                                                                );
                                                            }) }) }) }), Object.keys(this.state.data).length > 0 &&
                                                this.state.data.map(function (uType, uTypeIndex) {
                                                    var unitTypeId = uType.assetUnitTypeId;
                                                    var unitTypeName = uType.assetUnitTypeCd;
                                                    return (_jsx("td", { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: uType.totalCapacityUnitSubTypeGroupList.map(function (uSubType, uSubTypeIndex) {
                                                                        var unitSubTypeId = uSubType.unitSubTypeId;
                                                                        var unitSubTypeName = uSubType.unitSubTypeCd;
                                                                        return (_jsx("td", { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: uSubType.totalCapacityUnitGroupList.map(function (unit, unitIndex) {
                                                                                            var unitId = unit.unitId;
                                                                                            var unitName = unit.unitName;
                                                                                            var providerValue = 0;
                                                                                            if (_this.state.deltaByProvider) {
                                                                                                for (var i = 0; i < unit.totalCapacityDataList.length; i++) {
                                                                                                    if (unit.totalCapacityDataList[i].dataProviderId === _this.state.baseProvider.value) {
                                                                                                        providerValue = unit.totalCapacityDataList[i].capacityQty;
                                                                                                        break;
                                                                                                    }
                                                                                                    if (i === unit.totalCapacityDataList.length - 1)
                                                                                                        providerValue = null;
                                                                                                }
                                                                                            }
                                                                                            return (_jsx("td", __assign({ className: "k-header text-centre" }, { children: unit.totalCapacityDataList.map(function (cpct, cpctIndex) {
                                                                                                    var limitClass = '';
                                                                                                    if (_this.state.deltaByVersion) {
                                                                                                        if (cpct.capacityQty > 0 && cpct.capacityQty > _this.state.limitForHighlight)
                                                                                                            limitClass = 'limit-more';
                                                                                                        else if (cpct.capacityQty < 0 && cpct.capacityQty < -1 * _this.state.limitForHighlight)
                                                                                                            limitClass = 'limit-less';
                                                                                                        else
                                                                                                            limitClass = '';
                                                                                                    }
                                                                                                    if (_this.state.deltaByProvider) {
                                                                                                        if (
                                                                                                        // cpct.capacityQty &&
                                                                                                        providerValue !== null &&
                                                                                                            providerValue - cpct.capacityQty > 0 &&
                                                                                                            providerValue - cpct.capacityQty > _this.state.limitForHighlight)
                                                                                                            limitClass = 'limit-more';
                                                                                                        else if (
                                                                                                        // cpct.capacityQty &&
                                                                                                        providerValue !== null &&
                                                                                                            providerValue - cpct.capacityQty < 0 &&
                                                                                                            providerValue - cpct.capacityQty < -1 * _this.state.limitForHighlight)
                                                                                                            limitClass = 'limit-less';
                                                                                                        else
                                                                                                            limitClass = '';
                                                                                                    }
                                                                                                    if (cpctIndex === 0) {
                                                                                                        return (_jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: _jsx("td", __assign({ className: "k-header" }, { children: _jsx("div", __assign({ style: {
                                                                                                                                width: "145px",
                                                                                                                            }, className: "td-data " + limitClass }, { children: _jsx("button", __assign({ className: "k-link text-centre", "data-id": unitId, "data-country-id": _this
                                                                                                                                    .state
                                                                                                                                    .countryId, "data-country": _this
                                                                                                                                    .state
                                                                                                                                    .countryNm, "data-city-id": _this
                                                                                                                                    .state
                                                                                                                                    .cityId, "data-city": _this
                                                                                                                                    .state
                                                                                                                                    .cityNm, "data-owner-id": _this
                                                                                                                                    .state
                                                                                                                                    .ownerId, "data-owner": _this
                                                                                                                                    .state
                                                                                                                                    .ownerNm, "data-asset-id": _this
                                                                                                                                    .state
                                                                                                                                    .assetKey, "data-assetname": _this
                                                                                                                                    .state
                                                                                                                                    .assetNm, "data-assettype-id": _this
                                                                                                                                    .state
                                                                                                                                    .assetClassKey, "data-assettype": _this
                                                                                                                                    .state
                                                                                                                                    .assetClassDesc, "data-unittype-id": unitTypeId, "data-unittype": unitTypeName, "data-unitsubtype-id": unitSubTypeId, "data-unitsubtype": unitSubTypeName, "data-unit-id": unitId, "data-unit": unitName, onClick: _this
                                                                                                                                    .capacitySelect }, { children: cpct.capacityQty ==
                                                                                                                                    ""
                                                                                                                                    ? ""
                                                                                                                                    : _.round(cpct.capacityQty, 1) +
                                                                                                                                        " " +
                                                                                                                                        cpct.capacityUom })) }), cpctIndex) })) }) }) }, cpctIndex +
                                                                                                            "cpctIndex"));
                                                                                                    }
                                                                                                    else {
                                                                                                        return (_jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: _jsx("td", __assign({ className: "k-header" }, { children: _jsx("div", __assign({ style: {
                                                                                                                                width: "145px",
                                                                                                                            }, className: "td-data " + limitClass }, { children: _jsx("button", __assign({ className: "k-link no-pointer text-centre" }, { children: cpct.capacityQty ==
                                                                                                                                    ""
                                                                                                                                    ? ""
                                                                                                                                    : _.round(cpct.capacityQty, 1) +
                                                                                                                                        " " +
                                                                                                                                        cpct.capacityUom })) }), cpctIndex) })) }) }) }, cpctIndex +
                                                                                                            "cpctIndex"));
                                                                                                    }
                                                                                                }) }), unitIndex + "unitIndex"));
                                                                                        }) }) }) }) }, uSubTypeIndex + "unitSubType"));
                                                                    }) }) }) }) }, uTypeIndex + "unitType"));
                                                })] }) })] }) })) })), this.state.loader && _jsx(Spinner, {})] })));
    };
    return totalCapacityAssetUnit;
}(Component));
export default totalCapacityAssetUnit;
