import { __assign, __extends } from "tslib";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
var CustomDateInput = /** @class */ (function (_super) {
    __extends(CustomDateInput, _super);
    function CustomDateInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputStyle = {
            width: 90
        };
        _this.handleChange = function (syntheticEvent) {
            var date = {
                day: _this.props.value.getDate(),
                month: _this.props.value.getMonth(),
                year: _this.props.value.getFullYear()
            };
            date[syntheticEvent.target.getAttribute("data-section")] = Number(syntheticEvent.target.value);
            var value = new Date(date.year, date.month, date.day);
            _this.props.onChange({
                value: value,
                syntheticEvent: syntheticEvent,
                target: _this
            });
        };
        return _this;
    }
    CustomDateInput.prototype.render = function () {
        return (_jsxs(_Fragment, { children: [_jsxs("span", __assign({ className: this.props.textClass }, { children: [this.props.dimtext, ":"] })), _jsx("span", __assign({ className: this.props.dateClass }, { children: this.props.datetype }))] }));
    };
    return CustomDateInput;
}(Component));
export { CustomDateInput };
