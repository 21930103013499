import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
var notification = /** @class */ (function (_super) {
    __extends(notification, _super);
    function notification() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    notification.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsxs(Dropdown, __assign({ className: "settings" }, { children: [_jsx(Dropdown.Toggle, __assign({ variant: "none", id: "dropdown", className: "no-bg-button-icon" }, { children: _jsx("i", { className: "fa fa-bell icon-size", "aria-hidden": "true" }) })), _jsxs(Dropdown.Menu, __assign({ alignRight: true }, { children: [_jsx(Dropdown.Item, { children: "Another action" }), _jsx(Dropdown.Item, { children: "Something else" })] }))] })) }));
    };
    return notification;
}(Component));
export default notification;
