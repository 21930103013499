import { __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import React from "react";
// import {
//   Chart,
//   ChartTitle,
//   ChartSeries,
//   ChartSeriesItem,
//   ChartCategoryAxis,
//   ChartCategoryAxisItem
// } from "@progress/kendo-react-charts";
// class CrudeDifferentials extends React.Component {
//   render() {
//     const categories = [
//       "8-May-18",
//       "8-Jun-18",
//       "8-Jul-18",
//       "8-Jan-19",
//       "8-Feb-19",
//       "8-Mar-19",
//       "8-Apr-19",
//       "8-May-19",
//       "8-Jun-19",
//       "8-Jul-19",
//       "8-Aug-19",
//       "8-Sep-19"
//     ];
//     return (
//       <Chart>
//         <ChartTitle text="Global Refining Margins" />
//         <ChartCategoryAxis>
//           <ChartCategoryAxisItem categories={categories} />
//         </ChartCategoryAxis>
//         <ChartSeries>
//           <ChartSeriesItem
//             type="line"
//             data={[123, 276, 310, 212, 240, 156, 98, 310, 212, 240, 156, 98]}
//           />
//           <ChartSeriesItem
//             type="line"
//             data={[310, 212, 240, 156, 98, 165, 210, 287, 144, 190, 167, 212]}
//           />
//           <ChartSeriesItem
//             type="line"
//             data={[165, 232, 343, 234, 310, 212, 240, 156, 98, 190, 233, 50]}
//           />
//         </ChartSeries>
//       </Chart>
//     );
//   }
// }
//export default CrudeDifferentials;
import React from "react";
import "@progress/kendo-react-charts";
import "@progress/kendo-react-popup";
import "@progress/kendo-react-inputs";
import "@progress/kendo-react-intl";
import "@progress/kendo-data-query";
import "@progress/kendo-drawing";
import "@progress/kendo-file-saver";
import { Chart, ChartLegend, ChartTitle, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem } from "@progress/kendo-react-charts";
var CrudeDifferentials = /** @class */ (function (_super) {
    __extends(CrudeDifferentials, _super);
    function CrudeDifferentials() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CrudeDifferentials.prototype.render = function () {
        var chartData = [
            { date: new Date(2018, 3, 8), value1: 50, value2: 100, value3: 165 },
            { date: new Date(2018, 6, 8), value1: 50, value2: 100, value3: 165 },
            { date: new Date(2019, 1, 8), value1: 50, value2: 100, value3: 165 },
            { date: new Date(2019, 2, 8), value1: 150, value2: 255, value3: 265 },
            { date: new Date(2019, 3, 8), value1: 250, value2: 355, value3: 365 },
            { date: new Date(2019, 4, 8), value1: 350, value2: 455, value3: 365 },
            { date: new Date(2019, 5, 8), value1: 450, value2: 555, value3: 465 },
            { date: new Date(2019, 6, 8), value1: 550, value2: 655, value3: 565 },
            { date: new Date(2019, 7, 8), value1: 250, value2: 355, value3: 365 },
            { date: new Date(2019, 8, 8), value1: 350, value2: 455, value3: 365 },
            { date: new Date(2019, 9, 8), value1: 450, value2: 555, value3: 465 },
            { date: new Date(2019, 10, 8), value1: 550, value2: 655, value3: 565 }
        ];
        var categoryNotes = {
            line: {
                length: 240
            },
            icon: {
                border: {
                    width: 0
                }
            },
            // Initial notes
            data: [
                {
                // value: new Date(2010, 11, 12)
                }
            ]
        };
        var valueNotes = {
            line: {
                length: 1000
            },
            icon: {
                border: {
                    width: 0
                }
            },
            // Initial notes
            data: [
                {
                    value: 100
                }
            ]
        };
        return (_jsxs(Chart, { children: [_jsx(ChartTitle, { text: "Margins", color: "white" }), _jsx(ChartLegend, { position: "bottom", visible: true, orientation: "horizontal" }), _jsx(ChartCategoryAxis, { children: _jsx(ChartCategoryAxisItem, { labels: { format: "{0:8-MMM-yy}", rotation: 300 }, crosshair: { visible: true }, type: "date", baseUnit: "months", notes: categoryNotes }) }), _jsx(ChartValueAxis, { children: _jsx(ChartValueAxisItem, { title: { text: "" }, crosshair: { visible: true }, axisCrossingValue: -100, notes: valueNotes }) }), _jsxs(ChartSeries, { children: [_jsx(ChartSeriesItem, { data: chartData, type: "line", name: "WTI-Brent -7.67 w/w +0.48", field: "value1", categoryField: "date" }), _jsx(ChartSeriesItem, { data: chartData, type: "line", name: "Brent-Dubai 2.92 w/w +0.94", field: "value2", categoryField: "date" }), _jsx(ChartSeriesItem, { data: chartData, type: "line", name: "LLS-Mars 1.54 w/w -0", dashType: "dash", field: "value3", categoryField: "date" })] })] }));
    };
    return CrudeDifferentials;
}(React.Component));
export default CrudeDifferentials;
