import { __assign, __extends } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { saveSourceData, getSourceFilter, } from "../../../../../services/urlService";
import Spinner from "../../../../../commons/spinner";
var moment = require("moment");
var PromoteToBaselineButton = /** @class */ (function (_super) {
    __extends(PromoteToBaselineButton, _super);
    function PromoteToBaselineButton(props) {
        var _this = _super.call(this, props) || this;
        _this.anchor = null;
        _this.anchorAlign = {
            horizontal: "center",
            vertical: "bottom",
        };
        _this.popupAlign = {
            horizontal: "center",
            vertical: "top",
        };
        _this.handleScroll = function () {
            if (_this.state.show)
                _this.setState({ show: false });
        };
        _this.handleClickOutside = function (event) {
            var domNode = ReactDOM.findDOMNode(_this);
            if (!domNode || !domNode.contains(event.target)) {
                _this.setState({
                    show: false,
                });
            }
        };
        _this.onProviderDataClick = function (e) {
            e.preventDefault();
            _this.offSet = { left: e.clientX, top: e.clientY };
            _this.setState({
                show: !_this.state.show,
                dataProviderToPromote: e.target.innerText,
            });
        };
        _this.onPromoteToBaselineClick = function (e) {
            var path = "promoteToBaseline";
            var startDate = moment.utc(_this.props.startMonth).toISOString(); //new Date(this.props.startMonth).toISOString();
            var endDate = moment.utc(_this.props.endMonth).toISOString(); //new Date(this.props.endMonth).toISOString();
            var apiParam = {
                country: _this.props.country,
                //materialProduct: this.props.materialProduct,
                materialFamily: _this.props.materialFamily,
                materialGroup: _this.props.materialGroup,
                materialGrade: _this.props.materialGrade,
                startMonth: startDate,
                endMonth: endDate,
                //dataProviderName: this.state.dataProviderToPromote,
            };
            _this.setState({
                show: false,
                spinner: true,
            }, function () {
                saveSourceData(apiParam, path).then(function (response) {
                    if (response !== undefined && response.status === 200) {
                        //this.props.setShouldRefresh(true);
                        var refreshPath = _this.props.path;
                        var refreshReturn = getSourceFilter(_this.props.applyUrl, refreshPath).then(function (returnData) {
                            if (typeof returnData !== "undefined" &&
                                returnData.data.tableData.length > 0) {
                                _this.props.setSelectedData(returnData.data.tableData);
                                _this.props.setGraphData(returnData.data.graphData);
                                //this.props.setShouldRefresh(false);
                            }
                            else {
                                _this.setState({
                                    spinner: false,
                                });
                            }
                        });
                    }
                    else {
                        _this.setState({
                            spinner: false,
                        });
                    }
                });
            });
        };
        _this.state = {
            show: false,
            spinner: false,
            dataProviderToPromote: null,
        };
        return _this;
    }
    PromoteToBaselineButton.prototype.componentDidMount = function () {
        document.addEventListener("click", this.handleClickOutside, true);
        document.addEventListener("scroll", this.handleScroll, true);
    };
    PromoteToBaselineButton.prototype.componentWillUnmount = function () {
        document.removeEventListener("click", this.handleClickOutside, true);
        document.removeEventListener("scroll", this.handleScroll, true);
    };
    PromoteToBaselineButton.prototype.render = function () {
        var _this = this;
        return (_jsxs(_Fragment, { children: [this.state.spinner && _jsx(Spinner, {}), _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-right", 
                    //onClick={this.onProviderDataClick}
                    onContextMenu: this.onProviderDataClick, ref: function (button) {
                        _this.anchor = button;
                    } }, { children: this.props.dataProviderName })), _jsx(Popup, __assign({ anchor: this.anchor, anchorAlign: this.anchorAlign, popupAlign: this.popupAlign, collision: {
                        vertical: "fit",
                    }, offset: this.offSet, show: this.state.show, popupClass: "promote-popup" }, { children: _jsx(Button, __assign({ look: "flat", onClick: this.onPromoteToBaselineClick }, { children: "Promote to Baseline" })) }))] }));
    };
    return PromoteToBaselineButton;
}(Component));
export default PromoteToBaselineButton;
