import { __assign, __extends } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
// class Iframe extends Component {
//   iframe() {
//     return {
//       __html: this.props.iframe
//     };
//   }
//   render() {
//     return (
//       <div>
//         <div dangerouslySetInnerHTML={this.iframe()} />
//       </div>
//     );
//   }
// }
// export default Iframe;
import React, { Component } from "react";
var Iframe = /** @class */ (function (_super) {
    __extends(Iframe, _super);
    function Iframe() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    Iframe.prototype.componentDidMount = function () {
        this.refs.iframe.addEventListener("load", this.props.onLoad);
    };
    Iframe.prototype.render = function () {
        return _jsx("iframe", __assign({ ref: "iframe" }, this.props));
    };
    return Iframe;
}(Component));
export default Iframe;
