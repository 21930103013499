var programsMenu = {
    title: "Programs",
    caption: "Geneva",
    menu: [
        {
            genevaMenu: {
                id: "1",
                uniqueKey: 1,
                title: "Supply",
                component_path: " ",
            },
            items: [
                {
                    genevaMenu: {
                        id: "2",
                        uniqueKey: 2,
                        title: "Supply Production",
                        component_path: "/dashboard/globalSupplyDatabase/supply/supplyMain",
                    },
                },
                {
                    genevaMenu: {
                        id: "3",
                        uniqueKey: 3,
                        title: "Scenario",
                        component_path: "/dashboard/globalSupplyDatabase/scenarioUpload/uploadMain",
                    },
                },
            ],
            expanded: true,
        },
    ],
};
export function getProgramsMenu() {
    return programsMenu;
}
