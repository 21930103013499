import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { DropDownButton, ButtonGroup, Button, } from "@progress/kendo-react-buttons";
var itemRender = function (props) {
    return _jsx("span", __assign({ className: "current-history-item" }, { children: "".concat(props.item) }));
};
var ToggleBtnHistory = /** @class */ (function (_super) {
    __extends(ToggleBtnHistory, _super);
    function ToggleBtnHistory(props) {
        var _this = _super.call(this, props) || this;
        _this.componentDidMount = function () {
            if (_this.props.onSwitchPreviousSelectedItems) {
                _this.setState({
                    value: "Current + - " + _this.props.onSwitchPreviousSelectedItems + " year",
                });
                _this.props.apiValueCurrentHistory(_this.props.onSwitchPreviousSelectedItems);
            }
            else {
                if (_this.props.onSwitchPreviousSelectedItems > -1 &&
                    _this.props.onSwitchPreviousSelectedItems === 0) {
                    _this.setState({
                        primaryC: false,
                        primaryH: true,
                    });
                    _this.props.apiValueCurrentHistory(0);
                }
            }
        };
        _this.handleItemEvent = function (event) {
            if (event.item == "Current year") {
                _this.setState({
                    value: "Current year",
                });
                _this.props.apiValueCurrentHistory(0);
            }
            else {
                _this.setState({
                    value: "Current + - " + event.item + " year",
                });
                _this.props.apiValueCurrentHistory(event.item);
            }
        };
        _this.handleDomEvent = function () {
            _this.setState({
                primaryC: true,
                primaryH: false,
            });
        };
        _this.allHistory = function (event) {
            _this.setState({
                primaryC: false,
                primaryH: true,
            });
            _this.props.apiValueCurrentHistory(-1);
        };
        _this.state = {
            primaryC: true,
            primaryH: false,
            activeIndex: 0,
            activeLinkUnit: 1,
            value: "Current + - 3 year",
        };
        return _this;
    }
    ToggleBtnHistory.prototype.render = function () {
        return (_jsxs(ButtonGroup, __assign({ className: "bg-solid mx-3 pull-right" }, { children: [_jsx(DropDownButton, { className: "toggle-dropdown", text: this.state.value, items: ["Current year", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], onItemClick: this.handleItemEvent, onFocus: this.handleDomEvent, itemRender: itemRender, primary: this.state.primaryC, open: true }), _jsx(Button, __assign({ className: "btn border", primary: this.state.primaryH, onClick: this.allHistory }, { children: "All History" }))] })));
    };
    return ToggleBtnHistory;
}(Component));
export default ToggleBtnHistory;
