import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import "./leftNav.css";
function RightNavPanel(props) {
    var _a = useState(true), bool = _a[0], setToggle = _a[1];
    var _b = useState(props.data.baselineComments[0]), isActive = _b[0], setActive = _b[1];
    var listItems = props.data.baselineComments.map(function (list, comment) { return (_jsx("li", __assign({ className: "text-left px-1 my-1 ".concat(isActive === list.dateOrder ? "active" : ""), style: { paddingTop: "0.25rem", paddingBottom: "0.25rem" }, onClick: function () {
            setActive(list.dateOrder);
            //props.divRefs[dateOrder].scrollIntoView();
        } }, { children: _jsxs("span", { children: [_jsx("span", __assign({ className: "col-sm-0", style: { paddingRight: ".25rem" } }, { children: _jsx("i", { className: "fa fa-angle-double-right pr-0", "aria-hidden": "true" }) })), _jsx("span", __assign({ style: {
                        fontSize: "small",
                        marginBottom: ".25rem",
                        paddingLeft: ".25rem",
                    } }, { children: list.comment })), _jsxs("p", __assign({ style: {
                        fontSize: "smaller",
                        marginTop: ".1rem",
                        marginBottom: ".25rem",
                        paddingLeft: ".25rem",
                        paddingTop: ".25rem",
                        fontFamily: "FontAwesome",
                        borderBottom: "1px dotted #59595975",
                    } }, { children: [list.commentPeriodStart, " - ", list.commentPeriodEnd] })), _jsxs("span", __assign({ style: { fontSize: "smaller", fontFamily: "system-ui" } }, { children: [list.commentUserName, " \u00A0"] })), _jsxs("span", __assign({ style: { fontSize: "x-small", fontStyle: "italic" } }, { children: [list.commentDate, " \u00A0"] }))] }) }), list.dateOrder)); });
    return (_jsxs("div", __assign({ style: {
            width: bool ? "10%" : "100%",
            position: "relative",
            zIndex: "9999",
        } }, { children: [_jsx("div", __assign({ 
                //style={{ height: window.innerHeight - 300 }}
                id: "listItems", className: "navcontainer pull-left ".concat(bool ? "show" : "hidden") }, { children: _jsx("ul", { children: listItems }) })), _jsx("div", __assign({ className: "pull-right ".concat(bool ? "position-absolute" : ""), style: {
                    position: "position: relative; z-index:10000",
                    right: "".concat(bool ? "-300px" : "5px"),
                    top: "-28px",
                } }, { children: bool ? (_jsx("button", __assign({ 
                    // className="togleBtn mt-3"
                    onClick: function () {
                        setToggle(false);
                        document.getElementById("productionnumber" + props.index).style.width = "1600px";
                    } }, { children: _jsx("i", { className: "fa fa-comments-o fa-2x", "aria-hidden": "true" }) }))) : (_jsx("button", __assign({ onClick: function () {
                        setToggle(true);
                        document.getElementById("productionnumber" + props.index).style.width = "1286px";
                    } }, { children: _jsx("i", { className: "fa fa-comments-o fa-2x", "aria-hidden": "true" }) }))) }))] })));
}
export default RightNavPanel;
