import { __awaiter, __generator } from "tslib";
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { LOCALSTORAGE_REFRESH_TOKEN, LOCALSTORAGE_ACCESS_TOKEN, LOCALSTORAGE_CODE_VERIFIER, LOCALSTORAGE_PREVIOUS_ROUTE, } from '../../../common/contants';
import { generateCodeVerifier, createAuthorizeUrl } from './utils';
var PINGID_CODE = 'code';
export var useRequestAccessToken = function () {
    var search = useLocation().search;
    var queryParams = new URLSearchParams(search);
    var code = queryParams.get(PINGID_CODE);
    return useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var savedCodeVerifier, codeVerifier, resp, json, previousPath, path, codeVerifier, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!code) return [3 /*break*/, 4];
                    savedCodeVerifier = window.localStorage.getItem(LOCALSTORAGE_CODE_VERIFIER);
                    codeVerifier = savedCodeVerifier ? JSON.parse(savedCodeVerifier) : null;
                    if (!codeVerifier) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetch(process.env.REACT_APP_SSO_TOKEN_URI, {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            method: 'post',
                            body: new URLSearchParams({
                                grant_type: 'authorization_code',
                                client_id: process.env.REACT_APP_SSO_CLIENT_ID,
                                code_verifier: codeVerifier,
                                code: code,
                                redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
                            }),
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, resp.json()];
                case 2:
                    json = _a.sent();
                    window.localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, JSON.stringify(json.access_token));
                    window.localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, JSON.stringify(json.refresh_token));
                    try {
                        previousPath = window.localStorage.getItem(LOCALSTORAGE_PREVIOUS_ROUTE);
                        path = previousPath ? JSON.parse(previousPath) : '/';
                        window.localStorage.removeItem(LOCALSTORAGE_PREVIOUS_ROUTE);
                        window.location.replace(path);
                    }
                    catch (e) {
                        window.location.replace('/');
                    }
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    codeVerifier = generateCodeVerifier();
                    window.localStorage.setItem(LOCALSTORAGE_CODE_VERIFIER, JSON.stringify(codeVerifier));
                    url = createAuthorizeUrl(codeVerifier);
                    window.localStorage.setItem(LOCALSTORAGE_PREVIOUS_ROUTE, JSON.stringify(window.location.pathname));
                    if (url) {
                        window.location.replace(url);
                    }
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [code]);
};
