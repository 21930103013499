import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
import Toggleicon from "./tools/toggleicon";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ProductionView from "./productionView";
import RightNavPanel from "../../../../../commons/tools/rightNavPanel";
var supplyProductionAccordion = /** @class */ (function (_super) {
    __extends(supplyProductionAccordion, _super);
    function supplyProductionAccordion(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentRandKey: "",
        };
        return _this;
    }
    supplyProductionAccordion.prototype.getScalingInfo = function () {
        var scalingInfo = [];
        var scalingInfoDetails = "";
        if (typeof this.props.data.scalingDataProviderInfos != "undefined") {
            scalingInfo = this.props.data.scalingDataProviderInfos;
        }
        var length = scalingInfo.length;
        if (length == 0) {
            scalingInfoDetails =
                "Preferred Data Provider is Rystad. No Automatic Scaling Defined.";
        }
        else {
            for (var i = 0; i < length; i++) {
                var scalingDetail = "";
                var condensateInd = scalingInfo[i].condensateIncludedInd;
                var crudeInd = scalingInfo[i].crudeIncludedInd;
                var nglInd = scalingInfo[i].nglIncludedInd;
                var scalingType = scalingInfo[i].scalingType;
                var dataProvider = scalingInfo[i].dataProviderNm;
                var period = scalingInfo[i].endMonthNum;
                var scalingPriod = "";
                var scalingBaseOn = "";
                if (scalingType === "Actualization") {
                    scalingPriod = scalingType + " (<= M" + period + ")";
                }
                else if (scalingType === "Nowcasting") {
                    scalingPriod = scalingType + " (M" + period + ")";
                }
                else {
                    scalingPriod = scalingType + " (> M" + period + ")";
                }
                if (crudeInd == "Y") {
                    scalingBaseOn = scalingBaseOn + "Crude,";
                }
                if (condensateInd === "Y") {
                    scalingBaseOn = scalingBaseOn + "Condensate,";
                }
                if (nglInd == "Y") {
                    scalingBaseOn = scalingBaseOn + "NGL,";
                }
                scalingDetail =
                    "Scaling Period: " +
                        scalingPriod +
                        "</br>" +
                        "Data Provider: " +
                        dataProvider +
                        "</br>" +
                        "Scaling Base On: " +
                        scalingBaseOn.substring(0, scalingBaseOn.length - 1) +
                        "</br>";
                scalingInfoDetails = scalingInfoDetails + scalingDetail;
            }
        }
        return scalingInfoDetails;
    };
    supplyProductionAccordion.prototype.getTitle = function () {
        var title;
        var groupName = "";
        var familyName = "";
        for (var i = 0; i < this.props.selectedGroupName.length; i++) {
            groupName = groupName + ", " + this.props.selectedGroupName[i];
        }
        for (var i = 0; i < this.props.selectedFamilyName.length; i++) {
            familyName = familyName + ", " + this.props.selectedFamilyName[i];
        }
        if (this.props.selectedGroupName.length == 0 &&
            this.props.selectedFamilyName.length == 0) {
            title = this.props.title;
        }
        else {
            if (this.props.selectedFamilyName.length > 0) {
                familyName = familyName.substring(1, familyName.length);
                title = this.props.title + " (" + familyName;
            }
            if (this.props.selectedGroupName.length > 0) {
                groupName = groupName.substring(1, groupName.length);
                title = title + " -" + groupName;
            }
            title = title + " )";
        }
        return title;
    };
    supplyProductionAccordion.prototype.render = function () {
        return (_jsx(_Fragment, { children: _jsx(Accordion, __assign({ defaultActiveKey: this.props.accordionKey == 0 ? "0" : this.props.accordionKey }, { children: _jsxs(Card, { children: [_jsx(Accordion.Toggle, __assign({ as: Card.Header, eventKey: this.props.accordionKey == 0 ? "0" : this.props.accordionKey }, { children: _jsx(Toggleicon, { title: this.getTitle(), scalingInfo: this.getScalingInfo() }) })), _jsx(Accordion.Collapse, __assign({ eventKey: this.props.accordionKey == 0 ? "0" : this.props.accordionKey }, { children: _jsxs(Card.Body, { children: [_jsx("div", __assign({ className: "pull-right position-relative" }, { children: _jsx(RightNavPanel, { data: this.props.data, index: this.props.accordionKey }) })), _jsx("div", { children: _jsx(ProductionView, { data: this.props.data, country: this.props.countryKey, countryId: this.props.countryId, countryIndex: this.props.accordionKey, onDataSelect: this.props.onDataSelect, shouldRefresh: this.props.shouldRefresh, selectedKey: this.props.selectedKey, applyUrl: this.props.applyUrl, selectedMaterialGroup: this.props.selectedMaterialGroup, selectedMaterialFamily: this.props.selectedMaterialFamily, setShouldRefresh: this.props.setShouldRefresh, setSelectedData: this.props.setSelectedData, setGraphData: this.props.setGraphData }) }, this.props.identifier)] }) }))] }) })) }));
    };
    return supplyProductionAccordion;
}(Component));
export default supplyProductionAccordion;
