import { __awaiter, __generator } from "tslib";
import { useEffect, useState } from 'react';
import { useAccessToken } from '../../hooks/access-token';
import { getAccessTokenData } from '../../utils/access-token/get-access-token-data';
import { LOCALSTORAGE_ACCESS_TOKEN, LOCALSTORAGE_PREVIOUS_ROUTE, LOCALSTORAGE_REFRESH_TOKEN } from '../../contants';
var requestAccessToken = function (refreshToken, round) {
    if (round === void 0) { round = 0; }
    return __awaiter(void 0, void 0, Promise, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(process.env.REACT_APP_SSO_TOKEN_URI, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        method: 'post',
                        body: new URLSearchParams({
                            grant_type: 'refresh_token',
                            refresh_token: refreshToken,
                        }),
                    })];
                case 1:
                    resp = _a.sent();
                    if (resp.ok) {
                        return [2 /*return*/, resp];
                    }
                    else if (round < 3) {
                        return [2 /*return*/, requestAccessToken(refreshToken, round + 1)];
                    }
                    else {
                        throw new Error('Could not request new token');
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var useScheduleRefreshToken = function () {
    var accessToken = useAccessToken();
    var _a = useState(accessToken), internalAccessToken = _a[0], setInternalToken = _a[1];
    useEffect(function () {
        var accessTokenData = getAccessTokenData();
        if (accessTokenData) {
            // request new access token 2min before expiration
            var expirationMilliseconds = accessTokenData.exp * 1000;
            var time = expirationMilliseconds - 120000 - Date.now();
            if (time <= 0) {
                window.localStorage.clear();
                window.localStorage.setItem(LOCALSTORAGE_PREVIOUS_ROUTE, JSON.stringify(window.location.pathname));
                window.location.replace('/');
                return;
            }
            var timerId_1 = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var refreshToken, resp, json, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            refreshToken = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN));
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 4, , 5]);
                            return [4 /*yield*/, requestAccessToken(refreshToken, 0)];
                        case 2:
                            resp = _a.sent();
                            return [4 /*yield*/, resp.json()];
                        case 3:
                            json = _a.sent();
                            window.localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, JSON.stringify(json.access_token));
                            window.localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, JSON.stringify(json.refresh_token));
                            setInternalToken(json.access_token);
                            return [3 /*break*/, 5];
                        case 4:
                            e_1 = _a.sent();
                            console.error('Error', e_1);
                            window.localStorage.clear();
                            window.location.replace('/');
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); }, time);
            return function () {
                clearTimeout(timerId_1);
            };
        }
        return;
    }, [accessToken, internalAccessToken]);
};
