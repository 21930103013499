import * as types from "../actions/mainFilterActionsType";
export default function mainFilterActionReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case types.ADD_TO_FILTER_COLLECTION:
            var filterCollectionSets = new Map();
            var filterCollectionName = action.screen === "maintenanceEventSummary" ||
                action.screen === "maintenanceDaily"
                ? "maintenance"
                : "capacity";
            filterCollectionSets.set(filterCollectionName, action.array);
            return filterCollectionSets;
            break;
        case types.DELETE_FROM_FILTER_COLLECTION:
            return {};
            break;
        default:
            return state;
            break;
    }
}
