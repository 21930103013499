import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Collapsible from "react-collapsible";
import SimpleBar from "simplebar-react";
import "./mainMenu.css";
import "font-awesome/css/font-awesome.min.css";
import { TreeView } from "@progress/kendo-react-treeview";
import "@progress/kendo-react-animation";
import "../../../node_modules/simplebar/dist/simplebar.min.css";
var mainMenu = /** @class */ (function (_super) {
    __extends(mainMenu, _super);
    function mainMenu(props, context) {
        var _this = _super.call(this, props, context) || this;
        //Function to perform menu search on input event
        _this.handleSearch = function (e) {
            var value = e.target.value;
            var newData = _this.search(_this.menu, value);
            _this.setState({ treeData: newData });
        };
        _this.search = function (genevaArray, term) {
            return genevaArray.reduce(function (acc, item) {
                if (_this.contains(item.genevaMenu.title, term)) {
                    acc.push(item);
                }
                else if (item.items && item.items.length > 0) {
                    var newItems = _this.search(item.items, term);
                    if (newItems && newItems.length > 0) {
                        acc.push({
                            genevaMenu: {
                                id: item.genevaMenu.id,
                                uniqueKey: item.genevaMenu.uniqueKey,
                                title: item.genevaMenu.title,
                                component_path: item.genevaMenu.component_path
                            },
                            items: newItems,
                            expanded: true
                        });
                    }
                }
                return acc;
            }, []);
        };
        //Function to check , is search item exist in menu list
        _this.contains = function (text, term) {
            return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
        };
        _this.onExpandChange = function (event) {
            event.item.expanded = !event.item.expanded;
            _this.forceUpdate();
        };
        // OnTreeViewItemClicked = event => {
        //   this.props.itemOnClick();
        // };
        _this.onItemClick = function (event) {
            event.item.selected = !event.item.selected;
            _this.forceUpdate();
        };
        _this.menu = _this.props.subMenuStructure;
        // this.programList = ["Program 2", "program 3", "Program 4", "Program 5"];
        _this.state = {
            treeData: _this.props.subMenuStructure
        };
        return _this;
    }
    mainMenu.prototype.render = function () {
        var navlinkStyle = {
            textDecoration: "none",
            font: "Open Sans",
            color: "#D9D9D9"
        };
        return (_jsxs(React.Fragment, { children: [_jsx(Dropdown.Header, __assign({ className: "program-header-title" }, { children: _jsx("b", { children: this.props.title }) })), _jsxs(Collapsible, __assign({ trigger: _jsx(Dropdown.Header, __assign({ className: "color-bg program-header-spacing" }, { children: _jsx("b", { children: this.props.caption }) })), open: true }, { children: [_jsxs("div", __assign({ className: "searchBox mt-3 ml-3 mr-3" }, { children: [_jsx("span", { className: "fa fa-search fa-sm spanBox ml-3", id: "spanicon", style: { fontSize: "15px" } }), _jsx("input", { className: "k-textbox inpuBox w-100", style: { top: "0px" }, id: "input-" +
                                        this.props.searchDisplay.toLowerCase().replace(" ", "_"), onChange: this.handleSearch, placeholder: "Search in " + this.props.searchDisplay, autoComplete: "off" })] })), _jsx(SimpleBar, __assign({ style: { height: "820px", border: "0px" } }, { children: _jsx(TreeView, { data: this.state.treeData, textField: "genevaMenu.title", className: "treeView", expandIcons: true, onExpandChange: this.onExpandChange, onItemClick: this.itemOnClick, itemRender: function (props) { return [
                                    _jsx("div", { children: _jsx(NavLink, __assign({ to: props.item.genevaMenu.component_path, style: navlinkStyle }, { children: props.item.genevaMenu.title.substr(0, 45 - 1) +
                                                (props.item.genevaMenu.title.length > 45 ? "..." : "") }), props.item.genevaMenu.uniqueKey) }, props.item.genevaMenu.uniqueKey)
                                ]; } }) }))] }))] }));
    };
    return mainMenu;
}(Component));
export default mainMenu;
