import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from "react";
import IncrCapacityView from "./incrCapacityView";
import LeftNavPanel from "../../../../../commons/tools/leftNavPanel";
var toggle = true;
var IncrementalView = React.forwardRef(function (props, ref) { return (_jsx(IncrCapacityView, __assign({ ref: ref }, props))); });
var DilyMonthlyQuarterlyDataGrid = /** @class */ (function (_super) {
    __extends(DilyMonthlyQuarterlyDataGrid, _super);
    function DilyMonthlyQuarterlyDataGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.inputRefs = [];
        _this.setRef = function (ref) {
            _this.inputRefs.push(ref);
        };
        _this.clickHandler = function (id) {
            _this.toggle = false;
        };
        _this.setToggle = function (bool) {
            toggle = !toggle;
            //this.setState({ toggle: bool });
        };
        _this.componentDidMount = function () {
            document.getElementById("capacity-increment-data-area").style.width = "82%";
            document.getElementById("capacity-increment-data-area").style.marginLeft =
                "22px";
        };
        _this.getSourceOnRefresh = function (assetId) {
            var loadedData = _this.state.data;
            var index = loadedData.findIndex(function (obj) { return obj.assetId === assetId; });
            if (index >= 0) {
                _this.IncrCapacityView[index].getSourceOnRefresh();
            }
        };
        _this.setRefIncr = function (ref) {
            _this.IncrCapacityView.push(ref);
        };
        _this.state = {
            expOn: true,
            dailyOn: true,
            monthlyOn: false,
            quarterlyOn: false,
            data: _this.props.data,
            toggle: true,
        };
        _this.toggle = true;
        _this.IncrCapacityView = [];
        return _this;
    }
    DilyMonthlyQuarterlyDataGrid.prototype.render = function () {
        var _this = this;
        var data = this.state.data;
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ id: "list-element", className: "list-element" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "pull-left position-relative" }, { children: _jsx(LeftNavPanel, { data: data, divRefs: this.inputRefs }) })), _jsx("div", __assign({ id: "capacity-increment-data-area", className: "pull-right", style: {
                                height: window.innerHeight - 300,
                                overflow: "hidden",
                            } }, { children: data.map(function (parent, id) { return (_jsx("div", __assign({ id: parent.assetName.replace(" ", "_").toLowerCase(), ref: _this.setRef, className: "height_set" }, { children: _jsx("div", { children: _jsx(IncrementalView, __assign({ ref: _this.setRefIncr, 
                                        // key={"incrCV-" + this.getRandom()}
                                        paramsForAssetFilter: _this.props.paramsForAssetFilter }, _this.state, { Parent: parent, index: id, countryId: parent.countryId, country: parent.countryName, city: parent.cityName, cityId: parent.cityId, owner: parent.ownerName, ownerId: parent.ownerId, assetId: parent.assetId, assetName: parent.assetName, assettype: parent.assetTypeName, assettypeId: parent.assetTypeId, onCapacitySelect: _this.props.onCapacitySelect, randomParent: "rp-" + _this.getRandom(), limitForHighlight: _this.props.limitForHighlight, baseProvider: _this.props.baseProvider })) }) }), parent.id)); }) }))] })) })) }));
    };
    return DilyMonthlyQuarterlyDataGrid;
}(Component));
export default DilyMonthlyQuarterlyDataGrid;
