var _a;
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
var browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var ai = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: (_a = {},
            _a[reactPlugin.identifier] = { history: browserHistory },
            _a)
    }
});
ai.loadAppInsights();
export default (function (Component) { return withAITracking(reactPlugin, Component); });
export var appInsights = ai.appInsights;
