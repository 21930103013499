import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import Breadcrumb from "../breadcrumb/breadcrumb";
//import FeedBack from "@sede-x/geneva-component-library-feedback";
import Settings from "./settings";
import Notification from "./notification";
import Profile from "./profile";
import Search from "./search";
import { Navbar } from "react-bootstrap";
import "./topBarMenu.css";
import Feedback from "react-bootstrap/Feedback";
var topBarMenu = /** @class */ (function (_super) {
    __extends(topBarMenu, _super);
    function topBarMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    topBarMenu.prototype.render = function () {
        return (_jsx(React.Fragment, { children: _jsx("div", __assign({ className: "topbar-menu", style: {
                    position: "fixed",
                    width: "100%",
                    height: "45px",
                    width: "97.2%",
                } }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "topbar-menu-breadcrumbs col-sm-8" }, { children: _jsx(Breadcrumb, {}) })), _jsx("div", __assign({ className: "topbar-menu-user-tray col-sm-4" }, { children: _jsx(Navbar, { children: _jsx(Navbar.Collapse, { className: "justify-content-end" }) }) }))] })) })) }));
    };
    return topBarMenu;
}(Component));
export default topBarMenu;
