import * as types from "../actions/mainFilterActionsType";
export var addSelectedElementToGlobalArray = function (object, screen) { return function (dispatch) {
    if (Object.keys(object).length > 0 && screen) {
        dispatch({
            type: types.ADD_TO_FILTER_COLLECTION,
            screen: screen,
            array: object,
        });
    }
}; };
export var removeSelectedElementToGlobalArray = function () { return function (dispatch) {
    dispatch({
        type: types.DELETE_FROM_FILTER_COLLECTION,
    });
}; };
