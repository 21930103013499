import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from "react";
import { Popup } from "@progress/kendo-react-popup";
import VersionView from "./versionView";
import { getSourceFilter } from "../../../../../../services/urlService.jsx";
function VersionWindow(props) {
    var _a = useState([]), versionOrder = _a[0], setVersionOrder = _a[1];
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var _c = useState(false), versionOn = _c[0], setVersionOn = _c[1];
    var anchor = useRef(null);
    function handleToggale() {
        setShow(!show);
    }
    function dateFormatter(item) {
        if (item.versionEffectiveDate !== null && item.versionEffectiveDate) {
            var timeSplit = item.versionEffectiveDate.split(".");
            item.versionEffectiveDate = new Date(timeSplit[0]);
        }
        return item;
    }
    useEffect(function (e) {
        var capacityPath = "AssetUnitCapacities/" + props.unitCapacityId + "/versions";
        var maintanencePath = "AssetUnitEvents/" + props.unitEventId + "/versions";
        var path = props.capacity ? capacityPath : maintanencePath;
        getSourceFilter(path).then(function (returnData) {
            if (typeof returnData !== "undefined" && returnData.data.length > 0) {
                setVersionOrder(returnData.data.map(dateFormatter));
            }
        });
    }, []);
    return (_jsxs("div", { children: [_jsx("button", __assign({ ref: function (button) {
                    anchor.current = button;
                }, onClick: handleToggale, style: { color: "#A6A6A6", textDecoration: "underline" } }, { children: "Versions" })), _jsx("div", { children: _jsx(Popup, __assign({ className: "version-popup", anchor: anchor.current, show: show, popupclassName: "popup-content-version" }, { children: _jsx(VersionView, { onHandleToggale: handleToggale, versionOrder: versionOrder, capacity: props.capacity }) })) }), show && _jsx("div", { className: "k-overlay" })] }));
}
export default VersionWindow;
