import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component, useEffect } from "react";
import "./capacity.css";
import { getSourceData } from "../../../../../services/urlService.jsx";
import DilyMonthlyQuarterlyDataGrid from "./dilyMonthlyQuarterlyDataGrid";
var incrementalCapacityScreen = /** @class */ (function (_super) {
    __extends(incrementalCapacityScreen, _super);
    function incrementalCapacityScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.componentDidMount = function () {
            _this.setState({ loader: !_this.state.loader });
            _this.setState({
                incrCapacityIntialData: _this.props.data,
                loader: !_this.state.loader,
                key: _this.getRandom(),
            });
        };
        //Random function
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.getSourceOnRefresh = function (assetId) {
            _this.DilyMonthlyQuarterlyDataGrid.getSourceOnRefresh(assetId);
        };
        _this.state = {
            incrCapacityIntialData: [],
            loader: false,
            key: _this.getRandom(),
        };
        return _this;
    }
    incrementalCapacityScreen.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        if (nextProps.assetFilterParams === this.props.assetFilterParams &&
            nextProps.baseProvider === null) {
            if (nextState.incrCapacityIntialData === this.state.incrCapacityIntialData) {
                return false;
            }
            else {
                return true;
            }
        }
        return true;
    };
    incrementalCapacityScreen.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: _jsx("div", __assign({ className: "container-fluid m-0", id: "capacityscreen" }, { children: this.state.incrCapacityIntialData.length > 0 ? (_jsx(DilyMonthlyQuarterlyDataGrid, { ref: function (ref) { return (_this.DilyMonthlyQuarterlyDataGrid = ref); }, paramsForAssetFilter: this.props.assetFilterParams, data: this.state.incrCapacityIntialData, onCapacitySelect: this.props.onCapacitySelect, limitForHighlight: this.props.limitForHighlight, baseProvider: this.props.baseProvider }, this.state.key)) : (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center" }, { children: [_jsx("img", { alt: "geneva-watermark-logo", src: require("../../../../../assets/images/watermarkLogo.png") }), _jsx("h3", __assign({ className: "mt-2", style: { color: "#595959" } }, { children: "No data available, Please reselect filters." }))] })) }))) })) }));
    };
    return incrementalCapacityScreen;
}(Component));
export default incrementalCapacityScreen;
