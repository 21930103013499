import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import _ from "lodash";
import SimpleBar from "simplebar-react";
var MqyView = /** @class */ (function (_super) {
    __extends(MqyView, _super);
    function MqyView(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.windowFormator = function (e) {
            var currentWindowHeightSet = parseInt(window.innerHeight) - parseInt(275);
            _this.setState({ windowHeight: currentWindowHeightSet });
        };
        _this.absSubtract = function (schema1, schema2) {
            var ret = {};
            for (var key in schema1) {
                if (schema1.hasOwnProperty(key) && schema2.hasOwnProperty(key)) {
                    var obj = schema1[key];
                    var obj2 = schema2[key];
                    if (typeof parseInt(obj) === "number" &&
                        !isNaN(parseInt(obj)) &&
                        typeof parseInt(obj2) === "number" &&
                        !isNaN(parseInt(obj2))) {
                        ret[key] = parseInt(obj) - parseInt(obj2);
                    }
                    else {
                        if (typeof obj === "object" && typeof obj2 === "object") {
                            ret[key] = _this.calculateDeltaBetweenViews(obj, obj2);
                        }
                        else {
                            ret[key] = obj;
                        }
                    }
                }
            }
            return ret;
        };
        _this.absoluteDataOnState = function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setState({ rawdata: JSON.parse(this.initialState) })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.valueHighlighter(null, false)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.valueHighlighter = function (highlightValue, add, commonProvider) {
            if (highlightValue === void 0) { highlightValue = null; }
            if (add === void 0) { add = true; }
            var elems = document.getElementsByClassName("data-td");
            for (var i = 0; i < elems.length; i += 1) {
                if (!elems[i].classList.contains(commonProvider + "-td")) {
                    if (add) {
                        elems[i].classList.remove("bg-green");
                        elems[i].classList.remove("bg-red");
                        if (parseInt(elems[i].innerText) > parseInt(highlightValue) &&
                            parseInt(elems[i].innerText) !== 0) {
                            elems[i].classList.add("bg-green");
                        }
                        else if (parseInt(elems[i].innerText) < 0) {
                            elems[i].classList.add("bg-red");
                        }
                    }
                    else {
                        elems[i].classList.remove("bg-green");
                        elems[i].classList.remove("bg-red");
                    }
                }
            }
        };
        _this.deltaSelectOnMqyViews = function (commonProvider, highlightValue, calculatingUnits) { return __awaiter(_this, void 0, void 0, function () {
            var rawdata;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.setState({ rawdata: JSON.parse(this.initialState) })];
                    case 1:
                        _a.sent();
                        rawdata = __assign({}, this.state.rawdata);
                        return [4 /*yield*/, Object.keys(this.state.rawdata).map(function (year, index) {
                                var currentLoopedyear = _this.state.rawdata[year];
                                Object.entries(currentLoopedyear).map(function (mqyEntities, index) {
                                    Object.entries(mqyEntities[1]).map(function (calculatingEntities, index) {
                                        if (calculatingEntities[0] !== commonProvider &&
                                            calculatingEntities[0] !== "providers") {
                                            var diffVal = _this.absSubtract(_this.state.rawdata[year]["M"][commonProvider], _this.state.rawdata[year]["M"][calculatingEntities[0]]);
                                            _.set(rawdata, [year, "M", calculatingEntities[0]], diffVal);
                                        }
                                        return true;
                                    });
                                    return true;
                                });
                                return true;
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, Object.keys(this.state.rawdata).map(function (year, index) {
                                var currentLoopedyear = _this.state.rawdata[year];
                                Object.entries(currentLoopedyear).map(function (mqyEntities, index) {
                                    Object.entries(mqyEntities[1]).map(function (calculatingEntities, index) {
                                        if (calculatingEntities[0] !== commonProvider &&
                                            calculatingEntities[0] !== "providers") {
                                            var diffVal = _this.absSubtract(_this.state.rawdata[year]["Q"][commonProvider], _this.state.rawdata[year]["Q"][calculatingEntities[0]]);
                                            _.set(rawdata, [year, "Q", calculatingEntities[0]], diffVal);
                                        }
                                        return true;
                                    });
                                    return true;
                                });
                                return true;
                            })];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, Object.keys(this.state.rawdata).map(function (year, index) {
                                var currentLoopedyear = _this.state.rawdata[year];
                                Object.entries(currentLoopedyear).map(function (mqyEntities, index) {
                                    Object.entries(mqyEntities[1]).map(function (calculatingEntities, index) {
                                        if (calculatingEntities[0] !== commonProvider &&
                                            calculatingEntities[0] !== "providers") {
                                            var diffVal = _this.absSubtract(_this.state.rawdata[year]["Y"][commonProvider], _this.state.rawdata[year]["Y"][calculatingEntities[0]]);
                                            _.set(rawdata, [year, "Y", calculatingEntities[0]], diffVal);
                                        }
                                        return true;
                                    });
                                    return true;
                                });
                                return true;
                            })];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.setState({ rawdata: rawdata })];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.valueHighlighter(highlightValue, true, commonProvider)];
                    case 6:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.componentDidMount = function (e) {
            if (typeof _this.props.ondeltaset !== "undefined") {
                var deltaBetweenViewsSet = _this.props.ondeltaset.state.apiQuery.get("delta_between_views");
                if (typeof deltaBetweenViewsSet !== "undefined") {
                    if (deltaBetweenViewsSet.length > 0) {
                        _this.deltaSelectOnMqyViews(deltaBetweenViewsSet[0], deltaBetweenViewsSet[1], deltaBetweenViewsSet[2]);
                    }
                }
            }
            var elems = document.getElementsByClassName("td-provider-data");
            for (var i = 0; i < elems.length; i += 1) {
                var clientHeightToBeAssigned = elems[i].querySelectorAll("tr")[1]
                    .clientHeight;
                var trLength = parseInt(elems[i].querySelectorAll("tr").length) - parseInt(1);
                var widthToBeAssigned = parseInt(parseInt(trLength) * parseInt(clientHeightToBeAssigned)) +
                    parseInt(10);
                elems[i].parentNode.parentNode
                    .querySelector("td:first-child")
                    .querySelector("div").style.width = widthToBeAssigned + "px";
            }
            _this.windowFormator();
        };
        _this.state = {
            rawdata: {
                2018: {
                    M: {
                        iir: {
                            jan: "100",
                            feb: "125",
                            mar: "130",
                            apr: "346",
                            may: "211",
                            jun: "341",
                            jul: "674",
                            aug: "125",
                            sep: "600",
                            oct: "45",
                            nov: "450",
                            dec: "700"
                        },
                        baseline: {
                            jan: "75",
                            feb: "111",
                            mar: "10",
                            apr: "26",
                            may: "789",
                            jun: "347",
                            jul: "678",
                            aug: "345",
                            sep: "231",
                            oct: "243",
                            nov: "567",
                            dec: "876"
                        },
                        pm: {
                            jan: "23",
                            feb: "67",
                            mar: "89",
                            apr: "456",
                            may: "689",
                            jun: "234",
                            jul: "124",
                            aug: "123",
                            sep: "75",
                            oct: "54",
                            nov: "45",
                            dec: "36"
                        }
                    },
                    Q: {
                        iir: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        baseline: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        pm: { q1: "150", q2: "150", q3: "150", q4: "150" }
                    },
                    Y: {
                        iir: { y: "150" },
                        baseline: { y: "150" },
                        pm: { y: "150" }
                    }
                },
                2019: {
                    M: {
                        iir: {
                            jan: "100",
                            feb: "125",
                            mar: "130",
                            apr: "346",
                            may: "211",
                            jun: "341",
                            jul: "674",
                            aug: "125",
                            sep: "600",
                            oct: "45",
                            nov: "450",
                            dec: "700"
                        },
                        baseline: {
                            jan: "75",
                            feb: "111",
                            mar: "10",
                            apr: "26",
                            may: "789",
                            jun: "347",
                            jul: "678",
                            aug: "345",
                            sep: "231",
                            oct: "243",
                            nov: "567",
                            dec: "876"
                        },
                        pm: {
                            jan: "23",
                            feb: "67",
                            mar: "89",
                            apr: "456",
                            may: "689",
                            jun: "234",
                            jul: "124",
                            aug: "123",
                            sep: "75",
                            oct: "54",
                            nov: "45",
                            dec: "36"
                        }
                    },
                    Q: {
                        iir: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        baseline: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        pm: { q1: "150", q2: "150", q3: "150", q4: "150" }
                    },
                    Y: {
                        iir: { y: "150" },
                        baseline: { y: "150" },
                        pm: { y: "150" }
                    }
                },
                2020: {
                    M: {
                        iir: {
                            jan: "100",
                            feb: "125",
                            mar: "130",
                            apr: "346",
                            may: "211",
                            jun: "341",
                            jul: "674",
                            aug: "125",
                            sep: "600",
                            oct: "45",
                            nov: "450",
                            dec: "700"
                        },
                        baseline: {
                            jan: "75",
                            feb: "111",
                            mar: "10",
                            apr: "26",
                            may: "789",
                            jun: "347",
                            jul: "678",
                            aug: "345",
                            sep: "231",
                            oct: "243",
                            nov: "567",
                            dec: "876"
                        },
                        pm: {
                            jan: "23",
                            feb: "67",
                            mar: "89",
                            apr: "456",
                            may: "689",
                            jun: "234",
                            jul: "124",
                            aug: "123",
                            sep: "75",
                            oct: "54",
                            nov: "45",
                            dec: "36"
                        }
                    },
                    Q: {
                        iir: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        baseline: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        pm: { q1: "150", q2: "150", q3: "150", q4: "150" }
                    },
                    Y: {
                        iir: { y: "150" },
                        baseline: { y: "150" },
                        pm: { y: "150" }
                    }
                },
                2021: {
                    M: {
                        iir: {
                            jan: "100",
                            feb: "125",
                            mar: "130",
                            apr: "346",
                            may: "211",
                            jun: "341",
                            jul: "674",
                            aug: "125",
                            sep: "600",
                            oct: "45",
                            nov: "450",
                            dec: "700"
                        },
                        baseline: {
                            jan: "75",
                            feb: "111",
                            mar: "10",
                            apr: "26",
                            may: "789",
                            jun: "347",
                            jul: "678",
                            aug: "345",
                            sep: "231",
                            oct: "243",
                            nov: "567",
                            dec: "876"
                        },
                        pm: {
                            jan: "23",
                            feb: "67",
                            mar: "89",
                            apr: "456",
                            may: "689",
                            jun: "234",
                            jul: "124",
                            aug: "123",
                            sep: "75",
                            oct: "54",
                            nov: "45",
                            dec: "36"
                        }
                    },
                    Q: {
                        iir: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        baseline: { q1: "150", q2: "150", q3: "150", q4: "150" },
                        pm: { q1: "150", q2: "150", q3: "150", q4: "150" }
                    },
                    Y: {
                        iir: { y: "150" },
                        baseline: { y: "150" },
                        pm: { y: "150" }
                    }
                }
            },
            windowHeight: ""
        };
        _this.deltaSelectOnMqyViews = _this.deltaSelectOnMqyViews.bind(_this);
        _this.initialState = JSON.stringify(_this.state.rawdata);
        return _this;
    }
    MqyView.prototype.render = function () {
        var data = this.state.rawdata;
        return (_jsx("div", __assign({ className: "mt-3" }, { children: _jsx(SimpleBar, __assign({ style: { height: this.state.windowHeight + "px" } }, { children: _jsxs("div", __assign({ className: "mqy-view row" }, { children: [_jsx("div", __assign({ className: "col-sm-7" }, { children: Object.entries(data).map(function (year, index) {
                                var monthCollection = new Map();
                                var currentLoopedYear = year[0];
                                return (_jsx("table", __assign({ className: "table no-bgc p-0 mb-2" }, { children: _jsx("tbody", __assign({ className: "actual-data" }, { children: _jsxs("tr", { children: [_jsx("td", __assign({ className: "p-0 mr-2 month" }, { children: _jsx("div", __assign({ className: "text-h pull-left" }, { children: _jsx("span", { children: currentLoopedYear }) }), index) }), index), _jsx("td", __assign({ className: "td-provider-data" }, { children: _jsx("table", __assign({ className: "table  p-0 m-0" }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", __assign({ className: "text-center data-title" }, { children: "\u00A0" }), index), Object.entries(year[1].M).map(function (provider, index) {
                                                                            Object.keys(provider[1]).map(function (month, index) {
                                                                                monthCollection.set(index, month);
                                                                                return true;
                                                                            });
                                                                            return true;
                                                                        }), Array.from(monthCollection.values()).map(function (month, index) {
                                                                            return (_jsx("td", __assign({ className: "text-center day-month" }, { children: month
                                                                                    .split("_")
                                                                                    .map(function (daymonth, index) { return (_jsx("div", { children: _.capitalize(daymonth) + "" }, index)); }) }), index));
                                                                        })] }, index), Object.entries(year[1].M).map(function (provider, index) {
                                                                    var currentProviderInLoop = provider[0];
                                                                    return (_jsxs("tr", __assign({ className: "data-tr mb-3 " +
                                                                            _.lowerCase(provider[0]).replace(/\s+/g, "_") +
                                                                            " tr" }, { children: [_jsx("td", __assign({ className: "border border rounded data data-title" }, { children: provider[0].length > 4
                                                                                    ? _.capitalize(provider[0])
                                                                                    : _.upperCase(provider[0]) }), index), Object.values(provider[1]).map(function (value, index) {
                                                                                return (_jsx("td", __assign({ className: "data-td p-0 border border rounded data text-center data-" +
                                                                                        currentProviderInLoop +
                                                                                        "-td" +
                                                                                        " data-" +
                                                                                        currentProviderInLoop +
                                                                                        "-" +
                                                                                        index }, { children: value }), index));
                                                                            })] }), index));
                                                                })] }) })) }))] }) }), index) }), index));
                            }) })), _jsx("div", __assign({ className: "col-sm-4" }, { children: Object.entries(data).map(function (year, index) {
                                return (_jsx(React.Fragment, { children: _jsx("table", __assign({ className: index > 0
                                            ? "table no-bgc mt-4 mb-2"
                                            : "table no-bgc mt-2 mb-2" }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", __assign({ className: " text-center" }, { children: "Q1" }), index), _jsx("td", __assign({ className: " text-center" }, { children: "Q2" }), index), _jsx("td", __assign({ className: " text-center" }, { children: "Q3" }), index), _jsx("td", __assign({ className: " text-center" }, { children: "Q4" }), index)] }, index), Object.entries(year[1].Q).map(function (provider, index) {
                                                    var currentProviderInLoop = provider[0];
                                                    return (_jsx("tr", __assign({ className: "data-tr border rounded data mb-3 " +
                                                            _.lowerCase(provider[0]).replace(/\s+/g, "_") +
                                                            " tr" }, { children: Object.values(provider[1]).map(function (qData, index) {
                                                            return (_jsx("td", __assign({ className: "data-td p-0 border border rounded data text-center data-" +
                                                                    currentProviderInLoop +
                                                                    "-td" +
                                                                    " data-" +
                                                                    currentProviderInLoop +
                                                                    "-" +
                                                                    index }, { children: qData }), index));
                                                        }) }), index));
                                                })] }, index) }), index) }, index));
                            }) })), _jsx("div", __assign({ className: "col-sm-1" }, { children: Object.entries(data).map(function (year, index) {
                                return (_jsx(React.Fragment, { children: _jsx("table", __assign({ className: index > 0
                                            ? "table no-bgc mt-4 mb-2"
                                            : "table no-bgc mt-2 mb-2" }, { children: _jsxs("tbody", { children: [_jsx("tr", { children: _jsx("td", __assign({ className: " text-center" }, { children: "Yearly" }), index) }, index), Object.entries(year[1].Y).map(function (provider, index) {
                                                    var currentProviderInLoop = provider[0];
                                                    return (_jsx("tr", __assign({ className: "data-tr mb-3 border rounded data " +
                                                            _.lowerCase(provider[0]).replace(/\s+/g, "_") +
                                                            " tr" }, { children: Object.values(provider[1]).map(function (qData, index) {
                                                            return (_jsx("td", __assign({ className: "data-td p-0 border border rounded data text-center data-" +
                                                                    currentProviderInLoop +
                                                                    "-td" +
                                                                    " data-" +
                                                                    currentProviderInLoop +
                                                                    "-" +
                                                                    index }, { children: qData }), index));
                                                        }) }), index));
                                                })] }, index) }), index) }, index));
                            }) }))] })) })) })));
    };
    return MqyView;
}(Component));
export default MqyView;
