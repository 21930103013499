import http from "./httpService";
// import dataServiceTerminalType from "../components/globalReferenceData/referenceData/dataProviderService/dataServiceTerminalType";
import { getAccessToken } from '../app/common/utils/access-token/get-access-token';
//let baseUrl = process.env.REACT_APP_SUPPLY_URL;//process.env.REACT_APP_BASE_URL;
var baseSupplyUrl = process.env.REACT_APP_SUPPLY_URL;
var endpointProductionEdit = baseSupplyUrl + "/ProductionAddEdit";
var endpointProductionGradeEdit = baseSupplyUrl + "/ProductionGradeAddEdit";
var endpointProductionGrade = baseSupplyUrl + "/ProductionGrade";
var endpointMaterialGrade = baseSupplyUrl + "/MaterialGrade";
var endpointPromoteToBaseline = baseSupplyUrl + "/PromoteToBaseline";
var endpointStorageTank = baseSupplyUrl + "/StorageTank";
var endpointMaterialType = baseSupplyUrl + "/MaterialType?tc=3";
var endpointTankOperationalStatusType = baseSupplyUrl + "/TankOperationalStatusType";
var endpointTankCommercialityType = baseSupplyUrl + "/TankCommercialityType";
var endpointScenarioType = baseSupplyUrl + "/ScenarioType";
var endpointScenarioListing = baseSupplyUrl + "/ScenarioListing";
var endpointScenarioCreate = baseSupplyUrl + "/ScenarioCreate";
var endpointScenarioUpload = baseSupplyUrl + "/ScenarioUpload";
var endpointScenarioDelete = baseSupplyUrl + "/ScenarioCreate?ScenarioKey=";
var endpointScenarioPublish = baseSupplyUrl + "/ScenarioCreate?RevertPublishStatus=N&&ScenarioKey=";
var endpointScenarioRevert = baseSupplyUrl + "/ScenarioCreate?RevertPublishStatus=Y&&ScenarioKey=";
var endpointScenarioCopy = baseSupplyUrl + "/ScenarioCopy";
var urlArray = {
    productionAddEdit: endpointProductionEdit,
    productionGradeAddEdit: endpointProductionGradeEdit,
    productionGrade: endpointProductionGrade,
    storageTank: endpointStorageTank,
    materialGrade: endpointMaterialGrade,
    materialTypeNm: endpointMaterialType,
    tankOperationalStatusTypeDesc: endpointTankOperationalStatusType,
    promoteToBaseline: endpointPromoteToBaseline,
    tankCommercialityTypeDesc: endpointTankCommercialityType,
    scenarioType: endpointScenarioType,
    scenarioListing: endpointScenarioListing,
    scenarioCreate: endpointScenarioCreate,
    scenarioUpload: endpointScenarioUpload,
    scenarioDelete: endpointScenarioDelete,
    scenarioPublish: endpointScenarioPublish,
    scenarioRevert: endpointScenarioRevert,
    scenarioCopy: endpointScenarioCopy,
};
var getAxiosConfig = function (isSupplyUrl) {
    var axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
    };
    var accessToken = getAccessToken();
    if (accessToken && isSupplyUrl) {
        // @ts-ignore: authorization header added only with access token available
        axiosConfig.headers['Authorization'] = "Bearer ".concat(accessToken);
    }
    return axiosConfig;
};
var isSupplyUrl = function (url) {
    if (url.includes(baseSupplyUrl))
        return true;
    else
        return false;
};
export function getSourceData(screenType) {
    return http
        .get(urlArray[screenType], getAxiosConfig(isSupplyUrl(urlArray[screenType])))
        .then(function (result) {
        if (result.data.length > 0) {
            return result;
        }
        else {
            return undefined;
        }
    })
        .catch(function (error) {
        return undefined;
    });
}
export function getSourceFilter(path, supply) {
    if (supply === void 0) { supply = null; }
    var url;
    if (supply == null) {
        if (path.split("?")[0] == "MaterialGrade")
            url = baseSupplyUrl + "/" + path;
        else if (path.split("?")[0] == "scenarioListing")
            url = baseSupplyUrl + "/" + path;
        else
            url = baseSupplyUrl + "/" + path;
    }
    else {
        url = baseSupplyUrl + "/" + supply + path;
    }
    return http
        .get(url, getAxiosConfig(isSupplyUrl(url)))
        .then(function (result) {
        var dataType = typeof result.data;
        switch (dataType) {
            case "array":
                if (result.data.length > 0) {
                    return result;
                }
                else {
                    return undefined;
                }
                break;
            case "object":
                if (Object.keys(result.data).length > 0) {
                    return result;
                }
                else {
                    return undefined;
                }
                break;
            default:
                break;
        }
    })
        .catch(function (error) {
        return undefined;
    });
}
export function getSourceEventListing(path) {
    return http
        .get(baseSupplyUrl + "/" + path, getAxiosConfig(false))
        .then(function (result) {
        if (typeof result !== "undefined" && typeof result.data !== "undefined") {
            if (result.data.length > 0) {
                return result;
            }
            else {
                return undefined;
            }
        }
    })
        .catch(function (error) {
        return;
    });
}
export function slackApi(url, data) {
    return http
        .post(url, JSON.stringify(data))
        .then(function (result) {
        if (typeof result !== "undefined" && typeof result.data !== "undefined") {
            if (result.data.length > 0) {
                return result;
            }
            else {
                return undefined;
            }
        }
    })
        .catch(function (error) {
        return;
    });
}
export function getSourceDataByID(screenType, id) {
    return http
        .get(urlArray[screenType] + "/" + id, getAxiosConfig(isSupplyUrl(urlArray[screenType])))
        .then(function (result) {
        if (result.data.length > 0) {
            return result;
        }
        else {
            var retunObj = { data: "empty" };
            return retunObj;
        }
    })
        .catch(function (error) {
        return;
    });
}
export function editSourceData(data, id, screenType) {
    if (id === void 0) { id = null; }
    if (id === null) {
        var url = urlArray[screenType];
        if (screenType == "scenarioPublish") {
            url = urlArray[screenType] + data;
        }
        if (screenType == "scenarioRevert") {
            url = url = urlArray[screenType] + data;
        }
        return http
            .put(url, data, getAxiosConfig(isSupplyUrl(url)))
            .then(function (result) {
            return result;
        })
            .catch(function (error) {
            return;
        });
    }
    else {
        var url = urlArray[screenType] + "/" + id;
        return http
            .put(url, data, getAxiosConfig(isSupplyUrl(url)))
            .then(function (result) {
            return result;
        })
            .catch(function (error) {
            return;
        });
    }
}
export function promoteToBaseline(capacityKey, capacityVersionKey, screenType) {
    var url = urlArray[screenType] + "/" + capacityKey + "/" + capacityVersionKey;
    return http
        .put(url, 0, getAxiosConfig(isSupplyUrl(url)))
        .then(function (result) {
        return result;
    })
        .catch(function (error) {
        return;
    });
}
export function deleteSourceData(id, screenType) {
    var url = urlArray[screenType] + "/" + id;
    if (screenType == "scenarioDelete") {
        url = urlArray[screenType] + id;
    }
    return http
        .delete(url, getAxiosConfig(isSupplyUrl(url)))
        .then(function (result) {
        return result;
    })
        .catch(function (error) {
        console.error("Ouch " + error);
    });
}
export function saveSourceData(data, screenType) {
    var url = urlArray[screenType];
    return http
        .post(url, data, getAxiosConfig(isSupplyUrl(url)))
        .then(function (result) {
        return result;
    })
        .catch(function (error) {
        return;
    });
}
export function postSourceData(data, screenType, param, postaxiosConfig) {
    if (param === void 0) { param = null; }
    var url = urlArray[screenType];
    if (param != null) {
        url = url + param;
    }
    return http
        .post(url, data, postaxiosConfig)
        .then(function (result) {
        return result;
    })
        .catch(function (error) {
        return;
    });
}
