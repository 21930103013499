import { __assign, __awaiter, __extends, __generator } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import "../../../../../../node_modules/simplebar/dist/simplebar.min.css";
import { saveSourceData, getSourceFilter, } from "./../../../../../services/urlService";
import _ from "lodash";
import Spinner from "../../../../../commons/spinner";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import PromoteToBaselineButton from "./promoteToBaselineButton";
import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
var moment = require("moment");
var productionGradeView = /** @class */ (function (_super) {
    __extends(productionGradeView, _super);
    function productionGradeView(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.dataSelect = function (e) {
            _this.props.onDataSelect(e.currentTarget);
        };
        _this.anchor = null;
        _this.anchorAlign = {
            horizontal: "center",
            vertical: "bottom",
        };
        _this.popupAlign = {
            horizontal: "center",
            vertical: "top",
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.props.shouldRefresh &&
                            this.props.selectedGradeKey === this.props.gradeIndex &&
                            this.props.selectedCountryKey == this.props.countryIndex)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setState({ loader: true }, function (e) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    this.setState({
                                        loader: false,
                                    });
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        document.addEventListener("click", this.handleRightClick);
                        return [2 /*return*/];
                }
            });
        }); };
        /*  componentWillUnmount() {
          document.removeEventListener("click", this.handleClickOutside, true);
          document.removeEventListener("scroll", this.handleScroll, true);
        }
      
        handleScroll = () => {
          if (this.state.show) this.setState({ show: false });
        };
        handleClickOutside = (event) => {
          const domNode = ReactDOM.findDOMNode(this);
      
          if (!domNode || !domNode.contains(event.target)) {
            this.setState({
              show: false,
            });
          }
        };*/
        _this.handleRightClick = function (e) {
            if (_this.state.show)
                _this.setState({ show: false });
        };
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.clickText = function (e) {
            _this.setState({
                isSelected: true,
            });
        };
        _this.unclickText = function (e) {
            _this.setState({
                isSelected: false,
            });
            console.log(_this.state.dataToCopy);
        };
        _this.selectedText = function (e) {
            if (_this.state.isSelected) {
                var text = e.target.innerText;
                _this.state.dataToCopy.push(text);
            }
        };
        _this.handlePromoteMenu = function (e) {
            e.preventDefault();
            var selectMonth = e.currentTarget.attributes.selectmonth.value;
            var dates = moment.utc(selectMonth).toISOString(); //new Date(selectMonth).toISOString();
            _this.offSet = { left: e.clientX, top: e.clientY };
            _this.setState({
                show: true,
                month: dates,
                dataProviderToPromote: e.currentTarget.attributes.selectDataProvider.value,
            });
        };
        _this.copyToClipboard = function (e) {
            var selectedText = window.getSelection().toString();
            selectedText = selectedText.replaceAll("\n", "\t");
            var text = e.clipboardData.getData("text/plain");
            var clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;
            clipboardData.setData("text/plain", selectedText);
            console.log(text);
            e.preventDefault();
        };
        _this.onPromoteToBaselineClick = function (e) {
            var path = "promoteToBaseline";
            var apiParam = {
                country: _this.props.countryId,
                //materialProduct: this.props.selectedMaterialProduct,
                materialFamily: _this.props.selectedMaterialFamily,
                materialGroup: _this.props.selectedMaterialGroup,
                materialGrade: _this.props.grade,
                startMonth: _this.state.month,
                endMonth: _this.state.month,
                //dataProviderName: this.state.dataProviderToPromote,
            };
            _this.setState({
                show: false,
                spinner: true,
            }, function () {
                saveSourceData(apiParam, path).then(function (response) {
                    if (response !== undefined && response.status === 200) {
                        var refreshReturn = getSourceFilter(_this.props.applyUrl, "productionGrade").then(function (returnData) {
                            if (typeof returnData !== "undefined" &&
                                returnData.data.tableData.length > 0) {
                                _this.props.setSelectedData(returnData.data.tableData);
                                _this.props.setGraphData(returnData.data.graphData);
                                //this.props.setShouldRefresh(false);
                            }
                            else {
                                _this.setState({
                                    spinner: false,
                                });
                            }
                        });
                    }
                    else {
                        _this.setState({
                            spinner: false,
                        });
                    }
                });
            });
        };
        _this.state = {
            data: props.data,
            country: null,
            countryIndex: 0,
            limitForHighlight: null,
            supplyData: [],
            selectedData: [],
            expOn: true,
            isSelected: false,
            //numDp: props.data.quantityMonths[0].quantities.length,
            dataToCopy: [],
            show: false,
            month: null,
            dataProviderToPromote: null,
        };
        _this.handleEdit = _this.selectedText.bind(_this);
        _this.handleClick = _this.clickText.bind(_this);
        _this.handleUnclick = _this.unclickText.bind(_this);
        _this.handleCopy = _this.copyToClipboard.bind(_this);
        _this.handlePromoteClick = _this.handlePromoteMenu.bind(_this);
        return _this;
        // this.editClick = this.editClick.bind(this);
        // this.dataSelect = this.dataSelect.bind(this);
    }
    productionGradeView.prototype.render = function () {
        var _this = this;
        return (_jsxs(_Fragment, { children: [this.state.spinner && _jsx(Spinner, {}), _jsx("div", __assign({ id: "capacitycontainer" }, { children: _jsx("div", __assign({ className: "co-l-12 k-widget k-grid unit-sub-type", id: "capacityscreen" }, { children: _jsx("div", { children: _jsxs("table", { children: [_jsx("td", { children: _jsx("table", __assign({ style: { marginTop: "15px" } }, { children: _jsx("tbody", { children: this.state.data.quantityMonths[0].quantities.map(function (details, index) {
                                                    var dataProviderName;
                                                    var startMonth;
                                                    var endMonth;
                                                    var addEdit = false;
                                                    if (typeof _this.state.data.quantityMonths !==
                                                        "undefined") {
                                                        startMonth =
                                                            _this.state.data.quantityMonths[0].month;
                                                        var len = _this.state.data.quantityMonths.length - 1;
                                                        endMonth =
                                                            _this.state.data.quantityMonths[len].month;
                                                    }
                                                    if (index === 0) {
                                                        dataProviderName = "PREVIOUS BASELINE";
                                                        return false;
                                                    }
                                                    else if (index === 1) {
                                                        //dataProviderName = "Baseline";
                                                        if (details.dataProvider === null) {
                                                            dataProviderName = "SHELL-BASELINE";
                                                        }
                                                        else {
                                                            dataProviderName = details.dataProvider;
                                                        }
                                                        addEdit = true;
                                                    }
                                                    else {
                                                        dataProviderName = details.dataProvider;
                                                    }
                                                    return (_jsx("tr", { children: _jsx("td", __assign({ style: {
                                                                paddingBottom: "3px",
                                                                paddingRight: "10px",
                                                                textAlign: "right",
                                                                paddingTop: "3px",
                                                                // width: "50px",
                                                            } }, { children: _jsxs("div", { children: [dataProviderName === "BEST OF VENDOR" ? (_jsxs(_Fragment, { children: [" ", _jsx(PromoteToBaselineButton, { dataProviderName: dataProviderName, country: _this.props.countryId, materialGroup: _this.props.selectedMaterialGroup, 
                                                                                // materialProduct={
                                                                                //  this.props.selectedMaterialProduct
                                                                                // }
                                                                                materialFamily: _this.props.selectedMaterialFamily, materialGrade: _this.props.grade, startMonth: startMonth, endMonth: endMonth, setGraphData: _this.props.setGraphData, setSelectedData: _this.props.setSelectedData, setShouldRefresh: _this.props.setShouldRefresh, applyUrl: _this.props.applyUrl, path: "productionGrade" })] })) : (_jsx(_Fragment, { children: _jsx("button", __assign({ className: "k-link no-pointer ellipsis text-right" }, { children: dataProviderName })) })), !addEdit ? null : JSON.stringify(_this.props.selectedMaterialFamily) === JSON.stringify([9]) ? (_jsx(_Fragment, { children: _jsx("button", __assign({ "data-country-selected-id": _this.props.countryIndex, "data-grade-selected-id": _this.props.gradeIndex, onClick: _this.dataSelect }, { children: _jsx("i", { className: "fa fa-pencil" }) })) })) : null] }) })) }, "key-2-" + _this.getRandom()));
                                                }) }) })) }), _jsx("div", __assign({ id: "productionnumber" + this.props.grade, style: {
                                            overflowX: "auto",
                                            width: "1286px",
                                            // width: "91.7%",
                                        } }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: this.state.data.quantityMonths.map(function (monthData, index) {
                                                                        return (_jsx("th", __assign({ className: "k-header text-centre" }, { children: _jsx("div", __assign({ style: {
                                                                                    margin: "0 1px 1px 0",
                                                                                } }, { children: _jsx("button", __assign({ className: "k-link no pointer ellipsis text-centre" }, { children: monthData.month })) })) }), "key-1-" + _this.getRandom()));
                                                                    }) }) }) }) }) }), _jsx("tbody", { children: _jsx("tr", { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: this.state.data.quantityMonths[0].quantities.map(function (details, i) {
                                                                        if (i === 0)
                                                                            return false;
                                                                        return (_jsx("tr", __assign({ className: "k-header text-centre", style: { width: "50px" } }, { children: _this.state.data.quantityMonths.map(function (monthData, index) {
                                                                                var qty = 0.0;
                                                                                var bgStyle;
                                                                                var lockStatus = false;
                                                                                if (monthData.quantities[i]
                                                                                    .lockStatus === "Y") {
                                                                                    lockStatus = true;
                                                                                }
                                                                                else {
                                                                                    lockStatus = false;
                                                                                }
                                                                                if ((i === 0 || i === 1) &&
                                                                                    monthData.quantities[i]
                                                                                        .baselineStatus === null) {
                                                                                    qty = "-";
                                                                                }
                                                                                else {
                                                                                    qty = monthData.quantities[i]
                                                                                        .quantity
                                                                                        ? parseFloat(monthData.quantities[i]
                                                                                            .quantity).toFixed(0)
                                                                                        : monthData.quantities[i]
                                                                                            .quantity;
                                                                                    if (monthData.quantities[i]
                                                                                        .versionType === "B" ||
                                                                                        monthData.quantities[i]
                                                                                            .versionType === "V") {
                                                                                        if (monthData.quantities[i]
                                                                                            .status === "Actualization") {
                                                                                            bgStyle = 1;
                                                                                        }
                                                                                        else if (monthData.quantities[i]
                                                                                            .status === "Nowcasting") {
                                                                                            bgStyle = 2;
                                                                                        }
                                                                                        else if (monthData.quantities[i]
                                                                                            .status ===
                                                                                            "Forecasting-Next" ||
                                                                                            monthData.quantities[i]
                                                                                                .status ===
                                                                                                "Forecasting-Beyond") {
                                                                                            bgStyle = 3;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                return (_jsx("td", __assign({ 
                                                                                    // style={{ width: "50px" }}
                                                                                    className: "k-header" }, { children: _jsxs("div", __assign({ style: lockStatus
                                                                                            ? { margin: "0 1px 4px 0" }
                                                                                            : { margin: "0 1px 1px 0" }, onMouseMove: _this.handleEdit }, { children: [_jsxs("button", __assign({ className: "k-link no-pointer ellipsis", style: {
                                                                                                    backgroundColor: bgStyle === 1
                                                                                                        ? "#008000"
                                                                                                        : bgStyle === 2
                                                                                                            ? "#FFC300"
                                                                                                            : bgStyle === 3
                                                                                                                ? "#FF5733"
                                                                                                                : "#404040",
                                                                                                    // padding: "1px 1px",
                                                                                                }, selectmonth: monthData.month, selectDataProvider: monthData.quantities[i]
                                                                                                    .dataProvider, onMouseDown: _this.handleClick, onContextMenu: monthData.quantities[i]
                                                                                                    .versionType === "V"
                                                                                                    ? _this.handlePromoteMenu
                                                                                                    : null }, { children: [_jsx("i", { className: lockStatus
                                                                                                            ? "fa fa-lock"
                                                                                                            : "", style: lockStatus
                                                                                                            ? {
                                                                                                                color: "yellow",
                                                                                                                // position: "relative",
                                                                                                                left: "1px",
                                                                                                                //marginRight: "50px",
                                                                                                            }
                                                                                                            : {} }), _jsx("span", __assign({ style: {
                                                                                                            display: "inline-block",
                                                                                                            // width: "50%",
                                                                                                            float: "right",
                                                                                                            //  paddingBottom: "2px",
                                                                                                            //textAligh: "right",
                                                                                                        }, onCopy: _this.handleCopy }, { children: qty == null ? "-" : qty }))] }), "key-5-" + _this.getRandom()), monthData.quantities[i]
                                                                                                .versionType === "V" ? (_jsx(_Fragment, { children: _jsx(Popup, __assign({ anchor: _this.anchor, anchorAlign: _this.anchorAlign, popupAlign: _this.popupAlign, collision: {
                                                                                                        vertical: "fit",
                                                                                                    }, show: _this.state.show, offset: _this.offSet, popupClass: "promote-popup" }, { children: _jsx(Button, __assign({ look: "flat", onClick: _this
                                                                                                            .onPromoteToBaselineClick }, { children: "Promote to Baseline" })) })) })) : null] })) }), "key-4-" + _this.getRandom()));
                                                                            }) })));
                                                                    }) }) }) }) }) })] }) }))] }) }) })) })), this.state.loader && _jsx(Spinner, {})] }));
    };
    return productionGradeView;
}(Component));
export default productionGradeView;
