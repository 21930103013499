import { __assign, __extends } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import TotalCapacityAccordion from "./tools/totalCapacityAccordion";
import { getSourceFilter } from "../../../../../services/urlService";
import Spinner from "../../../../../../src/commons/spinner.jsx";
import { If } from "rc-if-else";
var UnitDataGrid = /** @class */ (function (_super) {
    __extends(UnitDataGrid, _super);
    function UnitDataGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.componentDidUpdate = function () {
            // console.log('updated');
        };
        _this.setButtonGroupState = function (assetKey, id) {
            var bgs = __assign({}, _this.state.buttonGroupState);
            bgs[assetKey] = id;
            _this.setState({
                buttonGroupState: bgs
            });
        };
        _this.state = {
            unitTpeOn: false,
            subUnitOn: false,
            unitOn: true,
            currentRandKey: "",
            totalCapacityAssetUnit: [],
            loading: false,
            buttonGroupState: {},
        };
        _this.currentView = 3;
        return _this;
    }
    UnitDataGrid.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        if (nextProps.assetFilterParams === this.props.assetFilterParams && nextProps.baseProvider === null && !this.props.shouldRefresh) {
            return false;
        }
        return true;
    };
    UnitDataGrid.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: this.props.data.length > 0 ? (this.props.data.map(function (parent, i) {
                var randNum = _this.getRandom();
                return (_jsx(TotalCapacityAccordion, { identifier: randNum, title: parent.groupName, accordionKey: i, data: parent, onCapacitySelect: _this.props.onCapacitySelect, assetFilterParams: _this.props.assetFilterParams, assetKey: parent.groupId, limitForHighlight: _this.props.limitForHighlight, baseProvider: _this.props.baseProvider, setButtonGroupState: _this.setButtonGroupState, buttonGroupState: _this.state.buttonGroupState[parent.groupId] }, randNum));
            })) : (_jsx("div", __assign({ className: "jumbotron vertical-center bg-transparent h-100 capacity-screen-no-data position-relative" }, { children: _jsxs("div", __assign({ className: "container text-center" }, { children: [_jsx("img", { alt: "geneva-watermark-logo", src: require("../../../../../assets/images/watermarkLogo.png") }), _jsx("h3", __assign({ className: "mt-2", style: { color: "#595959" } }, { children: "No data available, Please reselect filters." }))] })) }))) }, this.state.currentRandKey ? this.getRandom : this.state.currentRandKey));
    };
    return UnitDataGrid;
}(Component));
export default UnitDataGrid;
