import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
var Topnav = function (_a) {
    var showingValueKbd = _a.showingValueKbd, capacityViewEvent = _a.capacityViewEvent, cvisOn = _a.cvisOn, pathIncrCap = _a.pathIncrCap, pathTotCap = _a.pathTotCap, collapseExpandEvent = _a.collapseExpandEvent, ceisOn = _a.ceisOn, filterShowEvent = _a.filterShowEvent, fsisOn = _a.fsisOn, expandStandardEvent = _a.expandStandardEvent, visualizationEvent = _a.visualizationEvent, esisOn = _a.esisOn;
    var history = useHistory();
    var onCapacityTypeChange = function () {
        if (!cvisOn) {
            history.push("/dashboard/globalAssetDatabase/capacity/capacityIncremental");
        }
        else
            history.push("/dashboard/globalAssetDatabase/capacity/capacityTotal");
    };
    return (_jsx("div", __assign({ className: "row no-gutters border-bottom shadow topbar background-filter-fill topnav-toggle-container p-0" }, { children: _jsxs("div", __assign({ className: "col-sm-12 text-right mt-2" }, { children: [cvisOn ? (_jsx("span", __assign({ className: "topnav-span-text mr-2 disabled-toggle" }, { children: "Total" }))) : (_jsx("span", __assign({ className: "topnav-span-text mr-2" }, { children: "Total" }))), _jsxs("label", __assign({ className: "switch" }, { children: [_jsx("input", { type: "checkbox", checked: cvisOn, onChange: onCapacityTypeChange }), _jsx("span", { className: "slider slider-link round" })] })), cvisOn ? (_jsx("span", __assign({ className: "ml-2 mr-5 topnav-span-text" }, { children: "Incremental" }))) : (_jsx("span", __assign({ className: "ml-2 mr-5 topnav-span-text disabled-toggle" }, { children: "Incremental" }))), fsisOn ? (_jsx("span", __assign({ className: "mr-2 ml-2 topnav-span-text disabled-toggle" }, { children: "Filters Off" }))) : (_jsx("span", __assign({ className: "mr-2 ml-2 topnav-span-text" }, { children: "Filters Off" }))), _jsxs("label", __assign({ className: "switch" }, { children: [_jsx("input", { type: "checkbox", checked: fsisOn, onChange: filterShowEvent }), _jsx("span", { className: "slider slider-toggle round" })] })), fsisOn ? (_jsx("span", __assign({ className: "ml-2 mr-5 topnav-span-text" }, { children: "Filters On" }))) : (_jsx("span", __assign({ className: "ml-2 mr-5 topnav-span-text disabled-toggle" }, { children: "Filters On" }))), _jsxs("span", __assign({ className: "topnav-span-text topnav-span-link mr-3", onClick: visualizationEvent }, { children: [_jsx("i", { className: "fa fa-bar-chart pr-2" }), "Data Visualization"] }))] })) })));
};
Topnav.propTypes = {
    capacityViewEvent: PropTypes.func.isRequired,
    collapseExpandEvent: PropTypes.func.isRequired,
    filterShowEvent: PropTypes.func.isRequired,
    expandStandardEvent: PropTypes.func.isRequired,
    visualizationEvent: PropTypes.func.isRequired,
};
export default Topnav;
