import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import Filter from "./ProgressiveFilter";
import Supply from "./Supply";
import Maintenance from "./Maintenance";
import "./events.css";
import { ApiProvider } from "./apiContext";
import Spinner from "../../../../../commons/spinner";
import { getSourceFilter } from "../../../../../services/urlService";
function AddEditEvent(props) {
    var _a = useState(false), loader = _a[0], setLoader = _a[1];
    var _b = useState(false), windowOverLay = _b[0], setWindow = _b[1];
    var _c = useState(false), overlay = _c[0], setOverlay = _c[1];
    var _d = useState({}), selectData = _d[0], setSelectedData = _d[1];
    function getRandom() {
        return (Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15));
    }
    function onClose() {
        setWindow(false);
        //props.setShouldRefresh(true);
        // setIgnoreCapacity(false);
        //progressiveFilterRefs.current.onClose();
        // setLoader(true);
        // props.onApply();
        props.onClose();
        //props.onApply(props.applyUrl);
        //props.setShouldRefresh(false);
        // props.setShouldRefresh(false);
    }
    return (
    // {loader && <Spinner />}
    //  <If condition = {windowOverLay}>
    _jsxs(ApiProvider, { children: [loader && _jsx(Spinner, {}), _jsxs("div", __assign({ className: "assetunitconfigwindow" }, { children: [_jsx(Window, __assign({ title: "Edit Supply Production - " +
                            props.selectedData[props.selectedKey].country, 
                        /* style={{
                          // overflowY: "auto",
                          // overflowX: "auto",
                          width: "90%",
                          height: "60%",
                        }}*/
                        onClose: onClose, initialWidth: 1500, initialHeight: "auto", initialLeft: 160, initialTop: 200 }, { children: _jsx("div", __assign({ style: { position: "relative" } }, { children: _jsx("div", __assign({ className: "capacity-event", style: {
                                    position: "relative",
                                    height: "83%",
                                    width: "100%",
                                    // overflowY: "auto",
                                } }, { children: _jsx(Supply, { selectedData: props.selectedData, selectedKey: props.selectedKey, 
                                    //  selectedProduct={
                                    //props.selectedMaterial.get("maintenance").MaterialProducts
                                    //  props.selectedProduct
                                    // }
                                    selectedFamily: 
                                    //props.selectedMaterial.get("maintenance").MaterialProducts
                                    props.selectedFamily, selectedGroup: 
                                    // props.selectedMaterial.get("maintenance").MaterialGroups
                                    props.selectedGroup, updatedMonthData: [], onClickCancel: onClose, setOverlay: setOverlay, setShouldRefresh: props.setShouldRefresh, setSelectedData: props.setSelectedData, setGraphData: props.setGraphData, applyUrl: props.applyUrl }) })) })) })), windowOverLay && _jsx("div", { className: "k-overlay" })] }))] }));
}
export default AddEditEvent;
