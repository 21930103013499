import { __assign, __extends, __spreadArray } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { connect } from "react-redux";
//import * as capacityScreenActions from "../../../../../../redux/actions/capacityScreenActions";
import { bindActionCreators } from "redux";
import { getSourceFilter } from "../../../../../../services/urlService";
import ErrorBoundary from "../../../../../../services/errorBoundary";
import Spin from "../../../../../../commons/spin";
import SearchBox from "./search-box";
import { If, ElIf } from "rc-if-else";
import Checkbox from "./checkbox";
import _ from "lodash";
var countriesSt = [];
var tradeOrganizationsSt = [];
var regionsSt = [];
var materialGroupsSt = [];
var materialFamilySt = [];
var materialGradesSt = [];
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSearch = function (query) {
            _this.setState({ searchQuery: query.replace(/\\/g, "\\\\") });
            return;
        };
        // componentDidUpdate = () => {
        //   console.log(2);
        // };
        _this.getFilterData = function () {
            var _a = _this.state, searchQuery = _a.searchQuery, allData = _a.filterData;
            var filtered = allData;
            if (searchQuery)
                filtered = allData.filter(function (item) {
                    if (item[_this.props.code]) {
                        return item[_this.props.code]
                            .toLowerCase()
                            .match(_this.state.searchQuery.toLowerCase());
                    }
                    return true;
                });
            return filtered;
        };
        _this.onInputSelect = function (e) {
            _this.props.deleteFilterStore();
            var currentSelectedName = [];
            var currentSelected = __spreadArray([], _this.state.currentSelectedValue, true);
            if (e.target.id == "0-Select All") {
                if (e.target.checked) {
                    currentSelected.push(parseInt(e.target.value));
                    for (var i = 0; i < _this.state.filterData.length; i++) {
                        currentSelected.push(_this.state.filterData[i].id);
                    }
                }
                else {
                    currentSelected = [];
                }
            }
            else {
                if (e.target.checked) {
                    currentSelected.push(parseInt(e.target.value));
                }
                else {
                    var index = currentSelected.indexOf(parseInt(e.target.value));
                    currentSelected.splice(index, 1);
                }
            }
            _this.setState(function (prevState, props) {
                return { currentSelectedValue: currentSelected };
            }, function () {
                var path = _this.props.path.split("?");
                _this.handleChange(path[0], _this.state.currentSelectedValue);
            });
            if (_this.props.path.split("?")[0] == "MaterialFamily") {
                if (_this.state.filterData.length == 0 &&
                    currentSelected == _this.props.defaultValues) {
                    _this.props.setSelectedFamilyName(["Crude/Condensate"]);
                }
                else {
                    var _loop_1 = function (i) {
                        currentSelectedName.push(_this.state.filterData.find(function (element) { return element.id == currentSelected[i]; }).materialTypeNm);
                    };
                    for (var i = 0; i < currentSelected.length; i++) {
                        _loop_1(i);
                    }
                    _this.props.setSelectedFamilyName(currentSelectedName);
                }
            }
            else if (_this.props.path.split("?")[0] == "MaterialGroup") {
                var _loop_2 = function (i) {
                    currentSelectedName.push(_this.state.filterData.find(function (element) { return element.id == currentSelected[i]; }).materialTypeNm);
                };
                for (var i = 0; i < currentSelected.length; i++) {
                    _loop_2(i);
                }
                _this.props.setSelectedGroupName(currentSelectedName);
            }
        };
        _this.handleChange = function (path, currentSelected) {
            switch (path) {
                case "Region":
                    regionsSt = [];
                    currentSelected.forEach(function (value) {
                        regionsSt.push("he=" + value);
                    });
                    var eventManagementObjectR = {
                        tradeOrganizations: "TradeOrganization?" + regionsSt.join("&"),
                        countries: "Countries?" + regionsSt.join("&"),
                    };
                    _this.props.eventManagement(eventManagementObjectR, path, currentSelected, currentSelected.length);
                    _this.props.setSelectedMaterialGrade([]);
                    break;
                case "TradeOrganization":
                    tradeOrganizationsSt = [];
                    currentSelected.forEach(function (value) {
                        tradeOrganizationsSt.push("to=" + value);
                    });
                    var eventManagementObjectTo = {
                        countries: "Countries?" +
                            tradeOrganizationsSt.join("&") +
                            "&" +
                            regionsSt.join("&"),
                    };
                    _this.props.eventManagement(eventManagementObjectTo, path, currentSelected, currentSelected.length);
                    _this.props.setSelectedMaterialGrade([]);
                    break;
                case "Countries":
                    countriesSt = [];
                    currentSelected.forEach(function (value) {
                        countriesSt.push("countries=" + value);
                    });
                    var eventManagementObjectCo = {
                        materialGrade: "MaterialGrade?" +
                            "&" +
                            countriesSt.join("&") +
                            "&" +
                            (materialFamilySt.length == 0
                                ? "fc=9 "
                                : materialFamilySt.join("&")) + "&" + materialGroupsSt.join("&"),
                    };
                    _this.props.eventManagement(eventManagementObjectCo, path, currentSelected, currentSelected.length);
                    break;
                case "MaterialFamily":
                    var currentSelectedName = void 0;
                    materialFamilySt = [];
                    currentSelected.forEach(function (value) {
                        materialFamilySt.push("fc=" + value);
                    });
                    var eventManagementObjectFg = {
                        materialGroups: "MaterialGroup?" + (materialFamilySt.length == 0
                            ? "fc=9 "
                            : materialFamilySt.join("&")),
                        materialGrade: "MaterialGrade?" +
                            "&" +
                            countriesSt.join("&") +
                            "&" +
                            (materialFamilySt.length == 0
                                ? "fc=9 "
                                : materialFamilySt.join("&")),
                    };
                    _this.props.eventManagement(eventManagementObjectFg, path, currentSelected, currentSelected.length);
                    _this.props.setSelectedMaterialFamily(currentSelected);
                    break;
                case "MaterialGroup":
                    // let currentSelectedName;
                    materialGroupsSt = [];
                    currentSelected.forEach(function (value) {
                        materialGroupsSt.push("gc=" + value);
                    });
                    var eventManagementObjectMg = {
                        materialGrade: "MaterialGrade?" +
                            "&" +
                            countriesSt.join("&") +
                            "&" +
                            (materialFamilySt.length == 0
                                ? "fc=9 "
                                : materialFamilySt.join("&")) + "&" + materialGroupsSt.join("&"),
                    };
                    _this.props.eventManagement(eventManagementObjectMg, path, currentSelected, currentSelected.length);
                    _this.props.setSelectedMaterialGroup(currentSelected);
                    break;
                case "MaterialGrade":
                    materialGradesSt = [];
                    currentSelected.forEach(function (value) {
                        materialGradesSt.push("gc=" + value);
                    });
                    var eventManagementObjectgc = {};
                    _this.props.eventManagement(eventManagementObjectgc, path, currentSelected, currentSelected.length);
                    _this.props.setSelectedMaterialGrade(currentSelected);
                    break;
                default:
                    break;
            }
        };
        _this.getRandom = function () {
            return (Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15));
        };
        _this.resetSelectionOnFilter = function () {
            _this.setState({
                // refresh: false,
                currentSelectedValue: typeof _this.props.defaultValues !== "undefined"
                    ? _this.props.defaultValues
                    : [],
            });
            _this.props.setRefresh(false);
            regionsSt = [];
            tradeOrganizationsSt = [];
            countriesSt = [];
            materialGroupsSt = [];
            materialFamilySt = [];
            materialGradesSt = [];
            if (typeof _this.props.defaultValues !== "undefined") {
                _this.handleChange(_this.state.path.split("?")[0], _this.state.currentSelectedValue);
            }
        };
        _this.resetFilter = function () {
            if (_this.props.refresh) {
                _this.resetSelectionOnFilter();
            }
        };
        _this.setDataActive = function () {
            var dataActive;
            var currentSelectedInList = _this.state.currentSelectedValue;
            if (_this.props.path.split("?")[0] == "MaterialGrade") {
                if (_.indexOf(currentSelectedInList, 0) > -1) {
                    dataActive = true;
                }
                else {
                    dataActive = false;
                }
            }
            return dataActive;
        };
        _this.renderList = function (item) {
            var dataActive;
            var currentSelectedInList = _this.state.currentSelectedValue;
            if (_.indexOf(currentSelectedInList, item.id) > -1) {
                dataActive = true;
                return (_jsx(If, __assign({ condition: item[_this.props.code] }, { children: _jsx("div", __assign({ className: "py-2 ml-2 item-line-height" }, { children: _jsx(Checkbox, { name: item[_this.props.code], id: item.id, checkboxType: _this.props.path.split("?")[0], title: _this.props.path, onChange: _this.onInputSelect, value: item.id, isCheck: dataActive, typeOption: _this.props.typeOption }, item.id +
                            "-" +
                            _this.getRandom() +
                            "-" +
                            _this.props.path.split("?")[0]) }), item.id +
                        "-" +
                        _this.getRandom() +
                        "-" +
                        _this.props.path.split("?")[0]) }), item.id +
                    "-" +
                    _this.getRandom() +
                    "-" +
                    _this.props.path.split("?")[0]));
            }
            else {
                dataActive = false;
            }
            /* if (this.props.path.split("?")[0] == "MaterialGrade") {
              if (_.indexOf(currentSelectedInList, 0) > -1) {
                dataActive = true;
              }
            }*/
        };
        _this.renderUncheckList = function (item) {
            var dataActive;
            var currentSelectedInList = _this.state.currentSelectedValue;
            if (_.indexOf(currentSelectedInList, item.id) > -1) {
                dataActive = true;
            }
            else {
                dataActive = false;
                return (_jsx(If, __assign({ condition: item[_this.props.code] }, { children: _jsx("div", __assign({ className: "py-2 ml-2 item-line-height" }, { children: _jsx(Checkbox, { name: item[_this.props.code], id: item.id, checkboxType: _this.props.path.split("?")[0], title: _this.props.path, onChange: _this.onInputSelect, value: item.id, isCheck: dataActive, typeOption: _this.props.typeOption }, item.id +
                            "-" +
                            _this.getRandom() +
                            "-" +
                            _this.props.path.split("?")[0]) }), item.id +
                        "-" +
                        _this.getRandom() +
                        "-" +
                        _this.props.path.split("?")[0]) }), item.id +
                    "-" +
                    _this.getRandom() +
                    "-" +
                    _this.props.path.split("?")[0]));
            }
            /* if (this.props.path.split("?")[0] == "MaterialGrade") {
              if (_.indexOf(currentSelectedInList, 0) > -1) {
                dataActive = true;
              }
            }*/
        };
        _this.state = {
            searchQuery: "",
            selectSearchitem: null,
            loader: false,
            filterData: [],
            path: _this.props.path,
            reload: false,
            nodata: false,
            // currentSelectedValue: this.props.isReset
            //   ? this.props.previousSelection
            //   : [],
            currentSelectedValue: typeof _this.props.defaultValues !== "undefined"
                ? _this.props.defaultValues
                : [],
            allItemsInList: [],
            defaultValues: _this.props.defaultValues,
            selectAllValue: false,
        };
        return _this;
    }
    Filter.prototype.componentDidMount = function () {
        var _this = this;
        var path = this.state.path;
        var currentSelectedName = [];
        this.setState({
            loading: true /*[],*/,
            /* this.props.onSwitchPreviousSelectedItems &&
              this.props.onSwitchPreviousSelectedItems.length > 0
                ? this.props.onSwitchPreviousSelectedItems
                : */
            currentSelectedValue: typeof this.props.defaultValues !== "undefined"
                ? this.props.defaultValues
                : [],
        }
        /* () => {
          let path = this.props.path.split("?");
          //this.handleChange(path[0], this.state.currentSelectedValue);
        }*/
        );
        getSourceFilter(path)
            .then(function (filterData) {
            if (typeof filterData !== "undefined" &&
                typeof filterData.data !== "undefined") {
                if (filterData.data.length > 0) {
                    switch (path.split("?")[0]) {
                        case "Region":
                            _this.props.setAllFilters({ Regions: filterData.data });
                            break;
                        case "TradeOrganization":
                            _this.props.setAllFilters({
                                TradeOrganizations: filterData.data,
                            });
                            break;
                        case "Countries":
                            _this.props.setAllFilters({ Countries: filterData.data });
                            break;
                        case "MaterialGroup":
                            _this.props.setAllFilters({ MaterialGroups: filterData.data });
                            break;
                        case "MaterialFamily":
                            _this.props.setAllFilters({ MaterialFamilies: filterData.data });
                            break;
                        case "MaterialGrade":
                            _this.props.setAllFilters({ MaterialGrades: filterData.data });
                    }
                    _this.setState({ filterData: filterData.data, loading: false });
                }
                else if (filterData.data.length === 0) {
                    _this.setState({ nodata: true, loading: false });
                }
            }
            else {
                _this.setState({ nodata: true, loading: false });
            }
        })
            .catch(function (error) {
            // console.error("Error encountered: Details - " + error);
        });
        if (typeof path.split("?")[1] === "undefined" || !path.split("?")[1]) {
            this.resetSelectionOnFilter();
        }
        if (this.props.path.split("?").filter(function (entry) {
            return /\S/.test(entry);
        }).length <= 1) {
            this.setState({
                currentSelectedValue: 
                /*  this.props.onSwitchPreviousSelectedItems &&
                this.props.onSwitchPreviousSelectedItems.length > 0
                  ? this.props.onSwitchPreviousSelectedItems
                  : */ typeof this.props.defaultValues !== "undefined"
                    ? this.props.defaultValues
                    : [],
            }
            /* () => {
              let path = this.props.path.split("?");
              // this.handleChange(path[0], this.state.currentSelectedValue);
            }*/
            );
        }
        if (typeof this.props.defaultValues !== "undefined") {
            this.handleChange(this.state.path, this.state.currentSelectedValue);
        }
        if (this.state.path.split("?")[0] == "MaterialFamily") {
            if (this.state.filterData.length == 0 &&
                this.state.currentSelectedValue == this.props.defaultValues) {
                this.props.setSelectedFamilyName(["Crude/Condensate"]);
            }
            else {
                var _loop_3 = function (i) {
                    currentSelectedName.push(this_1.state.filterData.find(function (element) { return element.id == _this.state.currentSelectedValue[i]; }).materialTypeNm);
                };
                var this_1 = this;
                for (var i = 0; i < this.state.currentSelectedValue.length; i++) {
                    _loop_3(i);
                }
                this.props.setSelectedFamilyName(currentSelectedName);
            }
        }
        else if (this.state.path.split("?")[0] == "MaterialGroup") {
            var _loop_4 = function (i) {
                currentSelectedName.push(this_2.state.filterData.find(function (element) { return element.id == _this.state.currentSelectedValue[i]; }).materialTypeNm);
            };
            var this_2 = this;
            for (var i = 0; i < this.state.currentSelectedValue.length; i++) {
                _loop_4(i);
            }
            this.props.setSelectedGroupName(currentSelectedName);
        }
    };
    Filter.prototype.renderElement = function () {
        if (this.state.loading) {
            return _jsx(Spin, {});
        }
        else if (this.state.nodata) {
            return _jsx("span", { children: "No Data..." });
        }
    };
    Filter.prototype.render = function () {
        var _this = this;
        return (_jsx("div", { children: _jsx("div", __assign({ className: "col-12 p-0" }, { children: _jsxs(ErrorBoundary, { children: [_jsx(SearchBox, { id: this.props.id, onChange: this.handleSearch, value: this.state.searchQuery }), _jsxs("div", __assign({ style: {
                                height: "300px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                            } }, { children: [_jsx(If, __assign({ condition: this.props.id == "materialTypeNm" }, { children: _jsx("div", __assign({ className: "py-2 ml-2 item-line-height" }, { children: _jsx(Checkbox, { name: "Select All", id: 0, checkboxType: this.props.path.split("?")[0], title: "Select All", onChange: this.onInputSelect, value: 0, isCheck: this.setDataActive(), typeOption: this.props.typeOption }, this.getRandom() + "-" + this.props.path.split("?")[0]) })) })), _jsxs(If, __assign({ condition: this.getFilterData().length > 0 }, { children: [this.getFilterData().map(function (value, key) {
                                            return _this.renderList(value);
                                        }), this.getFilterData().map(function (value, key) {
                                            return _this.renderUncheckList(value);
                                        })] })), this.renderElement()] }))] }) })) }, this.state.path));
    };
    return Filter;
}(Component));
export { Filter };
Filter.propTypes = {
//actions: PropTypes.object.isRequired
};
function mapStateToProps(state) {
    return {
        filterData: state.filterData,
    };
}
function mapDispatchToProps(dispatch) {
    return {
    //actions: bindActionCreators(capacityScreenActions, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
